<template>
    <div class="card-content">
        <div class="card-header bg-white">
            <div class="card-heading-container">
                <h6 class="heading-6 card-heading">{{ asset.description }}</h6>
                <div class="text-smaller text-secondary">{{ asset.product.code }}</div>
            </div>
            <div class="field-grid">
                <field-vertical 
                    :isLoaded="isLoaded" 
                    label="Services" 
                    :value="`${asset.serviceTasks.length}`" />
                <field-vertical 
                    :isLoaded="isLoaded" 
                    label="Investigations" 
                    :value="`${asset.investigationTasks.length}`" />
                <field-vertical 
                    :isLoaded="isLoaded" 
                    label="Quotes" 
                    :value="`${asset.quotedWorksTasks.length}`" />
            </div>
        </div>
        <div class="card-body bg-primary-1 asset">
            <div class="field first">
                <img :src="statusIcon" alt="" class="field-icon">
                <div
                    class="field-value text-highlight bright nowrap"
                    :class="statusDescriptionClass"
                >
                    {{ equipmentStatusLabel }}
                </div>
            </div>
            <div class="field">
                <img src="/assets/img/tag-white.svg" alt="" class="field-icon">
                <div class="field-value nowrap">{{ asset.assetTag }}</div>
            </div>
            <div class="field">
                <img src="/assets/img/location-pin-white.svg" alt="" class="field-icon">
                <div class="field-value nowrap">{{ asset.position }}</div>
            </div>
            <div class="field">
                <img src="/assets/img/home-white.svg" alt="" class="field-icon">
                <div class="field-value nowrap">{{ asset.site.name || asset.site.line1 }}</div>
            </div>
            <div class="field">
                <img src="/assets/img/clock-white.svg" alt="" class="field-icon">
                <transition
                    enter-active-class="animated fadeIn faster"
                    leave-active-class="animated fadeOut faster"
                    mode="out-in"
                >
                    <div v-if="!isLoaded" key="fieldValueSkeleton" class="field-value highlight skeleton skeleton-box" style="width: 145px; height: 16px;"></div>
                    <div v-else key="fieldValue" class="field-value nowrap">{{ lastServiced }}</div>
                </transition>
            </div>
            <div class="field">
                <img src="/assets/img/download-white.svg" alt="" class="field-icon">
                <transition
                    enter-active-class="animated fadeIn faster"
                    leave-active-class="animated fadeOut faster"
                    mode="out-in"
                >
                    <div v-if="!isLoaded" key="fieldValueSkeleton" class="field-value highlight skeleton skeleton-box" style="width: 175px; height: 16px;"></div>
                    <a
                        v-if="isLoaded && lastServiceSheetAttachment !== undefined"
                        key="fieldValue1"
                        class="field-value text-white"
                        href="#"
                        @click.prevent="openSheetAttachment(lastServiceSheetAttachment)"
                    >
                        Download last sheet
                    </a>
                    <div v-else-if="isLoaded" key="fieldValue2" href="#" class="field-value nowrap">Service sheet unavailable</div>
                </transition>
            </div>

            <!-- <a href="#" class="labeled-button medium primary card-action w-inline-block mt30">
                <img src="/assets/img/megaphone-black.svg" alt="" class="labelled-button-icon">
                <div>request call out</div>
            </a> -->
        </div>
    </div>
</template>

<script>
// External packages
import assetStatus from "./asset-status";
import moment from "moment-timezone";

// Mixins
import { taskMixin } from "./task";
import { assetsMixin } from "../assets";

// Components
import FieldVertical from "./FieldVertical";

export default {
    mixins: [ assetsMixin, taskMixin ],
    props: {
        asset: {
            type: Object,
            required: true
        },
        isLoaded: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            lastServiced: undefined,
            lastServiceSheetAttachment: undefined,
            statusIcon: undefined,
            statusDescriptionClass: [],
        };
    },
    components: {
        FieldVertical
    },
    watch: {
        asset: function() {
            this.initAsset();
        }
    },
    methods: {
        initAsset() {
            if (this.asset !== undefined) {
                this.setEquipmentStatus(this.getLastExecutedTask(this.asset), this.asset.equipmentStatus);
                this.setEquipmentStatusLabel();
                this.setStatusStyling();
                this.setLastServiced();
                this.setLastServiceSheetAttachment();
            }
        },
        setStatusStyling() {
            switch (this.equipmentStatus) {
                case assetStatus.awaitingApproval:
                    this.statusIcon = '/assets/img/avatar-blue-light.svg';
                    this.statusDescriptionClass = [ 'text-primary-2', 'light' ];
                    break;
                case assetStatus.none:
                    this.statusIcon = '/assets/img/question-mark-orange.svg';
                    this.statusDescriptionClass = [ 'text-primary-3' ];
                    break;
                case assetStatus.online:
                    this.statusIcon = '/assets/img/check-green.svg';
                    this.statusDescriptionClass = [ 'text-primary-4' ];
                    break;
                case assetStatus.offline:
                    this.statusIcon = '/assets/img/cancel-red.svg';
                    this.statusDescriptionClass = [ 'text-primary-5' ];
                    break;
                case assetStatus.onlineEmergency:
                    this.statusIcon = '/assets/img/exclamation-mark-orange.svg';
                    this.statusDescriptionClass = [ 'text-primary-6' ];
                    break;
            }
        },
        setLastServiced() {
            let lastServiced = "--";
            const lastTask = this.getLastExecutedTask(this.asset);
            if (lastTask !== undefined && lastTask.visit !== undefined) {
                lastServiced = moment(lastTask.visit.probableStartDateTime).format("Do MMMM YYYY");
            }
            this.lastServiced = lastServiced;
        },
        setLastServiceSheetAttachment: function() {
            if (this.asset.lastServiceTask !== undefined) {
                const { lastServiceTask } = this.asset;
                this.lastServiceSheetAttachment = lastServiceTask.attachments !== undefined && lastServiceTask.attachments.length > 0 ? lastServiceTask.attachments[0] : undefined;
            }
        }
    },
    mounted() {
        this.initAsset();
    }
};
</script>