<template>
  <v-app id="inspire" light>
    <div class="main-container">

      <div v-if="showModal" class="overlay"></div>

      <transition
          enter-active-class="animated fadeInDownBig"
          leave-active-class="animated fadeOutUpBig"
      >
        <component v-if="showModal" :is="activeModal" />
      </transition>

      <side-nav-bar v-if="showNavbar" />

      <transition
        enter-active-class="animated zoomIn"
        leave-active-class="animated fadeOutDownBig"
        mode="out-in"
      >
        <router-view></router-view>
      </transition>

      <bottom-nav-bar v-if="showNavbar" />
    </div>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { HyperlinkMenuItem, RouterlinkMenuItem } from "./navbar";

import BottomNavBar from "./components/BottomNavBar";
import SideNavBar from "./components/SideNavBar";
import EngineerFilters from "./components/modals/EngineerFilters";
import ScheduledNotificationCreate from "./components/modals/ScheduledNotificationCreate";
import ScheduledNotificationDelete from "./components/modals/ScheduledNotificationDelete";
import ScheduledNotificationEdit from "./components/modals/ScheduledNotificationEdit";

export default {
  components: {
    BottomNavBar,
    EngineerFilters,
    ScheduledNotificationCreate,
    ScheduledNotificationDelete,
    ScheduledNotificationEdit,
    SideNavBar
  },
  computed: {
      ...mapGetters({
          "showModal": "modal/isVisible",
          "activeModal": "modal/activeModal"
      }),
      showNavbar: function() {
        const { path } = this.$route;
        return path !== "/" && !path.includes("contract") && !path.includes("callouts") && !path.includes("jobs");
      }
  },
  methods: {
    ...mapActions({
      "setDefaultMenuItems": "navbar/setDefaultMenuItems"
    }),
    logout() {
      this.$auth.logout();
      this.$router.push({ path: "/" });
    }
  },
  created() {
    const menuItems = [
      new RouterlinkMenuItem("/assets/img/noun_users_2303154-1.svg", "/engineers"),
      new RouterlinkMenuItem("/assets/img/noun_switch_3145970-1.svg", "/settings"),
      new HyperlinkMenuItem("/assets/img/noun_logout_3145924.svg", "", this.logout)
    ];
    this.setDefaultMenuItems(menuItems);
  }
};
</script>
