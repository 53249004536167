<template>
    <div class="field vertical">
        <div class="field-label">{{ label }}</div>
        <transition
            enter-active-class="animated fadeIn faster"
            leave-active-class="animated fadeOut faster"
            mode="out-in"
        >
            <div v-if="!isLoaded" key="fieldValueSkeleton" class="field-value highlight skeleton skeleton-box"></div>
            <div v-else key="fieldValue" class="field-value highlight">
                {{ value }}
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    props: {
        label: {
            type: String,
            required: true
        },
        value: {
            type: String,
            required: true
        },
        isLoaded: {
            type: Boolean,
            required: true
        }
    }
};
</script>

<style scoped>
.field-value.skeleton {
    min-width: 30px;
    min-height: 16px;
}
</style>