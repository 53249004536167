<template>
    <div class="row thin">
        <div class="row-fields grow thin">
            <div class="row-field shrink">
                <div class="row-field-label"><v-skeleton-loader width="50px" type="text"></v-skeleton-loader></div>
                <div class="text-smaller"><v-skeleton-loader width="75px" type="text"></v-skeleton-loader></div>
            </div>
            <div class="row-field">
                <div class="row-field-label"><v-skeleton-loader width="50px" type="text"></v-skeleton-loader></div>
                <div class="text-smaller"><v-skeleton-loader width="75px" type="text"></v-skeleton-loader></div>
            </div>
        </div>
        <div class="row-fields thin">
            <div class="row-field">
                <div class="row-field-label"><v-skeleton-loader width="50px" type="text"></v-skeleton-loader></div>
                <div class="text-smaller"><v-skeleton-loader width="175px" type="text"></v-skeleton-loader></div>
                <div class="text-smaller"><v-skeleton-loader width="115px" type="text"></v-skeleton-loader></div>
            </div>
        </div>
        
        <div class="row-more-icon horizontal-flex-container custom">
            <v-skeleton-loader width="20px" type="button"></v-skeleton-loader>
            <div class="spacer-15"></div>
            <v-skeleton-loader width="20px" type="button"></v-skeleton-loader>
        </div>
    </div>
</template>

<script>
// Components
import { VSkeletonLoader } from "vuetify/lib/components/VSkeletonLoader";

export default {
    components: {
        VSkeletonLoader
    }
}
</script>