<template>
    <div class="profile-container">
        <div v-if="engineer" class="profile-card">
            <avatar :engineer="engineer" />
            <engineer-hero-card :engineer="engineer" />

            <transition
                enter-active-class="animated fadeInRight faster"
                leave-active-class="animated fadeOutRight faster"
                mode="out-in"
            >
                <component
                    :is="activePanel"
                    :engineer="engineer"
                    @shownotifications="onShowNotifications"
                    @hidenotifications="onHideNotifications"
                />
            </transition>
        </div>
    </div>
</template>

<script>
import Avatar from "./Avatar";
import EngineerHeroCard from "../status/card/EngineerHeroCard";
import Fields from "./Fields";
import Notifications from "./Notifications";

export default {
    props: {
        engineer: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            activePanel: "Fields"
        };
    },
    components: {
        Avatar,
        EngineerHeroCard,
        Fields,
        Notifications
    },
    methods: {
        onShowNotifications() {
            this.activePanel = "Notifications";
        },
        onHideNotifications() {
            this.activePanel = "Fields";
        }
    }
};
</script>