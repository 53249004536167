<template>
  <v-card
    flat
    color="primary"
    height="225"
    width="550"
    class="d-flex bg-gradient-2 justify-center"
  >
    <div class="d-flex">
      <div class="bullet-pie flex-grow-0 align-self-center">
        <v-progress-circular
          ref="background"
          class="pie"
          size="160"
          width="16"
          color="grey lighten-3"
          :value="100"
        />
        <v-progress-circular
          ref="target"
          class="pie"
          rotate="270"
          size="160"
          width="16"
          color="light-blue lighten-4"
          :value="target"
        />
        <v-progress-circular
          ref="actual"
          class="pie actual"
          rotate="270"
          size="150"
          width="6"
          color="primary"
          :value="utilisation"
        />
      </div>

      <div class="key flex-grow-1 align-self-center ml-8">
        <div class="d-flex flex-column white--text">
          <div class="d-flex bullet-pie-key">
            <v-progress-linear
              class="key-chart"
              :value="100"
              height="18"
              color="light-blue lighten-4"
            >
              <v-progress-linear
                :value="100"
                height="4"
              />
            </v-progress-linear>
            <div class="key-value font-weight-bold ml-2">{{ utilisation }}%</div>
          </div>
          <div class="key-label text-caption">utilisation</div>
        </div>

        <div class="d-flex flex-column white--text mt-3">
          <div class="d-flex bullet-pie-key">
            <v-progress-linear
              class="key-chart"
              :value="100"
              height="16"
              color="light-blue lighten-4"
            />
            <div class="key-value font-weight-bold ml-2">{{ target }}%</div>
          </div>
          <div class="key-label text-caption">target</div>
        </div>

        <div class="d-flex flex-column white--text mt-3">
          <div class="d-flex bullet-pie-key">
            <v-progress-linear
              class="key-chart"
              :value="100"
              height="16"
              color="grey lighten-3"
            />
            <div class="key-value font-weight-bold ml-2">{{ manHours }}</div>
          </div>
          <div class="key-label text-caption">man hours</div>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    manHours: {
      type: Number,
      required: true
    },
    target: {
      type: Number,
      required: true
    },
    utilisation: {
      type: Number,
      required: true
    }
  }
}
</script>

<style scoped>
.bullet-pie {
  position: relative;
  height: 160px;
  width: 160px;
}

.bullet-pie > .pie {
  position: absolute;
}

.bullet-pie .pie.actual {
  top: 5px;
  left: 5px;
}

.key-chart {
  width: 18px;
  border-radius: 2px;
}

.key-value {
  line-height: 1.15rem;
  font-size: 1.05rem;
}

.key-label {
  line-height: 1.15rem;
}
</style>

<style>
.bullet-pie .v-progress-circular__underlay {
  stroke: none !important;
}
</style>