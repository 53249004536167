<template>
  <v-card
    class="mx-auto"
    :class="{ 'selected': active }"
    :elevation="active ? 2 : 10"
    :ripple="{ class: active ? 'grey--text' : 'info--text' }"
    max-width="350"
    outlined
    @click="toggle"
  >
    <div class="d-flex flex-no-wrap justify-space-between">
      <div>
        <v-card-title
          v-text="`${engineer.firstname} ${engineer.surname}`"
        ></v-card-title>

        <v-card-subtitle v-if="engineer.isOnCall">{{ engineer.onCallSlots[0].notes || $t('callout-subtitle') }}</v-card-subtitle>
        <v-card-subtitle v-else>{{ engineer.region }}</v-card-subtitle>
        
        <v-card-text>
          {{ engineer.mobile }}
        </v-card-text>

        <v-card-actions>
          <v-btn
            class="ml-2 mt-3"
            right
            elevation="0"
            :href="`tel:${engineer.mobile}`"
            @click.stop
            @mousedown.stop
          >
            <v-icon>mdi-phone</v-icon>
          </v-btn>

          <v-btn
            class="ml-2 mt-3"
            right
            elevation="0"
            @click.stop="showInfo"
            @mousedown.stop
          >
            <v-icon>mdi-information-outline</v-icon>
          </v-btn>
        </v-card-actions>
      </div>

      <div
        class="ma-3"
      >
        <v-avatar
          size="75"
          v-if="engineer.avatar"
        >
          <v-img :src="engineer.avatar"></v-img>
        </v-avatar>
        <v-avatar
          v-else
          size="75"
          color="primary white--text heading-5"
        >
          {{ engineer.firstname[0].toUpperCase() }}{{ engineer.surname[0].toUpperCase() }}
        </v-avatar>
        <div
          class="mt-1 text-center"
          v-if="engineer.isOnCall"
        >
          <v-chip 
            color="info"
            small
          >
            on-call
          </v-chip>
        </div>

        <div
          class="mt-1 text-center"
          v-if="active"
        >
          <v-chip 
            v-if="isPrimary"
            color="info"
            outlined
            small
          >
            primary
          </v-chip>
          <v-chip 
            v-else
            color="info"
            outlined
            small
          >
            support
          </v-chip>
        </div>
      </div>
    </div>

    <v-expand-transition>
      <v-card
        v-if="isShowInfo"
        class="transition-fast-in-fast-out v-card--reveal"
        style="height: 100%;"
      >
        <v-app-bar
          flat
          color="transparent"
        >
          <v-toolbar-title class="title pl-0">
            Contacts
          </v-toolbar-title>

          <v-spacer></v-spacer>

          <v-btn
            icon
            @click.stop="hideInfo"
            @mousedown.stop
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-app-bar>

        <v-card-text>
          <div class="d-flex justify-space-between">
            <v-list-item
              class="pa-2 mr-1 rounded"
              style="overflow: hidden"
              three-line
              :href="`tel:${fieldServiceManager.mobile}`"
              @mousedown.stop
            >
              <v-list-item-content>
                <v-list-item-title>Team leader</v-list-item-title>
                <v-list-item-subtitle>{{ fieldServiceManager.firstname }} {{ fieldServiceManager.surname }}</v-list-item-subtitle>
                <v-list-item-subtitle>{{ fieldServiceManager.mobile }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              class="pa-2 ml-1 rounded"
              style="overflow: hidden"
              three-line
              :href="`tel:${serviceManager.mobile}`"
              @mousedown.stop
            >
              <v-list-item-content>
                <v-list-item-title>Service manager</v-list-item-title>
                <v-list-item-subtitle>{{ serviceManager.firstname }} {{ serviceManager.surname }}</v-list-item-subtitle>
                <v-list-item-subtitle>{{ serviceManager.mobile }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-card-text>
      </v-card>
    </v-expand-transition>
  </v-card>
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,
      default: false
    },
    engineer: {
      type: Object,
      required: true
    },
    fieldServiceManager: {
      type: Object,
      required: true
    },
    isPrimary: {
      type: Boolean,
      default: false
    },
    serviceManager: {
      type: Object,
      required: true
    },
    toggle: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      isShowInfo: false
    };
  },
  methods: {
    callEngineer() {
      console.log(`Call ${this.engineer.mobile}`)
    },
    hideInfo() {
      this.isShowInfo = false;
    },
    showInfo() {
      this.isShowInfo = true;
    },
  }
}
</script>

<style scoped>
.v-card {
  border-color: transparent;
}

.selected {
  border-color: var(--v-info-base);
}

.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}

.v-card--link:focus:before {
  opacity: 0;
}
</style>>