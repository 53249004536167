<template>
    <div class="hero-block">
        <div class="hero-details">
            <div class="chip bg-grey-4">
                unavailable
            </div>
            <h3 class="hero-title">{{ nextAppointment.description }}</h3>
            <appointment 
                class="hero-description"
                :start="nextAppointment.start" 
                :end="nextAppointment.end" 
            />

            <div class="comment-container">
                <div class="comment-label">Notes:</div>
                <div class="comment">{{ nextAppointment.notes }}</div>
            </div>
        </div>
        <div class="hero-illustration">
            <img v-if="type === 1" src="/assets/illustrations/undraw_medicine_b1ol.svg" alt="">
            <img v-else-if="type === 2" src="/assets/illustrations/undraw_cup_of_tea_6nqg.svg" alt="">
            <img v-else-if="type === 3" src="/assets/illustrations/undraw_having_fun_iais.svg" alt="">
            <img v-else-if="type === 4" src="/assets/illustrations/undraw_teaching_f1cm.svg" alt="">
            <img v-else-if="type === 8" src="/assets/illustrations/undraw_work_chat_erdt.svg" alt="">
            <img v-else-if="type === 9" src="/assets/illustrations/undraw_QA_engineers_dg5p.svg" alt="">
            <img v-else src="/assets/illustrations/undraw_at_home_octe.svg" alt="">
        </div>
    </div>
</template>

<script>
import Appointment from "../descriptions/Appointment";
import { engineerMixin } from "../../engineer";

export default {
    mixins: [engineerMixin],
    props: {
        engineer: {
            type: Object,
            required: true
        }
    },
    components: {
        Appointment
    },
    computed: {
        type() {
            return this.nextAppointment.type;
        }
    }
};
</script>