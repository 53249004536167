<template>
  <v-list-item>
    <v-list-item-icon>
      <v-icon>
        mdi-account
      </v-icon>
    </v-list-item-icon>

    <v-list-item-content>
      <v-list-item-title>{{ `${contact.firstname} ${contact.surname}` }}</v-list-item-title>
      <v-list-item-subtitle>{{ contact.mobile || contact.telephone }}</v-list-item-subtitle>
      <v-list-item-subtitle>{{ contact.email }}</v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  props: {
    contact: {
      type: Object,
      required: true
    },
    showIcon: {
      type: Boolean,
      default: false
    }
  }
}
</script>