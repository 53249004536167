<template>
  <v-card
    flat
    color="grey lighten-3"
    height="225"
    class="d-flex"
  >
    <LineBar
      v-if="history.datasets"
      class="flex-grow-1"
      :chart-data="history"
      :options="{ 
        maintainAspectRatio: false, 
        responsive: true,
        title: {
          display: true,
          text: '12 month forecast'
        },
        scales: {
          yAxes: [{
            display: true,
            scaleLabel: {
              display: true,
              labelString: 'man hours'
            },
            ticks: {
              beginAtZero: true
            }
          }]
        }
      }"
    />
    <v-progress-linear
      v-else
      class="align-self-center mx-auto"
      indeterminate
      color="primary"
      style="width: 150px;"
    ></v-progress-linear>
  </v-card>
</template>

<script>
// External libraries
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';

// Components
import LineBar from './LineBar.js';

const colours = [
  '#E91E63',
  '#9C27B0',
  '#673AB7',
  '#3F51B5',
  '#2196F3',
  '#4CAF50',
  '#FFC107',
  '#FF5722',
];

const beginning = moment().startOf('month');

export default {
  components: {
    LineBar,
  },
  data() {
    return {
      history: {
        labels: undefined,
        datasets: undefined,
      },
    };
  },
  async mounted() {
    const dataset = await this.getForecastAvailability();
    await this.loadUtilisationHistory();
    this.initHistory(this.utilisationHistory, [dataset]);
  },
  computed: {
    ...mapGetters({
      utilisationHistory: 'prefs/engineers/utilisationHistory'
    }),
  },
  methods: {
    ...mapActions({
      loadUtilisationHistory: 'prefs/engineers/loadUtilisationHistory'
    }),
    initHistory(utilisation, customDatasets = []) {
      const labels = this.getLabels();
      const datasets = [ ...customDatasets ];
      let years = Object.values(utilisation)
        .map((v) => v.map((val) => val.year))
        .flat()
        .filter((v, i, a) => a.indexOf(v) === i)
        .sort();
      
      // Only show the last three years, ignoring the current year
      const start = years.length > 3 ? years.length - 4 : 0;
      const end = years.length - 1;
      years = years.slice(start, end);

      const c = [ ...colours ].sort();
      years.forEach((year) => {
        const data = [];

        labels.map((l) => l.toLowerCase()).forEach((month) => {
          const u = utilisation[month].find((u) => u.year === year);
          let d = u ? u.allocated.sum : 0;
          d = Math.round(d / 60);
          data.push(d);
        });
        
        const colour = c.pop();
        const dataset = {
          label: year,
          fill: false,
          tension: 0.5,
          showLine: false,
          backgroundColor: colour,
          borderColor: colour,
          data
        };

        datasets.push(dataset);
      });

      this.history = {
        labels,
        datasets,
      };
    },
    getLabels() {
      const labels = [];
      const date = beginning.clone();
      const startingMonth = beginning.month();
      let currentMonth = startingMonth;
      do {
        labels.push(date.format('MMMM').toLowerCase());
        date.add(1, 'month');
        currentMonth = date.month();
      } while (startingMonth !== currentMonth)

      return labels;
    },
    async getForecastAvailability() {
      const data = [];
      const api = await this.$api();
      const start = beginning.clone();
      let end = start.clone().endOf('month');
      let diff = 0;
      while (diff < 12) {
        // Get utilisation data for the period
        const params = {
          start: start.format('YYYY-MM-DD'),
          end: end.format('YYYY-MM-DD')
        };

        const { data: forecast } = await api.get('/engineers/utilisation', { params });
        const values = Object.values(forecast).map((v) => Object.values(v).map((inner) => inner.potential.actual));
        const manHours = Math.round(values.flat().reduce((p, c) => p + c, 0) / 60);
        data.push(manHours);

        start.add(1, 'month');
        end = start.clone().endOf('month');
        diff = start.diff(beginning, 'months');
      }

      return {
        label: 'availability',
        type: 'bar',
        backgroundColor: 'rgba(31, 35, 61, 0.2)',
        borderColor: 'rgba(31, 35, 61, 1)',
        borderWidth: 1,
        data
      };
    }
  },
};
</script>
