<template>
    <div class="row thin shrink my-1">
        <div class="row-fields">
            <div class="row-field shrink">
                <div class="row-field-label">Engineer</div>
                <div class="avatars grow">
                    <div class="avatar-container">
                        <img v-if="engineer && engineer.avatar" :src="engineer.avatar" alt="" class="avatar small">
                        <img v-else src="/assets/img/user-black.svg" alt="" class="avatar small">
                    </div>
                </div>
            </div>
            <div class="row-field shrink">
                <div class="row-field-label">Scheduled work</div>
                <div class="row-field-value text-smaller">{{ hydratedVisit.jobType }}</div>
            </div>
            <div class="row-field">
                <div class="row-field-label">Site</div>
                <div class="row-field-value text-smaller">{{ hydratedVisit.site.name || hydratedVisit.site.address.line1 }}</div>
            </div>
        </div>
        <div class="row-more-icon horizontal-flex-container">
            <a 
                v-if="reportAttachment !== undefined" 
                :href="reportAttachment.url" 
                class="icon-button smaller bg-transparent w-inline-block" 
                target="_blank"
            >
                <img src="/assets/img/download-black.svg" alt="">
            </a>
            <div v-else class="icon-button smaller bg-transparent w-inline-block">
                <img src="/assets/img/download-grey.svg" alt="" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        visit: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            engineer: undefined,
            hydratedVisit: { 
              ...this.visit,
              site: { address: { } }
            },
            reportAttachment: undefined
        };
    },
    methods: {
        async hydrateVisit() {
            const api = await this.$api();
            const { data } = await api.get(`/visits/${this.visit.id}`);
            this.hydratedVisit = data;

            this.reportAttachment = this.hydratedVisit.attachments.find(a => a.filename.toLowerCase().includes("report"));
        },
        async loadEngineer() {
            const api = await this.$api();
            const { data } = await api.get(`/users/${this.hydratedVisit.engineerId}`);
            this.engineer = data;
        }
    },
    async mounted() {
        await this.hydrateVisit();
        await this.loadEngineer();
    }
};
</script>