<template>
    <transition
        enter-active-class="animated fadeIn faster"
        leave-active-class="animated fadeOut faster"
        mode="out-in"
    >
        <skeleton-card
            v-if="!engineer"
            key="skeletonCard"
        />
        <idle-card
            v-else-if="engineer && isIdle"
            key="idleCard"
            :engineer="engineer"
        />
        <late-arriving-home-card
            v-else-if="engineer && isLateArrivingHome"
            key="homeLateCard"
            :engineer="engineer"
        />
        <appointment-card
            v-else-if="engineer && isUnavailable"
            key="appointmentCard"
            :engineer="engineer"
        />
        <visit-card
            v-else-if="engineer && nextVisit"
            key="visitCard"
            :visit="nextVisit"
        />
    </transition>
</template>

<script>

// Components
import IdleCard from "../card/IdleCard";
import LateArrivingHomeCard from "../card/LateArrivingHome";
import AppointmentCard from "../card/AppointmentCard";
import VisitCard from "../card/VisitCard";
import SkeletonCard from "../card/SkeletonCard";

// Mixins
import { engineerMixin } from "../../engineer";

export default {
    components: {
        AppointmentCard,
        IdleCard,
        LateArrivingHomeCard,
        VisitCard,
        SkeletonCard
    },
    mixins: [engineerMixin],
    props: {
        engineer: {
            type: Object,
            required: true
        }
    }
};
</script>