import Vue from "vue";
import axiosRetry from "axios-retry";

const symbolsRegex = /[^\w\s]/gi;

const userStatus = {
    atHome: "athome",
    help: "help",
    onSite: "onsite",
    travellingHome: "travellinghome"
};

async function getEngineers(getters) {
    const api = await Vue.prototype.$api();
    axiosRetry(api, { retries: 3 });
    const { data } = await api.get("/engineers");

    const engineers = {};
    for (let i = 0; i < data.length; i += 1) {
        const engineer = data[i];
        const existingEngineer = getters.engineers[engineer.id];
        engineers[engineer.id] = initEngineer(engineer, existingEngineer);
    }
    return engineers;
}

async function getTeams() {
    const user = await Vue.prototype.$auth.user;
    const api = await Vue.prototype.$api();
    const { data } = await api.get("/teams", { params: { email: user.email } });
    return data;
}

function initEngineer(engineer, existingEngineer) {
    engineer.notifications = existingEngineer ? existingEngineer.notifications : [];
    engineer.weight = existingEngineer ? existingEngineer.weight : 0;
    engineer.initials = getInitials(engineer);
    return engineer;
}

function getInitials(engineer) {
    return engineer.firstname.replace(symbolsRegex, "")[0].toUpperCase()
        + engineer.surname.replace(symbolsRegex, "")[0].toUpperCase();
}

function getStatusNotification(id, status) {
    return {
        subjectId: id,
        subjectType: "user",
        status: status
    };
}

const state = {
  isLoaded: false,
  engineers: {},
  regions: [],
  status: [],
  fieldServiceManagers: [],
  serviceManagers: [],
  statusOptions: {
    awaitingVisitStart: "awaiting-visit-start",
    lateDeparture: "late-departure",
    travellingToSite: "travelling-to-site",
    onSite: "on-site",
    travellingHome: "travelling-home",
    lateArrivingHome: "late-arriving-home",
    atHome: "at-home",
    idle: "idle",
    unavailable: "unavailable"
  },
  utilisationHistory: {}
};

const mutations = {
  engineer(state, payload) {
    const engineers = { ...state.engineers };
    engineers[payload.id] = payload;
    state.engineers = engineers;
  },
  engineers(state, payload) {
    state.engineers = payload;
  },
  isLoaded(state, payload) {
    state.isLoaded = payload;
  },
  regions(state, payload) {
    state.regions = payload;
  },
  status(state, payload) {
    state.status = payload;
  },
  fieldServiceManagers(state, payload) {
    state.fieldServiceManagers = payload;
  },
  serviceManagers(state, payload) {
    state.serviceManagers = payload;
  },
  utilisationHistory(state, payload) {
    state.utilisationHistory = payload;
  }
};

const actions = {
    async refreshEngineers({ getters, commit }) {
        commit("engineers", await getEngineers(getters));
    },
    async loadEngineers({ getters, commit }) {
      const { email } = Vue.prototype.$auth.user;
      if (!getters.isLoaded && email !== 'external@grundfos.com') {
            const teams = await getTeams();
            const teamRegions = teams
                .map(t => t.regions)
                .reduce((p, c) => [ ...p, ...c ], [])
                .map(r => `${r}`);
            commit("regions", teamRegions);
            commit("engineers", await getEngineers(getters));
            commit("isLoaded", true);
        }
    },
    async loadEngineer({ getters, commit }, engineerId) {
        const api = await Vue.prototype.$api();
        const { data } = await api.get(`/engineers/${engineerId}`);

        const existingEngineer = getters.engineers[engineerId];
        const engineer = initEngineer(data, existingEngineer);
        commit("engineer", engineer);
    },
    async loadManagers({ commit, getters }) {
      const isLoaded = getters.fieldServiceManagers.length > 0
        || getters.serviceManagers.length > 0;

      if (!isLoaded) {
        const api = await Vue.prototype.$api();
        const { data: fieldServiceManagers } = await api.get(`/regions/managers`, { params: { type: 'fieldService' } });
        const { data: serviceManagers } = await api.get(`/regions/managers`, { params: { type: 'serviceManager' } });
        commit('fieldServiceManagers', fieldServiceManagers);
        commit('serviceManagers', serviceManagers);

        // Update engineers with their manager and service manager
        const engineers = getters.engineers;
        for (const [ id, engineer ] of Object.entries(engineers)) {
          // Set the field service manager
          let team = fieldServiceManagers.find(m => m.regions.indexOf(engineer.regionId) > -1);
          if (team) {
            engineer.fieldServiceManager = team.manager;
          }

          // Set the service manager
          team = serviceManagers.find(m => m.regions.indexOf(engineer.regionId) > -1);
          if (team) {
            engineer.serviceManager = team.manager;
          }
        }
        commit('engineers', engineers);
      }
    },
    async loadUtilisationHistory({ commit }) {
      const api = await Vue.prototype.$api();
      const { data } = await api.get('/engineers/utilisation-history');
      commit('utilisationHistory', data);
    },
    async patchEngineer({ getters, commit }, { id, data, operations }) {
        const api = await Vue.prototype.$api();
        await api.patch(`/engineers/${id}`, operations);

        const existingEngineer = getters.engineers[id];
        const engineer = { ...existingEngineer, ...data };
        commit("engineer", engineer);
    },
    setRegions({ commit }, regions) {
        commit("regions", regions);
    },
    setStatus({ commit }, status) {
        commit("status", status);
    },
    async setOnSite({ commit }, engineerId) {
        try {
            const api = await Vue.prototype.$api();
            await api.post("/status-notifications", getStatusNotification(engineerId, userStatus.onSite));
        } catch(err) {
            console.error("Could not update engineer's status to 'onsite'.");
        }
    },
    async setTravellingHome({ commit }, engineerId) {
        try {
            const api = await Vue.prototype.$api();
            await api.post("/status-notifications", getStatusNotification(engineerId, userStatus.travellingHome));
        } catch(err) {
            console.error("Could not update engineer's status to 'travellinghome'.");
        }
    },
    async setAtHome({ commit }, engineerId) {
        try {
            const api = await Vue.prototype.$api();
            await api.post("/status-notifications", getStatusNotification(engineerId, userStatus.atHome));
        } catch(err) {
            console.error("Could not update engineer's status to 'athome'.");
        }
    },
    async setRequiresAssistance({ commit }, engineerId) {
        try {
            const api = await Vue.prototype.$api();
            await api.post("/status-notifications", getStatusNotification(engineerId, userStatus.help));
        } catch(err) {
            console.error("Could not update engineer's status to 'help'.");
        }
    }
};

const getters = {
  engineers(state) {
    return state.engineers;
  },
  filteredEngineers(state) {
    return Object.values(state.engineers)
        .filter(e => state.regions.length === 0 || state.regions.indexOf(`${e.regionId}`) >= 0)
        .filter(e => state.status.length === 0 || state.status.indexOf(e.status) >= 0);
  },
  isLoaded(state) {
    return state.isLoaded;
  },
  regions(state) {
    return state.regions;
  },
  status(state) {
    return state.status;
  },
  statusOptions(state) {
    return state.statusOptions;
  },
  fieldServiceManagers(state) {
    return state.fieldServiceManagers;
  },
  serviceManagers(state) {
    return state.serviceManagers;
  },
  utilisationHistory(state) {
    return state.utilisationHistory;
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};