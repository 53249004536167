<template>
    <sliding-card
        :shadowClass="'shadow-2'"
        cardTwoHeader="Site visits"
        v-observe-visibility="{
            callback: (isVisible, entry) => onVisibilityChanged(isVisible, asset),
            once: true,
            throttle: 500,
            threshold: 1.0
        }"
        class="card mb30 asset"
    >
        <template v-slot:front>
            <asset-summary
                :asset="asset"
                :isLoaded="asset.isHydrated"
            />
        </template>
        <template v-slot:back>
            <asset-details
                :asset="asset"
                :isLoaded="asset.isHydrated"
            />
        </template>
    </sliding-card>
</template>

<script>
// External libraries
import assetStatus from "./asset-status";
import { mapActions, mapGetters } from "vuex";

// Components
import AssetDetails from "./AssetDetails";
import AssetSummary from "./AssetSummary";
import SlidingCard from "../../../components/cards/SlidingCard";

export default {
    props: {
        assetId: {
            type: [ String, Number ],
            required: true
        }
    },
    data() {
        return {
            asset: {
                isHydrated: false,
                product: { code: "--" },
                investigationTasks: [],
                quotedWorksTasks: [],
                serviceTasks: [],
                site: { name: "--" }
            }
        };
    },
    components: {
        AssetDetails,
        AssetSummary,
        SlidingCard
    },
    computed: {
        ...mapGetters({
            "assets": "contract/assets"
        })
    },
    methods: {
        ...mapActions({
            "updateAsset": "contract/updateAsset"
        }),
        async hydrateAsset(overwriteAsset) {
            let asset = overwriteAsset ? { ...overwriteAsset } : undefined;
            if (!asset) {
                const api = await this.$api();
                const { data } = await api.get(`/assets/${this.assetId}`);
                asset = data;
            }
            
            asset.isHydrated = true;
            this.updateAsset(asset);
            this.asset = asset;
        },
        onVisibilityChanged(isVisible, asset) {
            const overwriteAsset = asset.equipmentStatus === assetStatus.awaitingApproval ? asset : undefined;
            if (isVisible && !asset.isHydrated) {
                this.hydrateAsset(overwriteAsset);
            }
        }
    },
    mounted() {
        this.asset = this.assets.find(a => a.id === this.assetId);
    }
};
</script>