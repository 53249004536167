<template>
    <div class="content-section framed bg-primary-1 grow vertical-flex-container">
        <div class="field-grid">
            <div class="field vertical">
                <div class="field-label">{{ $t("contract_reference") }}</div>
                <div class="field-value highlight">{{ reference !== undefined ? reference : "--" }}</div>
            </div>
            <div class="field vertical">
                <div class="field-label">{{ $t("contract_nextservice") }}</div>
                <div class="field-value highlight">{{ nextServiceDate !== undefined ? nextServiceDate.format("Do MMM YYYY") : "--" }}</div>
            </div>
        </div>
    </div>
</template>

<script>
// External packages
import { mapGetters } from "vuex";
import moment from "moment";

export default {
    computed: {
        ...mapGetters({
            "contract": "contract/contract"
        }),
        nextServiceDate: function(){
            return this.contract !== undefined && this.contract.nextServiceDate !== undefined ? moment(this.contract.nextServiceDate.plannedServiceDate) : undefined;
        },
        reference: function() {
            return this.contract !== undefined ? this.contract.reference : undefined;
        }
    },
};
</script>