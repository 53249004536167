<template>
    <div class="row my-1" :class="[ backgroundColour ? `${backgroundColour}-background` : '' ]">
        <img :src="icon" alt="" class="row-icon">
        <div class="row-avatar-container" :class="[ fontColour ? `${fontColour}-border` : '' ]">
            <img v-if="engineer.avatar" :src="engineer.avatar" alt="" class="row-avatar">
            <div v-else class="row-avatar-text" :class="[ fontColour ? `${fontColour}-border` : '' ]">
                {{ engineer.initials }}
            </div>
        </div>
        <div class="row-heading"><span class="strong">{{ engineer.firstname }}<br>‍</span>{{ engineer.surname }}</div>
        <div class="row-fields">
            <div class="row-field shrink">
                <div class="row-field-label" :class="[ `${fontColour}--text` ]">On-call</div>
                <div class="icon" :class="{ 'green lighten-1': engineer.isOnCall, 'grey lighten-1': !engineer.isOnCall }">
                    <img v-if="engineer.isOnCall" src="/assets/img/tick-white.svg" alt="">
                    <img v-else src="/assets/img/circled-x-black.svg" alt="">
                </div>
            </div>
            <div class="row-field shrink">
                <div class="row-field-label" :class="[ `${fontColour}--text` ]">Alerts</div>
                <div class="icon" :class="{ 'green lighten-1': engineer.isReceiveAlerts, 'grey lighten-1': !engineer.isReceiveAlerts }">
                    <img v-if="engineer.isReceiveAlerts" src="/assets/img/tick-white.svg" alt="">
                    <img v-else src="/assets/img/circled-x-black.svg" alt="">
                </div>
            </div>
            <div class="row-field">
                <div class="row-field-label" :class="[ `${fontColour}--text` ]">Current status</div>
                <div class="row-field-value">
                    <visit v-if="nextVisit" :visit="nextVisit" :isLateArrivingHome="isLateArrivingHome" :isShowPostcode="true" :isShowJobType="true" />
                    <idle v-if="nextIdleSlot" :timezone="engineer.timezone" :start="nextIdleSlot.start" :end="nextIdleSlot.end" />
                    <appointment v-if="isUnavailable" :start="nextAppointment.start" :end="nextAppointment.end" :description="nextAppointment.description" />
                </div>                
            </div>
        </div>
        <div class="row-more-icon">
            <router-link 
                :to="`/engineer/${engineer.id}`"
                class="icon transparent w-inline-block"
            >
                <img v-if="fontColour !== undefined" src="/assets/img/noun_three-dots_2854151-1.svg" alt="">
                <img v-else src="/assets/img/three-dots-black.svg" alt="">
            </router-link>
        </div>
    </div>
</template>

<script>
// External packages
import { engineerMixin } from "./engineer";
import { mapGetters } from "vuex";
import moment from "moment-timezone";

// Components
import Appointment from "./status/descriptions/Appointment";
import Idle from "./status/descriptions/Idle";
import Visit from "./status/descriptions/Visit";

export default {
    mixins: [engineerMixin],
    components: {
        Appointment,
        Idle,
        Visit
    },
    props: {
        engineer: {
            type: Object,
            required: true
        }
    },
    watch: {
        engineer: function() {
            // The updated engineer might have changed his status, so he should be re-ordered in
            // the list accordingly.
            this.setWeight();
        }
    },
    computed: {
        ...mapGetters({
            "statusOptions": "prefs/engineers/statusOptions"
        }),
        fontColour() {
            let colour;
            if (this.backgroundColour && this.backgroundColour !== "light-grey")
                colour = "white";
            return colour;
        },
        backgroundColour() {
            let colour;
            if (this.isAssistanceRequired)
                colour = "red";
            else if (this.isLateArrivingHome)
                colour = "violet";
            else if (this.isLateDeparture)
                colour = "blue";
            else if (this.isIdle)
                colour = "orange";
            else if (this.isUnavailable)
                colour = "light-grey";
            return colour;
        },
        icon() {
            const iconBase = "/assets/img/";
            let icon = `${iconBase}noun_Question_118559.svg`;
            if (this.isLateArrivingHome || this.isAssistanceRequired)
                icon = `${iconBase}noun_Exclamation-Mark_118547.svg`;
            else if (this.isLateDeparture)
                icon = `${iconBase}noun_Home_97307.svg`;
            else if (this.isIdle)
                icon = `${iconBase}noun_Question_118559.svg`;
            else if (this.isTravellingToSite)
                icon = `${iconBase}noun_right-arrow_59750-2.svg`;
            else if (this.isOnSite)
                icon = `${iconBase}noun_Map-Marker_97312-1.svg`;
            else if (this.isTravellingHome)
                icon = `${iconBase}noun_right-arrow_59750-2.svg`;
            else if (this.isAtHome)
                icon = `${iconBase}noun_Home_97307-2.svg`;
            else if (this.isUnavailable)
                icon = `${iconBase}noun_Home_97307-2.svg`;
            return icon;
        }
    },
    methods: {
        setWeight() {
            let weight = 4;
            let status = "";
            if (this.isAssistanceRequired) {
                weight = -1;
                status = this.statusOptions.lateArrivingHome;
            } else if (this.isLateArrivingHome) {
                weight = 0;
                status = this.statusOptions.lateArrivingHome;
            } else if (this.isLateDeparture) {
                weight = 1;
                status = this.statusOptions.lateDeparture;
            } else if (this.isIdle) {
                // Engineers who are available soon should appear on top of the ones
                // that are available later (e.g. tomorrow).
                const now = moment.tz(this.engineer.timezone);
                const slot = this.getNextIdleSlot(this.engineer);
                const hours = slot.start.diff(now, "hours");
                weight = 2 + (hours * 0.001);
                status = this.statusOptions.idle;
            } else if (this.isTravellingToSite) {
                weight = 3;
                status = this.statusOptions.travellingToSite;
            } else if (this.isOnSite) {
                weight = 4;
                status = this.statusOptions.onSite;
            } else if (this.isTravellingHome) {
                weight = 5;
                status = this.statusOptions.travellingHome;
            } else if (this.isAtHome) {
                weight = 6;
                status = this.statusOptions.atHome;
            } else if (this.isUnavailable) {
                weight = 999;
                status = this.statusOptions.unavailable;
            }
                
            this.engineer.weight = weight;
            this.engineer.status = status;
        }
    },
    created() {
        this.setWeight();
    }
};
</script>