<template>
  <div>
    <v-calendar
      ref="calendar"
      :now="day"
      :value="day"
      :events="events"
      :weekdays="[1, 2, 3, 4, 5, 6, 0]"
      color="primary"
      type="week"
      @click:event="showEvent"
    >
      <template v-slot:event="{ event: { start, end, type, job, appointment, notes, description } }">
        <div
          class="d-flex flex-column"
        >
          <div 
            v-if="type !== 'appointment'"
            class="pa-2"
            :class="{ 'primary-text--text': type === 'travel' }"
          >
            <div>{{ job.ref }}</div>
            <div>{{ type !== 'travel' ? job.type : '' }}</div>
            <div>{{ formatDate(start, 'HH:mm') }} - {{ formatDate(end, 'HH:mm') }}</div>
          </div>
          <div
            v-else-if="appointment.type !== 10"
            class="pa-2"
          >
            {{ description }}
            <div class="text-caption">{{ notes }}</div>
            <div>{{ formatDate(start, 'HH:mm') }} - {{ formatDate(end, 'HH:mm') }}</div>
          </div>
          <div
            v-else
            class="px-2 primary-text--text"
          >{{ description }} - {{ notes }}</div>
        </div>
      </template>
    </v-calendar>
    <v-menu
      v-if="selectedEvent.type === 'onsite'"
      v-model="selectedOpen"
      :close-on-content-click="false"
      :activator="selectedElement"
      offset-x
    >
      <v-card
        color="grey lighten-4"
        min-width="350px"
        flat
      >
        <v-card-text>
          <div
            class="text-subtitle-1 font-weight-bold"
          >
            {{ selectedEvent.job.ref }}
          </div>

          <div
            class="text-subtitle-2 mt-2"
          >
            Site
          </div>
          <div
            class="text-body-2"
          >
            <div
              v-for="(field, index) of selectedEvent.siteFields"
              :key="index"
            >
              {{ field }}
            </div>
          </div>

          <div
            class="text-subtitle-2 mt-4"
          >
            Customer
          </div>
          <div
            class="text-body-2"
          >
            <div>
              {{ selectedEvent.customer.name }}
            </div>
          </div>     
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
// Mixins
import { jobMixin } from '@/mixins/job';
import { momentMixin } from '@/mixins/moment';

// External libraries
import moment from 'moment';

const timedFormat = 'YYYY-MM-DD HH:mm';
const dateFormat = 'YYYY-MM-DD';
const travelColour = 'grey lighten-3';

export default {
  mixins: [ jobMixin, momentMixin ],
  props: {
    day: {
      type: String,
      required: true
    },
    engineerId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      appointments: [],
      events: [],
      visits: [],
      selectedEvent: {},
      selectedElement: undefined,
      selectedOpen: false,
    }
  },
  methods: {
    async tryLoad() {
      this.events = [];
      if (this.engineerId && this.day) {
        await this.loadAppointments();
        await this.loadVisits();
        this.$refs.calendar.scrollToTime('07:30');
      }
    },
    async loadAppointments() {
      const start = moment(this.day).startOf('week');
      const end = start.clone().endOf('week');
      const api = await this.$api();
      const { data: appointments } = await api.get(
        `/engineers/${this.engineerId}/appointments`,
        { params: { start: start.format(), end: end.format() } }
      );

      this.appointments = appointments;
      this.events = [ ...this.events, ...appointments.map(a => {
        const { type, fromDateTime, toDateTime, notes, description } = a;
        const format = type !== 10 ? timedFormat : dateFormat;
        return {
          appointment: {
            type
          },
          start: this.formatDate(fromDateTime, format),
          end: this.formatDate(toDateTime, format),
          timed: type !== 10,
          type: 'appointment',
          notes,
          description,
          color: type !== 10 ? 'blue-grey lighten-3' : 'teal lighten-4',
        }
      })];
    },
    async loadVisits() {
      const $t = this.$t.bind(this);
      const start = moment(this.day).startOf('week');
      const end = start.clone().endOf('week');
      const api = await this.$api();
      const { data: visits } = await api.get(
        `/engineers/${this.engineerId}/visits`, 
        { params: { start: start.format(), end: end.format(), customer: true } }
      );

      this.visits = visits;
      this.events = [ ...this.events, ...visits.map((v) => {
        const { 
          jobId,
          jobReference, 
          jobTypeId,
          site,
          workOrder,
          scheduledStartDateTime,
          estimatedDuration,
          departedDateTimeLocal, 
          arrivedDateTimeLocal,
          actualStartDateTimeLocal,
          actualEndDateTimeLocal,
          returnTravelDuration
        } = v;
        const job = { 
          id: jobId,
          ref: jobReference,
          type: $t(`job_type_${jobTypeId}`),
        };
        const slots = [];

        // Get 'travel to site' slot
        if (this.isDateSet(departedDateTimeLocal)
          && this.isDateSet(arrivedDateTimeLocal)) {
            slots.push({
              type: 'travel',
              job,
              start: this.formatDate(departedDateTimeLocal, timedFormat),
              end: this.formatDate(arrivedDateTimeLocal, timedFormat),
              color: travelColour
            });
          }
        
        // Get 'on site' slot
        let start = this.formatDate(scheduledStartDateTime, timedFormat);
        let end = moment(scheduledStartDateTime).add(estimatedDuration, 'hours').format(timedFormat);
        if (this.isDateSet(actualStartDateTimeLocal)) {
          start = this.formatDate(actualStartDateTimeLocal, timedFormat);
          if (this.isDateSet(actualEndDateTimeLocal)) {
            end = this.formatDate(actualEndDateTimeLocal, timedFormat);
          } else {
            end = moment(scheduledStartDateTime).add(estimatedDuration, 'hours').format(timedFormat);
          }
        }

        // Setup site address fields
        const { name, address: { line1, line2, city, postcode, country } } = site;
        const siteFields = [
          name && name !== line1 ? name : undefined,
          line1,
          line2,
          city,
          postcode,
          country ? country.name : undefined
        ].filter(f => f);

        // Setup customer fields
        const { customer } = workOrder;

        // Push 'on site' slot
        slots.push({
          type: 'onsite',
          job,
          customer,
          site,
          siteFields,
          start,
          end,
          color: this.colours.type[jobTypeId].background
        });

        // Get 'return travel' slot
        if (returnTravelDuration !== undefined && returnTravelDuration > 0) {
          slots.push({
            type: 'travel',
            job,
            start: end,
            end: moment(end).add(returnTravelDuration, 'minutes').format(timedFormat),
            color: travelColour
          })
        }

        return slots;
      }).flat() ];
    },
    showEvent ({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true));
      }

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
  },
  mounted() {
    this.tryLoad();
  },
}
</script>