<template>
    <div class="content-container navbar-offset">
        <div class="content">
            <div class="content-section">
                <content-section-heading :isLoaded="isLoaded" text="Field Engineers">
                    <a 
                        href="#"
                        class="icon transparent w-inline-block"
                        @click="showModal('EngineerFilters')"
                    >
                        <img src="/assets/img/noun_filter_3212561.svg" alt="">
                    </a>
                </content-section-heading>
                <div class="content-section-container">
                    <transition
                        enter-active-class="animated fadeIn faster"
                        leave-active-class="animated fadeOut faster"
                        mode="out-in"
                    >
                        <div
                            v-if="!isLoaded"
                            key="engineersSkeletons"
                            class="row-container"
                        >
                            <engineer-row-skeleton />
                            <engineer-row-skeleton style="opacity: 0.8;" />
                            <engineer-row-skeleton style="opacity: 0.6;" />
                            <engineer-row-skeleton style="opacity: 0.4;" />
                            <engineer-row-skeleton style="opacity: 0.2;" />
                        </div>
                        <div
                            v-else
                            key="engineers"
                            class="rows-container"
                        >
                            <engineer-row
                                v-for="engineer in engineersRows"
                                :key="engineer.id"
                                :engineer="engineer"
                            />
                        </div>
                    </transition>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// External packages
import { mapActions, mapGetters } from "vuex";
import { chain } from "lodash";

// Components
import ContentSectionHeading from "../../components/ContentSectionHeading";
import EngineerRow from "./EngineerRow";
import EngineerRowSkeleton from "./EngineerRowSkeleton";

export default {
    components: {
        ContentSectionHeading,
        EngineerRow,
        EngineerRowSkeleton
    },
    computed: {
        ...mapGetters({
            "engineers": "prefs/engineers/engineers",
            "filteredEngineers": "prefs/engineers/filteredEngineers",
            "isLoaded": "prefs/engineers/isLoaded",
            "regions": "prefs/engineers/regions",
            "status": "prefs/engineers/status"
        }),
        engineersRows: function () {
            return chain(this.filteredEngineers)
                .sortBy((e) => e.weight)
                .value();
        }
    },
    methods: {
        ...mapActions({
            "showModal": "modal/showModal",
            "loadEngineers": "prefs/engineers/loadEngineers",
            "refreshEngineers": "prefs/engineers/refreshEngineers",
            "resetMenuItems": "navbar/resetMenuItems"
        })
    },
    mounted() {
        this.loadEngineers();
        this.resetMenuItems();
        
        // Refresh the engineer list once every 15 minutes if the user is on this page
        const vm = this;
        const delay = 1000 * 60 * 15;
        setInterval(() => vm.refreshEngineers(), delay);
    }
};
</script>