export const jobMixin = {
  data() {
    return {
      colours: {
        type: {
          1: {
            background: 'red darken-3',
            foreground: 'white'
          },
          2: {
            background: 'amber darken-1',
            foreground: 'white'
          },
          3: {
            background: 'orange darken-1',
            foreground: 'white'
          },
          5: {
            background: 'light-blue darken-1',
            foreground: 'white'
          },
          7: {
            background: 'deep-orange',
            foreground: 'white'
          },
          8: {
            background: 'yellow darken-3',
            foreground: 'white'
          },
          9: {
            background: 'cyan',
            foreground: 'white'
          },
          11: {
            background: 'deep-purple',
            foreground: 'white'
          },
          1040: {
            background: 'teal',
            foreground: 'white'
          }
        },
        status: {
          '-1': {
            background: 'amber accent-4',
            foreground: 'white',
            icon: 'mdi-hand-left'
          },
          '-2': {
            background: 'amber accent-4',
            foreground: 'white',
            icon: 'mdi-hand-left'
          },
          '-3': {
            background: 'amber accent-4',
            foreground: 'white',
            icon: 'mdi-hand-left'
          },
          1: {
            background: 'green',
            foreground: 'white',
            icon: 'mdi-ray-start-arrow'
          },
          5: {
            background: 'blue',
            foreground: 'white',
            icon: 'mdi-calendar'
          },
          21: {
            background: 'amber accent-4',
            foreground: 'white',
            icon: 'mdi-calendar-alert'
          },
          27: {
            background: 'indigo darken-1',
            foreground: 'white',
            icon: 'mdi-account-cash'
          },
          30: {
            background: 'amber accent-4',
            foreground: 'white',
            icon: 'mdi-calendar-alert'
          },
          34: {
            background: 'purple',
            foreground: 'white',
            icon: 'mdi-file-eye'
          },
          37: {
            background: 'blue-grey darken-1',
            foreground: 'white',
            icon: 'mdi-timer-sand'
          },
          41: {
            background: 'pink',
            foreground: 'white',
            icon: 'mdi-file-question'
          }
        }
      },
    };
  }
}