const state = {
    isVisible: false,
    activeModal: undefined
};

const mutations = {
    isVisible(state, payload) {
        state.isVisible = payload;
    },
    activeModal(state, payload) {
        state.activeModal = payload;
    }
};

const actions = {
    showModal({ commit }, modal) {
        commit("activeModal", modal);
        commit("isVisible", true);
    },
    hideModal({ commit }) {
        commit("isVisible", false);
        commit("activeModal", undefined);
    }
};

const getters = {
    isVisible(state) {
        return state.isVisible;
    },
    activeModal(state) {
        return state.activeModal;
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};