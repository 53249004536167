var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-0",attrs:{"loading":_vm.isLoading,"loading-text":_vm.$t('loading_jobs'),"headers":_vm.headers,"items":_vm.jobs,"item-key":"id","sort-by":['typeId', 'statusId'],"sort-desc":[false, false],"multi-sort":"","show-expand":""},scopedSlots:_vm._u([{key:"item.typeId",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-regular mr-2 px-4",attrs:{"small":"","dark":"","color":_vm.colours.type[item.typeId].background}},[_vm._v(" "+_vm._s(item.type)+" ")])]}},{key:"item.statusId",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-regular mr-2 px-4",attrs:{"small":"","dark":"","color":_vm.colours.status[item.statusId].background}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v(" "+_vm._s(_vm.colours.status[item.statusId].icon)+" ")]),_vm._v(" "+_vm._s(item.status)+" ")],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"pa-0",attrs:{"colspan":headers.length}},[_c('job-details',{key:item.id,attrs:{"id":item.id},on:{"deleted":_vm.onJobDeleted}})],1)]}}],null,true)}),_c('v-snackbar',{attrs:{"timeout":_vm.snackTimeout,"color":_vm.snackColour},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.snack = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snack),callback:function ($$v) {_vm.snack=$$v},expression:"snack"}},[_vm._v(" "+_vm._s(_vm.snackText)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }