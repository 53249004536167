<template>
    <div class="scene">
        <div class="card-faces bg-white" :class="[ shadowClass ]">
            <div class="card-face" key="cardOne">
                <div class="card-face-header">
                    <div class="buttons">
                        <a class="icon-button smaller bg-transparent" href="#" @click.prevent="toggleCardTwoVisibility">
                            <img src="/assets/img/three-dots-black.svg" />
                        </a>
                    </div>
                </div>
                <slot name="front"></slot>
            </div>

            <transition
                enter-active-class="animated fadeInRightBig"
                leave-active-class="animated fadeOutRightBig slow"
                mode="out-in"
            >
                <div v-if="isCardTwoVisible" class="card-face absolute bg-black" key="cardTwo">
                    <div class="card-face-header">
                        <div class="card-face-heading text-normal text-bolder">
                            {{ cardTwoHeader }}
                        </div>
                        <div class="buttons">
                            <a class="icon-button smaller bg-transparent" href="#" @click.prevent="toggleCardTwoVisibility">
                                <img src="/assets/img/x-white.svg" />
                            </a>
                        </div>
                    </div>
                    <slot name="back"></slot>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        width: {
            type: Number,
            default: 300
        },
        height: {
            type: Number,
            default: 450
        },
        shadowClass: {
            type: String,
            default: ""
        },
        cardTwoHeader: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            isCardTwoVisible: false
        };
    },
    methods: {
        toggleCardTwoVisibility() {
            this.isCardTwoVisible = !this.isCardTwoVisible;
        }
    }
};
</script>

<style scoped>
.card-faces {
  position: relative;
  overflow-x: hidden;
}

.card-face.absolute {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
}

.card-face.absolute .card-body {
    max-height: 89%;
}
</style>