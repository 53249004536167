<template>
    <div class="content-container navbar-offset">
      <div class="content">
        <div class="content-section">
          <content-section-heading :isLoaded="isLoaded" text="Engineer Notifications" />
          <div class="content-section-container">
            <transition
              enter-active-class="animated fadeIn faster"
              leave-active-class="animated fadeOut faster"
              mode="out-in"
            >
              <div 
                v-if="!isLoaded"
                key="engineerNotificationsSkeleton"
                class="rows-container"
              >
                <scheduled-notification-skeleton />
              </div>
              <div
                v-else
                key="engineerNotifications"
                class="rows-container"
              >
                <scheduled-notification
                  v-for="notification in engineerNotifications"
                  :key="notification.notificationId"
                  :id="notification.notificationId"
                  :hour="notification.hour"
                  :minute="notification.minute"
                  :isEmail="false"
                  :isSms="true"
                />
                <button
                  class="row button-row w-inline-block"
                  @click="createEngineerNotification(); showCreateModal();"
                >
                  <div class="row-button-label">Add <span class="strong">engineer notification</span></div>
                  <div class="icon" style="font-size: 0;"><img src="/assets/img/add-white.svg" alt="Add engineer notification"></div>
                </button>
              </div>
            </transition>
          </div>
        </div>

        <div class="content-section">
          <content-section-heading :isLoaded="isLoaded" text="Field Service Manager Notifications" />
          <div class="content-section-container">
            <transition
              enter-active-class="animated fadeIn faster"
              leave-active-class="animated fadeOut faster"
              mode="out-in"
            >
              <div 
                v-if="!isLoaded"
                key="fieldServiceManagerNotificationsSkeleton"
                class="rows-container"
              >
                <scheduled-notification-skeleton />
              </div>
              <div
                v-else
                key="fieldServiceManagerNotifications"
                class="rows-container"
              >
                <scheduled-notification
                  v-for="notification in fieldServiceManagerNotifications"
                  :key="notification.notificationId"
                  :id="notification.notificationId"
                  :hour="notification.hour"
                  :minute="notification.minute"
                  :isEmail="true"
                  :isSms="true"
                />
                <button
                  class="row button-row w-inline-block"
                  @click="createFsmEngineerWelfareNotification(); showCreateModal();"
                >
                  <div class="row-button-label">Add <span class="strong">field service manager notification</span></div>
                  <div class="icon" style="font-size: 0;"><img src="/assets/img/add-white.svg" alt="Add field service manager notification"></div>
                </button>
              </div>
            </transition>
          </div>
        </div>

        <div class="content-section">
          <content-section-heading :isLoaded="isLoaded" text="Service Manager Notifications" />
          <div class="content-section-container">
            <transition
              enter-active-class="animated fadeIn faster"
              leave-active-class="animated fadeOut faster"
              mode="out-in"
            >
              <div 
                v-if="!isLoaded"
                key="serviceManagerNotificationsSkeleton"
                class="rows-container"
              >
                <scheduled-notification-skeleton />
              </div>
              <div
                v-else
                key="serviceManagerNotifications"
                class="rows-container"
              >
                <scheduled-notification
                  v-for="notification in serviceManagerNotifications"
                  :key="notification.notificationId"
                  :id="notification.notificationId"
                  :hour="notification.hour"
                  :minute="notification.minute"
                  :isEmail="true"
                  :isSms="true"
                />
                <button
                  class="row button-row w-inline-block"
                  @click="createSmEngineerWelfareNotification(); showCreateModal();"
                >
                  <div class="row-button-label">Add <span class="strong">service manager notification</span></div>
                  <div class="icon" style="font-size: 0;"><img src="/assets/img/add-white.svg" alt="Add service manager notification"></div>
                </button>
              </div>
            </transition>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
// External packages
import { mapActions, mapGetters } from "vuex";

// Components
import ContentSectionHeading from "../../components/ContentSectionHeading";
import ScheduledNotificationSkeleton from "./ScheduledNotificationSkeleton";
import ScheduledNotification from "./ScheduledNotificationRow";

export default {
  components: {
    ScheduledNotification,
    ContentSectionHeading,
    ScheduledNotificationSkeleton
  },
  computed: {
    ...mapGetters({
      isLoaded: "notifications/isLoaded",
      engineerNotifications: "notifications/engineerNotifications",
      fieldServiceManagerNotifications: "notifications/fieldServiceManagerNotifications",
      serviceManagerNotifications: "notifications/serviceManagerNotifications"
    })
  },
  methods: {
    ...mapActions({
      createEngineerNotification: "notifications/createEngineerNotification",
      createFsmEngineerWelfareNotification: "notifications/createFsmEngineerWelfareNotification",
      createSmEngineerWelfareNotification: "notifications/createSmEngineerWelfareNotification",
      loadNotifications: "notifications/loadNotifications",
      showModal: "modal/showModal"
    }),
    showCreateModal() {
      this.showModal("ScheduledNotificationCreate");
    }
  },
  mounted() {
      this.loadNotifications();
  }
};
</script>