<template>
    <div class="bottom-navbar">
      <primary-menu :isBottom="true" />
    </div>
</template>

<script>
// Components
import PrimaryMenu from "./PrimaryMenu";

export default {
    components: {
        PrimaryMenu
    }
};
</script>