<template>
    <transition
        appear
        enter-active-class="animated fadeIn faster"
        leave-active-class="animated fadeOut faster"
        mode="out-in"
    >
        <div v-if="visits.length === 0" key="emptyMessage" class="message-container">
            <img src="/assets/illustrations/undraw_empty_xct9-2.svg" alt="" class="message-illustration">
            <h6 class="message-title">Nothing to see here...</h6>
            <div class="text-smaller">There {{ visitsDate.isBefore(getNow()) ? "were" : "are" }} no visits scheduled for the {{ visitsDate.format("Do") }}.</div>
        </div>
        <div v-else :key="date" class="rows-container grow">
            <div class="rows-header">
                <div class="text-bolder">Visits scheduled for the {{ visitsDate.format("Do MMM YYYY") }}</div>
            </div>
            <contract-visit
                v-for="visit in visits"
                :key="visit.id"
                :visit="visit"
            />
        </div>
    </transition>
</template>

<script>
// External libraries
import moment from "moment";

// Components
import ContractVisit from "./ContractVisit";

export default {
    props: {
        date: {
            type: String,
            required: true
        },
        visits: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            visitsDate: moment(this.date)
        };
    },
    components: {
        ContractVisit
    },
    watch: {
        date: function() {
            this.visitsDate = moment(this.date);
        }
    },
    methods: {
        getNow() {
          return moment();
        }
    }
};
</script>