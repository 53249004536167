<template>
  <div class="content-container navbar-offset d-flex flex-column">
    <full-screen-modal
      :show="calendar"
      :heading="selectedEngineer.name"
      :subheading="`Week ${formatDate(day, 'w')}, ${formatDate(day, 'MMMM YYYY')}`"
      @close="calendar = false"
    >
      <week-calendar
        :key="`${selectedEngineer.id}_${day}`"
        :day="day"
        :engineerId="selectedEngineer.id"
        :style="{ 'height': calendarHeight }"
      />
    </full-screen-modal>

    <v-menu
      :close-on-content-click="false"
      max-width="290"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          :value="formattedRange"
          readonly
          outlined
          dense
          v-bind="attrs"
          v-on="on"
          class="date-range-picker text-center flex-grow-0"
          style="width: 310px;"
          @click:clear="range = null"
        >
          <template v-slot:prepend-inner>
            <v-icon small class="mt-1">mdi-calendar-blank-outline</v-icon>
          </template>
        </v-text-field>
      </template>
      <v-date-picker
        v-model="range"
        no-title
        range
        flat
        first-day-of-week="1"
        show-adjacent-months
        @change="reload"
      ></v-date-picker>
    </v-menu>

    <div class="charts">
      <v-row
        no-gutters
        class="no-border pa-0 ma-0"
      >
        <v-col
          cols="12"
          md="4"
        >
          <UtilisationSummary
            :man-hours="globalManHours"
            :target="80"
            :utilisation="globalUtilisation"
          />
        </v-col>
        <v-col
          cols="12"
          md="8"
        >
          <UtilisationAnalysis
            class="ml-6 pa-2"
          />
        </v-col>
      </v-row>
    </div>

    <v-data-table
      class="regions-table mt-8"
      :headers="headers"
      :items="regions"
      :expanded.sync="expandedRegions"
      :loading="loading"
      :height="tableHeight"
      item-key="name"
      show-expand
      fixed-header
      :items-per-page="-1"
    >
      <template v-slot:[`item.name`]="{ item }">
        <div :class="{ 'font-weight-bold': isExpandedRegion(item) }">{{ item.name }}</div>
      </template>
      <template v-slot:[`item.potential.actual`]="{ item }">
        <div :class="{ 'font-weight-bold': isExpandedRegion(item) }">{{ humanizeMinutes(item.potential.actual, [ 'h' ]) }}</div>
      </template>
      <template v-slot:[`item.allocated`]="{ item }">
        <div :class="{ 'font-weight-bold': isExpandedRegion(item) }">{{ humanizeMinutes(item.allocated, [ 'h' ]) }}</div>
      </template>
      <template v-slot:[`item.utilisation`]="{ item: { id, utilisation } }">
        <div :class="{ 'font-weight-bold': isExpandedRegion({ id }) }">{{ utilisation.toFixed(0) }}%</div>
      </template>
      <template v-slot:[`item.target`]="{ item }">
        <div :class="{ 'font-weight-bold': isExpandedRegion(item) }">{{ item.target }}%</div>
      </template>
      <template v-slot:[`item.chart`]="{ item: { utilisation, target } }">
        <v-progress-linear
          class="bullet-chart-outer rounded-sm"
          :value="target"
          height="16"
          color="light-blue lighten-4"
        >
          <v-progress-linear
            rounded
            class="bullet-chart-inner"
            :value="utilisation"
            height="4"
          />
        </v-progress-linear>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td
          :colspan="headers.length"
          class="px-0"
        >
          <v-data-table
            hide-default-footer
            :headers="headers"
            :headers-length="headers.length"
            :items="item.engineers"
            :expanded.sync="item.expandedEngineers"
            item-key="id"
            show-expand
            :items-per-page="-1"
            class="expanded ml-4"
          >
            <template v-slot:[`item.name`]="{ item }">
              <div :class="{ 'font-weight-medium': isExpandedEngineer(item) }">{{ item.name }}</div>
            </template>
            <template v-slot:[`item.potential.actual`]="{ item }">
              <div :class="{ 'font-weight-medium': isExpandedEngineer(item) }">{{ humanizeMinutes(item.potential.actual, [ 'h' ]) }}</div>
            </template>
            <template v-slot:[`item.allocated`]="{ item }">
              <div :class="{ 'font-weight-medium': isExpandedEngineer(item) }">{{ humanizeMinutes(item.allocated, [ 'h' ]) }}</div>
            </template>
            <template v-slot:[`item.utilisation`]="{ item: { id, regionId, utilisation } }">
              <div :class="{ 'font-weight-medium': isExpandedEngineer({ id, regionId }) }">{{ utilisation.toFixed(0) }}%</div>
            </template>
            <template v-slot:[`item.target`]="{ item }">
              <div :class="{ 'font-weight-medium': isExpandedEngineer(item) }">{{ item.target }}%</div>
            </template>
            <template v-slot:[`item.chart`]="{ item: { utilisation, target } }">
              <v-progress-linear
                class="bullet-chart-outer rounded-sm"
                :value="target"
                height="16"
                color="light-blue lighten-4"
              >
                <v-progress-linear
                  rounded
                  class="bullet-chart-inner"
                  :value="utilisation"
                  height="4"
                />
              </v-progress-linear>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td 
                class="px-0"
                :colspan="headers.length"
              >
                <v-sheet
                  :width="sheetWidth"
                >
                  <v-slide-group
                    show-arrows
                    class="d-flex"
                  >
                    <v-slide-item
                      v-for="([ day, { potential: { actual }, productive, travel, unavailable, utilisation, isWeekend } ], index) of Object.entries(item.days)"
                      :key="day"
                    >
                      <div
                        :class="{'mr-6': isEndOfWeek(day) }"
                        class="d-flex flex-column my-6"
                        style="position: relative;"
                      >
                        <div
                          class="ml-1 text--secondary"
                          style="height: 20px; width: 150px; position: absolute; top: 0;"
                        >
                          {{ index === 0 || isStartOfWeek(day) ? getWeekRange(day) : '' }}
                        </div>

                        <div
                          class="text-caption text--secondary font-weight-medium ml-1 mt-5"
                        >
                          {{ getDayOfWeek(day) }}
                        </div>
                        <v-tooltip
                          bottom
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <div
                              class="rounded d-flex align-center justify-center mr-1 mb-6 text-caption"
                              :class="[ !isWeekend && actual > 0 ? utilisationColor(utilisation) : 'grey lighten-4' ]"
                              :style="{'border': !isWeekend && actual > 0 && utilisation < 40 ? '#EF9A9A 1px solid !important' : '' }"
                              style="width: 52px; height: 30px"
                              v-bind="attrs"
                              v-on="on"
                            >
                              {{ utilisation.toFixed(0) }}%
                            </div>
                          </template>
                          <div class="mb-1">
                            <span class="font-weight-bold">{{ formatDate(day, 'ddd, Do of MMMM YYYY') }}</span>
                          </div>
                          <div v-if="productive > 0">
                            <span class="font-weight-bold">On-site:</span> {{ humanizeMinutes(productive, [ 'h', 'm' ]) }}
                          </div>
                          <div v-if="travel > 0">
                            <span class="font-weight-bold">Travel:</span> {{ humanizeMinutes(travel, [ 'h', 'm' ]) }}
                          </div>
                          <div v-if="unavailable > 0">
                            <span class="font-weight-bold">Unavailable:</span> {{ humanizeMinutes(unavailable, [ 'h', 'm' ]) }}
                          </div>
                          <div v-if="actual > 0">
                            <div>
                              <span class="font-weight-bold">Available:</span> {{ humanizeMinutes(actual, [ 'h', 'm' ]) }}
                            </div>
                            <div>
                              <span class="font-weight-bold">Utilisation:</span> (<span class="font-weight-medium">on-site</span> + <span class="font-weight-medium">travel</span>) / <span class="font-weight-medium">available</span> = {{ utilisation.toFixed(0) }}%
                            </div>
                          </div>
                        </v-tooltip>

                        <a
                          v-if="index === 0 || isStartOfWeek(day)"
                          class="ml-1 text-decoration-none text-caption text--secondary"
                          style="position: absolute; bottom: 0; z-index: 1; font-size: 0.75em;"
                          href="#"
                          @click="showCalendar(day, item)"
                        >
                          View details
                        </a>
                      </div>
                    </v-slide-item>
                  </v-slide-group>
                </v-sheet>
              </td>
            </template>
          </v-data-table>
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
// Components
import FullScreenModal from '@/components/modals/FullScreenModal';
import UtilisationAnalysis from './UtilisationAnalysis.vue';
import UtilisationSummary from './UtilisationSummary.vue';
import WeekCalendar from './WeekCalendar';

// Mixins
import { momentMixin } from '@/mixins/moment';

// External libraries
import moment from 'moment';
import humanizeDuration from "humanize-duration";
import { cloneDeep, sortBy, uniqWith } from 'lodash';
import { mapActions, mapGetters } from "vuex";

export default {
  mixins: [ momentMixin ],
  data () {
    return {
      regions: [],
      expandedRegions: [],
      headers: [
        {
          text: 'Regions / Engineers',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        { 
          text: 'Man hours',
          align: 'start',
          value: 'potential.actual'
        },
        {
          text: 'Allocated hours',
          align: 'start',
          value: 'allocated'
        },
        { 
          text: 'Utilisation',
          align: 'start',
          value: 'utilisation'  
        },
        { 
          text: 'Target utilisation',
          align: 'start',
          value: 'target'
        },
        {
          text: '', value: 'chart'
        },
        { text: '', value: 'data-table-expand' }
      ],
      utilisation: {},
      range: [
        this.$route.query.start ? moment(this.$route.query.start).format('YYYY-MM-DD') : moment().startOf('month').format('YYYY-MM-DD'),
        this.$route.query.end ? moment(this.$route.query.end).format('YYYY-MM-DD') : moment().endOf('month').format('YYYY-MM-DD')
      ],
      loading: false,
      day: undefined,
      selectedEngineer: {},
      calendar: false,
    }
  },
  components: {
    FullScreenModal,
    UtilisationAnalysis,
    UtilisationSummary,
    WeekCalendar
  },
  computed: {
    ...mapGetters({
      "engineers": "prefs/engineers/engineers",
      "isLoaded": "prefs/engineers/isLoaded",
    }),
    formattedRange() {
      const start = moment(this.range[0]).format('ddd Do, MMM YYYY');
      const end = moment(this.range[1]).format('ddd Do, MMM YYYY');
      return `${start} - ${end}`;
    },
    sheetWidth () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '86vw'
        case 'sm': return '86vw'
        case 'md': return '86vw'
        case 'lg': return '87vw'
        case 'xl': return '90vw'
      }
    },
    calendarHeight () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '86vh'
        case 'sm': return '86vh'
        case 'md': return '75vh'
        case 'lg': return '75vh'
        case 'xl': return '85vh'
      }
    },
    tableHeight () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '90vh'
        case 'sm': return '90vh'
        case 'md': return '75vh'
        case 'lg': return '75vh'
        case 'xl': return '80vh'
      }
    },
    globalManHours() {
      const manHours = this.regions
        .map(r => r.potential.actual)
        .reduce((p, c) => p + c, 0);
      return Math.round(manHours / 60);
    },
    globalUtilisation() {
      const grundfosRegions = [1, 2, 3, 4];
      const regions = this.regions
        .filter(r => grundfosRegions.includes(r.id));
      const available = regions
        .map(r => r.potential.actual)
        .reduce((p, c) => p + c, 0);
      const allocated = regions
        .map(r => r.allocated)
        .reduce((p, c) => p + c, 0);
      console.log('available: ', available);
      console.log('allocated: ', allocated);
      const utilisation = available > 0 ? allocated / available : 0;
      return Math.round(utilisation * 100);
    },
  },
  methods: {
    ...mapActions({
      "loadEngineers": "prefs/engineers/loadEngineers",
      "loadEngineer": "prefs/engineers/loadEngineer",
    }),
    async reload() {
      this.regions = [];
      this.utilisation = {};
      await this.loadUtilisation();
    },
    async loadUtilisation() {
      try {
        this.loading = true;
        const api = await this.$api();
        const { data } = await api.get('/engineers/utilisation', { params: { start: this.range[0], end: this.range[1] } });
        this.utilisation = data;
        await this.loadRegions();
      } finally {
        this.loading = false;
      }
    },
    async loadRegions() {
      const template = {
        potential: {
          max: 0,
          actual: 0
        },
        unavailable: 0,
        productive: 0,
        travel: 0,
        allocated: 0,
        utilisation: 0,
        target: 80,
        chart: 'chart'
      };

      const regions = sortBy(
        uniqWith(
          Object.values(this.engineers).map(e => ({ id: e.regionId, name: e.region })), 
          (a, b) => a.id === b.id
        ), [ 'id' ]
      )
      .map(({ id, name }) => ({ 
        id,
        name,
        ...cloneDeep(template),
        engineers: [],
        expandedEngineers: []
      }));

      for (const [ engineerId, days ] of Object.entries(this.utilisation)) {
        let engineer = this.engineers[engineerId];
        if (!engineer) {
          await this.loadEngineer(engineerId);
          engineer = this.engineers[engineerId];
        }

        const stats = {
          id: Number(engineerId),
          name: `${engineer.firstname} ${engineer.surname}`,
          regionId: engineer.regionId,
          ...cloneDeep(template),
          days
        };
        const region = regions.find(r => r.id === engineer.regionId);
        for (const [ day, dayStats ] of Object.entries(days)) {
          const isoWeekday = moment(day).isoWeekday();
          const isWeekend = isoWeekday === 6 || isoWeekday === 7;
          const { potential: { max, actual }, productive, travel, allocated } = dayStats;
          dayStats.utilisation = this.getUtilisation(dayStats);
          dayStats.isWeekend = isWeekend;

          // Exclude weekdays from the utilisation calculations
          if (!isWeekend) {
            region.potential.max += max;
            region.potential.actual += actual;
            region.productive += productive;
            region.travel += travel;
            region.allocated += allocated;

            stats.potential.max += max;
            stats.potential.actual += actual;
            stats.productive += productive;
            stats.travel += travel;
            stats.allocated += allocated;
          }
        }

        region.utilisation = this.getUtilisation(region);
        stats.utilisation = this.getUtilisation(stats);
        region.engineers.push(stats);
      }

      // Sort engineers by their utilisation stats
      for (const region of regions) {
        region.engineers.sort((a, b) => a.utilisation - b.utilisation);
      }

      this.regions = regions;
    },
    humanizeMinutes(minutes, units = [ 'h' ]) {
      return humanizeDuration(minutes * 60 * 1000, { units, round: true });
    },
    isExpandedRegion(region) {
      return this.expandedRegions.find(r => r.id === region.id) !== undefined;
    },
    isExpandedEngineer(engineer) {
      let isExpanded = false;
      const expandedRegion = this.expandedRegions.find(r => r.id === engineer.regionId);
      if (expandedRegion) {
        isExpanded = expandedRegion.expandedEngineers.find(e => e.id === engineer.id) !== undefined;
      }
      return isExpanded;
    },
    getUtilisation(stats) {
      const { potential: { actual, max }, allocated } = stats;
      const calculated = (actual > 0 && max > 0 ? allocated / actual : 0);
      return calculated * 100;
    },
    getDayOfWeek(date) {
      return moment(date).format('ddd');
    },
    getWeekRange(date) {
      const selectedEnd = moment(this.range[1]);
      const start = moment(date);
      let end = moment(date).endOf('isoWeek');
      if (end.isAfter(selectedEnd)) {
        end = selectedEnd;
      }

      let range = `${start.format('MMM Do')} - ${end.format('Do')}`;
      if (end.isSame(start, 'day')) {
        range = `${start.format('MMM Do')}`;
      }

      return range;
    },
    isStartOfWeek(date) {
      const startOfWeek = moment(date).startOf('isoWeek');
      const actual = moment(date);
      return startOfWeek.isSame(actual, 'day');
    },
    isEndOfWeek(date) {
      const endOfWeek = moment(date).endOf('isoWeek');
      const actual = moment(date);
      return endOfWeek.isSame(actual, 'day');
    },
    utilisationColor(value) {
      if (value > 80) return 'light-blue lighten-1 white--text';
      else return 'light-blue lighten-4';
    },
    showCalendar(day, engineer) {
      this.day = day;
      this.selectedEngineer = engineer;
      this.calendar = true;
    }
  },
  async mounted() {
    try {
      this.loading = true;
      await this.loadEngineers();
      await this.loadUtilisation();
    } finally {
      this.loading = false;
    }
  }  
}
</script>

<style>
.regions-table {
  width: 100%;
}

.expanded thead > tr > th {
  opacity: 0;
  height: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  border-bottom: none !important;
}

.v-chip__content {
  width: 100% !important;
}

.date-range-picker .v-text-field__slot > input {
  text-align: center;
  font-size: 0.85em;
  color: #424242;
}

.bullet-chart-outer {
  width: 85px;
}
</style>