<template>
    <div class="profile-container">
        <div class="profile-card">
            <avatar-skeleton />
            <fields-skeleton />
        </div>
    </div>
</template>

<script>
// Components
import AvatarSkeleton from "./AvatarSkeleton";
import FieldsSkeleton from "./FieldsSkeleton";

export default {
    components: {
        AvatarSkeleton,
        FieldsSkeleton
    }
};
</script>