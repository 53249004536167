<template>
    <div class="row my-1">
        <v-skeleton-loader class="row-icon" width="20px" type="button"></v-skeleton-loader>
        <div class="row-heading mobile-width-80"><v-skeleton-loader width="50px" type="text"></v-skeleton-loader></div>
        <div class="row-fields">
            <div class="row-field">
                <div class="row-field-label"><v-skeleton-loader width="50px" type="text"></v-skeleton-loader></div>
                <div class="row-field-value"><v-skeleton-loader width="75px" type="text"></v-skeleton-loader></div>
            </div>
            <div class="row-field">
                <div class="row-field-label"><v-skeleton-loader width="50px" type="text"></v-skeleton-loader></div>
                <div class="row-field-value"><v-skeleton-loader width="75px" type="text"></v-skeleton-loader></div>
            </div>
            <div class="row-field">
                <div class="row-field-label"><v-skeleton-loader width="50px" type="text"></v-skeleton-loader></div>
                <div class="row-field-value"><v-skeleton-loader width="75px" type="text"></v-skeleton-loader></div>
            </div>
            <div class="row-field">
                <div class="row-field-label"><v-skeleton-loader width="50px" type="text"></v-skeleton-loader></div>
                <div class="row-field-value"><v-skeleton-loader width="75px" type="text"></v-skeleton-loader></div>
            </div>
        </div>
    </div>
</template>

<script>
// Components
import { VSkeletonLoader } from "vuetify/lib/components/VSkeletonLoader";

export default {
    components: {
        VSkeletonLoader
    }
}
</script>