<template>
  <div>
    <v-data-table
      :loading="isLoading"
      :loading-text="$t('loading_jobs')"
      :headers="headers"
      :items="jobs"
      item-key="id"
      :sort-by="['typeId', 'statusId']"
      :sort-desc="[false, false]"
      multi-sort
      show-expand
      class="elevation-0"
    >
      <template v-slot:[`item.typeId`]="{ item }">
        <v-chip
          small
          class="font-weight-regular mr-2 px-4"
          dark
          :color="colours.type[item.typeId].background"
        >
          {{ item.type }}
        </v-chip>
      </template>
      <template v-slot:[`item.statusId`]="{ item }">
        <v-chip
          small
          class="font-weight-regular mr-2 px-4"
          dark
          :color="colours.status[item.statusId].background"
        >
          <v-icon
            left
            small
          >
            {{ colours.status[item.statusId].icon }}
          </v-icon>
          {{ item.status }}
        </v-chip>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td class="pa-0" :colspan="headers.length">
          <job-details
            :id="item.id"
            :key="item.id"
            @deleted="onJobDeleted"
          />
        </td>
      </template>
    </v-data-table>
    <v-snackbar
      v-model="snack"
      :timeout="snackTimeout"
      :color="snackColour"
    >
      {{ snackText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          text
          @click="snack = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
// Mixins
import { jobMixin } from '@/mixins/job';

// Components
import JobDetails from './JobDetails';

export default {
  mixins: [ jobMixin ],
  props: {
    jobs: {
      type: Array,
      default: []
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  components: {
    JobDetails
  },
  watch: {
    jobs: function(val) {
      for (const job of val) {
        this.setTypeLabel(job);
        this.setStatusLabel(job);
      }
    }
  },
  data() {
    return {
      headers: [
          { text: 'Reference', value: 'reference' },
          { text: 'Type', value: 'typeId' },
          { text: 'Status', value: 'statusId' },
          { text: 'Customer', value: 'workOrder.customer.name' },
          { text: 'Site', value: 'site.name' },
        ],
      snack: false,
      snackTimeout: 5000,
      snackColour: '',
      snackText: '',
    };
  },
  methods: {
    setTypeLabel(job) {
      job.type = this.$t(`job_type_${job.typeId}`);
    },
    setStatusLabel(job) {
      job.status = this.$t(`job_status_${job.statusId}`);
    },
    showSnack(text, colour = 'primary') {
      this.snack = true;
      this.snackColour = colour;
      this.snackText = text;
    },
    onJobDeleted({ job: { reference } }) {
      const vm = this;
      setTimeout(() => {
        vm.showSnack(vm.$t('job_delete_success', { reference }), 'success');
      }, 500);
    }
  },
}
</script>

<style>
.v-data-table > .v-data-table__wrapper tbody tr:first-child:hover td {
  border-radius: 0 !important;
}
</style>