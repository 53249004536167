import Vue from "vue";

export default async (customHeaders, token) => {
    const headers = { ...customHeaders };

    const { $auth } = Vue.prototype;
    if (!token && $auth) {
      try {
        token = await $auth.getTokenSilently();
      } catch (err) {
        console.warn('Could not get authentication token. Is the user authenticated?', err);
      }
    }

    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }
    
    return Vue.prototype.$http.create({
        baseURL: process.env.VUE_APP_API_BASE_URL,
        headers: headers
    });
};