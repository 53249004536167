<template>
    <transition
        enter-active-class="animated fadeInUp faster"
    >
        <v-overlay 
            color="white"
            opacity="0.6"
            v-show="isExecuting || isExecuted"
        >
            <div 
                v-show="isExecuting"
                ref="executing"
                data-name="executing"
                class="lottie anim"
                data-animation-path="/assets/lottie/executing_primary.json"
                data-anim-loop="true"
                data-anim-type="svg"
            ></div>
            <div 
                v-show="isExecuted"
                ref="executed"
                data-name="executed"
                class="lottie anim"
                data-animation-path="/assets/lottie/executed_primary.json"
                data-anim-loop="false"
                data-anim-type="svg"
            ></div>

            <div class="text-center text-h4 secondary--text">
                <transition
                    enter-active-class="animated fadeInLeft faster"
                    leave-active-class="animated fadeOutRight faster"
                    mode="out-in"
                >
                    <div v-if="isExecuting" key="executing">{{ executingLabel }}</div>
                    <div v-else-if="isExecuted" key="executed">{{ executedLabel }}</div>
                </transition>
            </div>
        </v-overlay>
    </transition>
</template>

<script>
import { getRegisteredAnimations, goToAndStop, registerAnimation, play, stop } from "lottie-web";

export default {
  props: {
    isExecuting: {
      type: Boolean,
      required: true
    },
    executingLabel: {
      type: String,
      default: "Saving"
    },
    executedLabel: {
      type: String,
      default: "Saved"
    },
    isWarning: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isRegistered: false,
      isExecuted: false,
    };
  },
  watch: {
    isExecuting(value) {
      if (value) {
        this.startExecuting();
      } else {
        this.startExecuted();
      }
    }
  },
  methods: {
    startExecuting() {
      this.resetAnimations();
      play("executing");
    },
    startExecuted() {
      this.isExecuted = true;
      stop("executing");
      play("executed");
    },
    stopExecuted() {
      if (this.isExecuted) {
        this.isExecuted = false;
        this.resetAnimations();
        this.$emit("animationComplete");
      }
    },
    resetAnimations() {
      goToAndStop(0);
    },
  },
  mounted() {
    registerAnimation(this.$refs.executing);
    registerAnimation(this.$refs.executed);
    const vm = this;
    const animations = getRegisteredAnimations();
    const savedAnim = getRegisteredAnimations().find(a => a.name === "executed");
    savedAnim.addEventListener("complete", () => vm.stopExecuted());
  }
};
</script>

<style scoped>
.lottie.anim {
    height: 10vh;
    max-height: 10vh;
    width: 10vw;
    max-width: 10vw;
}
</style>