<template>
  <div
    class="d-flex flex-column white"
    style="width: 100%; height: 100%;"
  >
    <div v-if="loading" class="d-flex" style="height: 100%">
      <v-progress-circular
        :size="120"
        :width="8"
        color="primary"
        class="ma-auto"
        indeterminate
      >
        <span class="text-center font-weight-thin">{{ loadingMessage }}</span>
      </v-progress-circular>
    </div>

    <v-row
      v-else
      no-gutters
      align="center"
      justify="center"
      class="no-border rounded-0"
    >
      <v-col
        align-self="center"
        style="height: 100%"
      >
        <progress-overlay 
          :isExecuting="saving" 
          @animationComplete="resetAndClose"
        />

        <v-stepper
          v-model="step"
          style="height: 100%"
        >

          <v-stepper-items
            style="height: 100%"
            class="d-flex flex-column"
          >
            <slot v-bind="{ steps, next, previous, finish: tryFinish, close: resetAndClose }"></slot>
          </v-stepper-items>
        </v-stepper>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// Components
import ProgressOverlay from '@/components/overlays/ProgressOverlay';

export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    loadingMessage: {
      type: String,
      default: 'loading'
    },
    steps: {
      type: Number,
      required: true
    },
    finish: {
      type: Function,
      required: true
    },
    close: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      step: 1,
      saving: false
    }
  },
  components: {
    ProgressOverlay
  },
  methods: {
    next() {
      this.step += 1;
    },
    previous() {
      this.step -= 1;
    },
    resetAndClose() {
      this.step = 1;
      this.close();
    },
    async tryFinish() {
      this.saving = true;
      try {
        await this.finish();
      } finally {
        this.saving = false;
      }
    }
  }
}
</script>