<template>
    <div class="hero-block">
        <div class="hero-details">
            <div class="chip bg-primary-2 light">
                visit details
            </div>
            <h3 class="hero-title">Site visit</h3>
            <visit 
                class="hero-description"
                :visit="visit"
            />

            <div class="comment-container">
                <div class="comment-label">Job type:</div>
                <div class="comment">{{ visit.jobType }}</div>
                <br />
                <div class="comment-label">Site details:</div>
                <div class="comment" v-html="site"></div>
            </div>
        </div>
        <div class="hero-illustration">
            <img v-if="visit.isLateDeparture" src="/assets/illustrations/undraw_time_management_30iu.svg" alt="">
            <img v-else-if="visit.isTravellingToSite || visit.isTravellingHome" src="/assets/illustrations/undraw_navigator_a479.svg" alt="">
            <img v-else src="/assets/illustrations/undraw_QA_engineers_dg5p.svg" alt="">
        </div>
    </div>
</template>

<script>
import Visit from "../descriptions/Visit";

export default {
    props: {
        visit: {
            type: Object,
            required: true
        }
    },
    components: {
        Visit
    },
    computed: {
        site() {
            let lines = [];
            const { site } = this.visit;
            if (site.name) {
                lines.push(site.name);
            }

            const address = { ...site.address };
            delete address.isoCode;
            delete address.country;
            delete address.countryId;
            delete address.id;
            lines = [ ...lines, ...Object.values(address) ];
            lines = lines.filter(v => v);
            return lines.join("<br />");
        }
    }
};
</script>