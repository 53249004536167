<template>
  <stepper
    :steps="7"
    :close="close"
    :finish="saveCallout"
  >
    <template v-slot:default="{ steps, next, previous, finish, close }">
      <step
        :step="1"
        :steps="steps"
        :next="next"
        :previous="previous"
        :close="close"
        :isValid="Boolean(contract)"
        heading="Which service contract is the call-out for?"
      >
        <v-autocomplete
          v-model="contract"
          ref="input_contract"
          :loading="loading"
          :items="contracts"
          :search-input.sync="query"
          :rules="[v => !!v || 'You have to select a service contract']"
          required
          hint="Search for contracts using their reference, customer details and site details"
          class="mt-6"
          item-text="reference"
          return-object
          auto-select-first
          flat
          outlined
          persistent-hint
          clearable
          no-filter
        >
          <template v-slot:no-data>
            <v-list-item>
              <div
                v-if="!lastQuery"
              >
                <v-list-item-title v-if="!lastQuery">
                  Search for <strong>service contracts</strong>
                </v-list-item-title>
              </div>
              <div
                v-else
              >
                <v-list-item-title>
                  No service contracts matching <strong>{{ lastQuery }}</strong> found
                </v-list-item-title>
                <v-row
                  no-gutters
                  class="no-border pa-0"
                >
                  <v-col>
                    <div class="mt-5 grey lighten-4 rounded-lg pa-5">
                      <div class="text-subtitle-1 font-weight-medium mb-4">
                        In this case
                      </div>
                      <p class="text-body-2">
                        Please make sure you have explored other criteria while searching for a contract, such as the <span class="text-decoration-underline">site's address</span>, <span class="text-decoration-underline">postcode</span>, <span class="text-decoration-underline">customer details</span> and <span class="text-decoration-underline">contract reference</span>.
                      </p>
                      <p class="text-body-2">
                        If you still do not find it, then it may not be possible to continue logging a call-out for this customer. If the customer insists or if you feel that this is an emergency situation, please contact one of the managers below and they will advise you on how to proceed.
                      </p>
                      <v-row
                        no-gutters
                        class="no-border pa-0 mt-4 transparent"
                      >
                        <v-col
                          v-for="{ manager } in serviceManagers"
                          :key="manager.id"
                          cols="2"
                        >
                          <v-list-item
                            class="pa-2 mr-1 rounded"
                            style="overflow: hidden"
                            three-line
                            :href="`tel:${manager.mobile}`"
                            @mousedown.stop
                          >
                            <v-list-item-content>
                              <v-list-item-title>Service Manager</v-list-item-title>
                              <v-list-item-subtitle>{{ manager.firstname }} {{ manager.surname }}</v-list-item-subtitle>
                              <v-list-item-subtitle>{{ manager.mobile }}</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-col>
                        <v-col
                          v-for="{ manager } in fieldServiceManagers"
                          :key="manager.id"
                          cols="2"
                        >
                          <v-list-item
                            class="pa-2 mx-1 rounded"
                            style="overflow: hidden"
                            three-line
                            :href="`tel:${manager.mobile}`"
                            @mousedown.stop
                          >
                            <v-list-item-content>
                              <v-list-item-title>Team Leader</v-list-item-title>
                              <v-list-item-subtitle>{{ manager.firstname }} {{ manager.surname }}</v-list-item-subtitle>
                              <v-list-item-subtitle>{{ manager.mobile }}</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-col>
                      </v-row>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </v-list-item>
          </template>
          <template v-slot:item="{ item }">
            <v-list-item-content>
              <v-list-item-title>{{ `${item.reference} - ${item.customer.name}` }}</v-list-item-title>
              <v-list-item-subtitle>
                <span v-if="isContractExpired(item)" class="font-weight-bold red--text text--darken-2">Expired on</span>
                <span v-else class="font-weight-bold green--text text--darken-2">Active until</span>
                {{ `${getContractExpiry(item).format('Do of MMMM YYYY')}` }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </step>

      <step
        :step="2"
        :steps="steps"
        :next="next"
        :previous="previous"
        :close="close"
        :isValid="Boolean(site)"
        heading="Which site is the call-out for?"
      >
        <v-select
          v-model="site"
          ref="input_site"
          :items="sites"
          :rules="[v => !!v || 'You have to select a site']"
          required
          class="mt-4"
          item-text="name"
          hint="Select an existing site"
          return-object
          flat
          clearable
          outlined
          persistent-hint
        ></v-select>
      </step>

      <step
        :step="3"
        :steps="steps"
        :next="next"
        :previous="previous"
        :close="close"
        :isValid="Boolean(equipment) && Boolean(description)"
        :heading="`What are the details of the asset on ${site ? site.name : 'site'}?`"
      >
        <v-select
          v-model="equipment"
          ref="input_asset"
          :items="siteEquipment"
          :rules="[v => !!v || 'You have to select an asset']"
          required
          class="mt-4"
          item-text="equipmentDescription"
          label="Select an existing asset"
          return-object
          flat
          clearable
          outlined
          persistent-hint
        >
          <template v-slot:item="{ item }">
            <v-list-item-content>
              <v-list-item-title>{{ item.equipmentDescription }}</v-list-item-title>
              <v-list-item-subtitle><v-icon small class="mr-2">mdi-tag-outline</v-icon>{{ item.assetTag }}</v-list-item-subtitle>
              <v-list-item-subtitle v-if="!item.product.code.toLowerCase().includes('unkpro')">
                <v-icon small class="mr-2">mdi-engine-outline</v-icon>{{ item.product.code }} - {{ item.product.description }}
              </v-list-item-subtitle>
              <v-list-item-subtitle v-else>
                <v-icon small class="mr-2">mdi-engine-outline</v-icon>{{ $t('asset_product_thirdparty_code') }} - {{ $t('asset_product_thirdparty_description') }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-select>
        <v-textarea
          v-model="description"
          ref="input_description"
          outlined
          class="mt-5"
          label="What is the issue?"
        ></v-textarea>
      </step>

      <step
        :step="4"
        :steps="steps"
        :next="next"
        :previous="previous"
        :close="close"
        :isValid="Boolean(contact)"
        heading="Who is the site contact for this call-out?"
      >
        <call-out-contacts
          :site="site || {}"
          :filter="dbContract ? [ dbContract.contactId ] : []"
          @select="onSelectContact"
        />
      </step>

      <step
        :step="5"
        :steps="steps"
        :next="next"
        :previous="previous"
        :close="close"
        :heading="`Is there a purchase order for this call-out?`"
        :isValid="!hasPurchaseOrder || Boolean(purchaseOrder)"
      >
        <v-switch
          v-model="hasPurchaseOrder"
          ref="input_has_purchase_order"
          inset
          :label="hasPurchaseOrder ? 'yes' : 'no'"
          class="mt-5 ml-3"
        ></v-switch>
        <div
          class="text-caption ml-3 mb-4"
        >
          Grundfos will require a PO for this visit. If the customer has a PO now, please change to <span class="font-weight-bold">yes</span> and record it. Otherwise, leave this set to <span class="font-weight-bold">no</span> and we will collect it from the customer later.
        </div>
        <v-text-field
          v-if="hasPurchaseOrder"
          ref="input_purchase_order"
          outlined
          class="mt-2"
          label="Purchase order"
          v-model="purchaseOrder"
        ></v-text-field>
      </step>

      <step
        :step="6"
        :steps="steps"
        :next="next"
        :previous="previous"
        :close="close"
        :heading="`Is there anything the engineer should know when he arrives on site?`"
        :isValid="!hasPrerequisite || Boolean(prerequisite)"
      >
        <v-switch
          v-model="hasPrerequisite"
          ref="input_has_prerequisite"
          inset
          :label="hasPrerequisite ? 'yes' : 'no'"
          class="mt-5 ml-3"
        ></v-switch>
        <v-textarea
          v-if="hasPrerequisite"
          ref="input_prerequisite"
          outlined
          class="mt-2"
          label="Notes for the engineer"
          hint="Indicate here if there are any door codes, references to find the building, additional person to contact on site, where to park, etc."
          persistent-hint
          v-model="prerequisite"
        ></v-textarea>
      </step>

      <step
        :step="7"
        :steps="steps"
        :next="next"
        :previous="previous"
        :close="close"
        :finish="finish"
        :isValid="selectedEngineers.length > 0"
        heading="Which engineer should attend site?"
      >
        <v-item-group
          v-model="selectedEngineers"
          multiple
        >
          <v-row
            class="no-border"
          >
            <v-col
              v-for="engineer in calloutEngineers"
              :key="engineer.id"
              cols="4"
              xl="3"
              md="4"
              sm="12"
            >
              <v-item :value="engineer.id" v-slot="{ active, toggle }">
                <call-out-engineer
                  :engineer="engineer"
                  :active="active"
                  :toggle="toggle"
                  :isPrimary="selectedEngineers[0] === engineer.id"
                  :fieldServiceManager="engineer.fieldServiceManager || {}"
                  :serviceManager="engineer.serviceManager || {}"
                />
              </v-item>
            </v-col>
          </v-row>
        </v-item-group>
      </step>
    </template>
  </stepper>
</template>

<script>
// External libraries
import moment from 'moment-timezone';
import { isEqualWith, orderBy, uniqWith } from 'lodash';
import { mapActions, mapGetters } from "vuex";

// Components
import CallOutContacts from './CallOutContacts';
import CallOutEngineer from './CallOutEngineer';
import Step from '@/components/stepper/Step';
import Stepper from '@/components/stepper/Stepper';

export default {
  data() {
    return {
      step: 1,
      contract: undefined,
      contracts: [],
      dbContract: undefined,
      loading: false,
      query: undefined,
      lastQuery: undefined,
      scheduledSearch: undefined,
      site: undefined,
      sites: [],
      equipment: undefined,
      siteEquipment: [],
      description: "",
      selectedEngineers: [],
      contact: undefined,
      isShowExplanation: false,
      hasPrerequisite: true,
      prerequisite: undefined,
      hasPurchaseOrder: false,
      purchaseOrder: undefined,
      routesToSite: {}
    };
  },
  components: {
    CallOutContacts,
    CallOutEngineer,
    Step,
    Stepper,
  },
  watch: {
    query (val) {
      if (val && (!this.contract || this.contract.reference !== val)) {
        this.scheduleSearch(val);
      }
    },
    contract (val) {
      if (val) {
        this.loadSites(val);
        this.loadDbContract();
      }
    },
    site (val) {
      if (val) {
        this.equipment = undefined;
        this.loadEquipment(this.contract, val);
        this.loadRoutesToSite();
      }
    },
    engineers (val = []) {
      this.loadRoutesToSite();
    }
  },
  computed: {
    ...mapGetters({
      'engineers': 'prefs/engineers/engineers',
      'fieldServiceManagers': 'prefs/engineers/fieldServiceManagers',
      'serviceManagers': 'prefs/engineers/serviceManagers'
    }),
    calloutEngineers: function() {
      const regions = [1, 2, 3, 4];
      let engineers = Object.values(this.engineers).filter(e => regions.indexOf(e.regionId) > -1);
      for (const engineer of engineers.filter(e => e.address !== undefined)) {
        const routeToSite = this.routesToSite[engineer.address.id];
        let travelTimeInSeconds = Number.MAX_VALUE;
        if (routeToSite) {
          ({ travelTimeInSeconds } = routeToSite);
        }
        engineer.travelTime = travelTimeInSeconds;
      }
      engineers = orderBy(engineers, [ 'isOnCall', 'travelTime', 'regionId' ], [ 'desc', 'asc', 'asc' ]);
      return engineers;
    }
  },
  methods: {
    ...mapActions({
      "postJob": "jobs/postJob",
      "postVisits": "jobs/postVisits",
      'loadManagers': 'prefs/engineers/loadManagers',
      'loadEngineers': 'prefs/engineers/loadEngineers'
    }),
    async scheduleSearch(query) {
      if (this.scheduledSearch) {
        clearTimeout(this.scheduledSearch);
      }

      this.scheduledSearch = setTimeout(() => this.searchContracts(query), 500);
    },
    async searchContracts(query) {
      try {
        this.loading = true;
        const api = await this.$api();
        const { data: contracts } = await api.get('/search/contracts', { params: { query } });
        this.contracts = contracts.sort((a, b) => moment(b.endDate).diff(moment(a.endDate)));
        this.contracts = uniqWith(this.contracts, (a, b) => isEqualWith(a, b, (a, b) => a.reference.split('/')[0] === b.reference.split('/')[0]));
        this.lastQuery = query;
      } finally {
        this.loading = false;
      }
    },
    async loadSites(contract) {
      const api = await this.$api();
      const { data: sites } = await api.get(`/contracts/${contract.externalId}/sites`);
      this.sites = sites;
    },
    async loadEquipment(contract, site) {
      const api = await this.$api();
      let { data: siteEquipment } = await api.get(`/contracts/${contract.externalId}/sites/${site.id}/assets`);
      this.siteEquipment.push();
      this.siteEquipment = [ { header: this.$t('assets_header_under_contract') }, ...siteEquipment ];
      this.siteEquipment.push({ divider: true });
      this.siteEquipment.push({ header: this.$t('assets_header_not_under_contract') });
      ({ data: siteEquipment } = await api.get(`/sites/${site.id}/equipment`));
      siteEquipment = siteEquipment.filter(e => !this.siteEquipment.find(se => se.id === e.id));
      this.siteEquipment = [ ...this.siteEquipment, ...siteEquipment ];
    },
    async loadRoutesToSite() {
      if (this.site && this.engineers) {
        const from = this.site.address.id;
        const to = Object.values(this.calloutEngineers).filter(e => e.isOnCall && e.address !== undefined).map(e => e.address.id).join(',');
        const api = await this.$api();
        ({ data: this.routesToSite } = await api.get('/routes', { params: { from, to } }));
      }
    },
    getContractExpiry(contract) {
      return moment(contract.endDate);
    },
    isContractExpired(contract) {
      const now = moment();
      const expiry = moment(contract.endDate);
      return now.isAfter(expiry);
    },
    onSelectContact({ contact }) {
      this.contact = contact;
    },
    showExplanation() {
      this.isShowExplanation = true;
    },
    async loadDbContract() {
      const api = await this.$api();
      ({ data: this.dbContract } = await api.get(`/contracts/${this.contract.externalId}`));
    },
    async saveJob(contract, createdBy) {
      // Get the contract with all the details needed to log the job
      const {
        id: contractId,
        customer: { id: customerId },
        contactId: customerContactId
      } = contract;
      const { id: createdById } = createdBy;

      // Create contacts
      const { id: contactId, isNew, ...contactDetails } = this.contact;
      const siteContact = { 
        type: 2,
        contact: isNew ? contactDetails : undefined,
        contactId: !isNew ? contactId : undefined
      };      
      const contacts = [ 
        {
          type: 3,
          isCallerContact: true,
          contactId: customerContactId
        },
        siteContact
      ];

      // Create work order
      const workOrder = {
        purchaseOrder: this.hasPurchaseOrder ? this.purchaseOrder : "CALLOUT",
        customerId,
        contractId
      };

      // Create pre-requisites
      const prerequisites = [];
      if (this.hasPrerequisite) {
        prerequisites.push({ description: this.prerequisite });
      }

      // Create tasks
      const { equipment: { id: equipmentId }, description } = this;
      const tasks = [
        {
          description,
          equipmentId,
          chargeSchemeId: 7
        }
      ];

      let job = {
        typeId: 5,
        chargeSchemeId: 7,
        chargeTypeId: 2,
        siteId: this.site.id,
        createdById,
        contractId,
        workOrder,
        prerequisites,
        contacts,
        tasks
      };

      job = await this.postJob({ job });

      return job;
    },
    async saveVisits(job, createdBy) {
      const { id: jobId, contacts } = job;
      const { id: createdById } = createdBy;
      const scheduledStartDateTime = moment.utc().format();
      const estimatedDuration = 1;
      const visitStatusId = 7;
      const supportEngineers = this.selectedEngineers.slice(1);
      const supportVisits = [];
      for (const selected of supportEngineers) {
        const supportEngineer = this.engineers[selected];
        const { id: engineerId } = supportEngineer;
        supportVisits.push({
          jobId,
          engineerId,
          createdById,
          scheduledStartDateTime,
          estimatedDuration,
          visitStatusId
        });
      }

      const primaryEngineer = this.engineers[this.selectedEngineers[0]];
      const visit = {
        jobId,
        supportVisits,
        createdById,
        scheduledStartDateTime,
        estimatedDuration,
        visitStatusId,
        engineerId: primaryEngineer.id,
        isPrimaryEngineer: true,
        confirmedById: contacts.find(c => c.isCallerContact).contactId
      };
      await this.postVisits({ job, visits: [ visit ] });
    },
    async saveCallout() {
      const createdBy = this.$auth.profisUser;
      const job = await this.saveJob(this.dbContract, createdBy);
      await this.saveVisits(job, createdBy);
    },
    reset() {
      // Reset all inputs
      const { 
        input_contract, 
        input_site, 
        input_asset, 
        input_description, 
        input_prerequisite,
        input_purchase_order
      } = this.$refs;
      input_contract.reset();
      input_site.reset();
      input_asset.reset();
      input_description.reset();
      if (input_prerequisite) {
        input_prerequisite.reset();
      }
      if (input_purchase_order) {
        input_purchase_order.reset();
      }

      // Reset variables
      this.hasPrerequisite = true;
      this.hasPurchaseOrder = false;
      this.contract = 
        this.query =
        this.lastQuery =
        this.scheduledSearch = 
        this.site = 
        this.equipment =
        this.contact =
        this.prerequisite
        this.purchaseOrder = undefined;
      
      this.contracts =
        this.selectedEngineers =
        this.sites =
        this.siteEquipment = [];
    },
    close() {
      this.reset();
      this.$emit('close');
    }
  },
  async mounted() {
    await this.loadEngineers();
    await this.loadManagers();
  }
};
</script>