<template>
    <div>
        <div class="side-panel-heading">
            <button 
                class="icon transparent w-inline-block"
                @click="hideNotifications"
            >
                <img src="/assets/img/left-black.svg" alt="">
            </button>
            <div>Alerts &amp; Replies</div>
            <!-- Empty div to ensure the title is still in the center of the heading -->
            <div></div>
        </div>
        <div class="side-panel-content">
            <div class="notifications-container">
                <notification
                    v-for="notification in engineer.notifications"
                    :key="notification.id"
                    :timezone="engineer.timezone"
                    :createdOn="notification.createdOn"
                    :status="notification.status"
                    :details="notification.channel === 'sms' ? notification.content : undefined"
                    :description="notification.channel === 'sms' ? 'Text message sent' : 'E-mail sent'"
                />
            </div>
        </div>
    </div>
</template>

<script>
// Components
import Notification from "./Notification";

export default {
    props: {
        engineer: {
            type: Object,
            required: true
        }
    },
    components: {
        Notification
    },
    methods: {
        hideNotifications() {
            this.$emit("hidenotifications");
        }
    }
};
</script>