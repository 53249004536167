import Vue from "vue";

const state = {
  jobs: []
};

const mutations = {
  jobs(state, payload) {
    state.jobs = payload;
  },
  job(state, payload) {
    const index = state.jobs.findIndex(j => j.id === payload.id);
    if (index > -1) {
      state.jobs.splice(index, 1, payload);
    } else {
      state.jobs.unshift(payload);
    }
  },
  delete(state, id) {
    const index = state.jobs.findIndex(j => j.id === id);
    state.jobs.splice(index, 1);
  }
};

const actions = {
  async delete({ commit }, { id, cb }) {
    const api = await Vue.prototype.$api();
    await api.delete(`/jobs/${id}`);
    await cb();
    commit('delete', id);
  },
  async loadJob({ commit }, id) {
    const api = await Vue.prototype.$api();
    const { data } = await api.get(`/jobs/${id}`);
    commit('job', data);
  },
  async loadJobs({ commit }, { type, chargeScheme } = {}) {
    const api = await Vue.prototype.$api();
    const { data } = await api.get(`/jobs`, { params: { type, chargeScheme } });
    commit('jobs', data);
  },
  async postJob({ commit }, { job }) {
    const api = await Vue.prototype.$api();
    const { data } = await api.post('/jobs', job);
    commit('job', data);
    return data;
  },
  async postVisits({ commit }, { job, visits, engineers = [] }) {
    const api = await Vue.prototype.$api();
    const { data } = await api.post(`/jobs/${job.id}/visits`, visits);
    const updated = { ...job, ...data };
    for (const visit of updated.visits.filter(v => !v.engineer)) {
      visit.engineer = engineers.find(e => e.id === visit.engineerId);
    }
    commit('job', updated);
  }
};

const getters = {
  jobs(state) {
    return state.jobs;
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};