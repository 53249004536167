<template>
  <v-row
    no-gutters
    align="center"
    justify="center"
    style="height: 100vh"
  >
    <v-col
      align-self="center"
      md="10"
    >
      <progress-overlay 
        :isExecuting="saving" 
        @animationComplete="close"
      />

      <v-form
        ref="form"
        v-model="valid"
        class="pa-2"
        lazy-validation
      >
        <h3 class="heading-3">Review new assets</h3>
        <div class="subtitle-1">Contract {{ contract.reference }}</div>
        <div v-if="!!nextServiceDate" class="subtitle-2">Next service on {{ nextServiceDate }}</div>
        <div v-else class="subtitle-2">No service dates remaining</div>
        
        <v-data-table
          :headers="headers"
          :items="assets"
          :items-per-page="5"
          :loading="loading"
          no-data-text="There are no new assets to review for this contract."
          item-key="id"
        >
          <template v-slot:[`item.isApproved`]="{ item }">
            <v-switch
              v-model="item.isApproved"
              inset
              color="success"
              style="min-width: 140px;"
              :label="item.isApproved ? 'approved' : 'rejected'"
            ></v-switch>
          </template>
        </v-data-table>
      </v-form>
      <div class="d-flex justify-end">
        <v-btn
          elevation="0"
          class="mr-4"
          rounded
          @click="close"
        >
          Cancel
        </v-btn>
        <v-btn
          elevation="0"
          class="primary mr-4"
          rounded
          @click="updateAssets"
        >
          Done
        </v-btn>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import moment from "moment";

// Components
import ProgressOverlay from '@/components/overlays/ProgressOverlay';

export default {
  data() {
    return {
      assets: [],
      contractId: this.$route.params.id,
      contract: { reference: undefined },
      loading: false,
      valid: true,
      headers: [
        { text: 'Description', value: 'equipmentDescription' },
        { text: 'Asset tag', value: 'assetTag' },
        { text: 'Site', value: 'site.name' },
        { text: 'Location', value: 'position' },
        { text: 'Product code', value: 'product.code' },
        { text: 'Product description', value: 'product.description' },
        { text: 'Status', value: 'isApproved' },
      ],
      saving: false,
    };
  },
  components: {
    ProgressOverlay
  },
  computed: {
    isLoaded: function() {
      return !!this.contract.reference;
    },
    nextServiceDate: function() {
      let date = undefined;
      if (this.isLoaded && !!this.contract.nextServiceDate) {
        date = moment(this.contract.nextServiceDate.plannedServiceDate).format('Do of MMM YYYY');
      }
      return date;
    },
  },
  methods: {
    async loadAssets() {
      const api = await this.$api();
      const { data: assets } = await api.get(`/contracts/${this.contractId}/assets`);

      for (const asset of assets) {
        asset.isApproved = false;
      }

      this.assets = assets;
    },
    async loadContract() {
      const api = await this.$api();
      const { data: contract } = await api.get(`/contracts/${this.contractId}`, {
        params: { loadEquipment: false }
      });
      this.contract = contract;
    },
    close() {
      // named route
      this.$router.replace({ name: 'contract', params: { id: this.contractId } });
    },
    async updateAssets() {
      try {
        this.saving = true;
        const api = await this.$api();
        for (const asset of this.assets) {
          const operations = [
            { op: 'replace', path: '/status', value: asset.isApproved ? 'approved' : 'rejected' }
          ];
          await api.patch(`/assets/${asset.id}`, operations);
        }
      } finally {
        this.saving = false;
      }
    },
  },
  async mounted() {
    this.loading = true;
    try {
      await this.loadContract();
      await this.loadAssets();
    } finally {
      this.loading = false;
    }
  }
}
</script>