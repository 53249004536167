<template>
    <div
        class="notification-container"
        :class="{ 
            violet: status === 'onsite', 
            blue: status === 'travellinghome', 
            green: status === 'athome', 
            red: status === 'help' 
        }"
    >
        <div class="horizontal-container">
            <div class="icon white">
                <img v-if="status === 'onsite'" src="/assets/img/location-pin-black.svg" alt="">
                <img v-else-if="status === 'travellinghome'" src="/assets/img/right-arrow-circled-black.svg" alt="">
                <img v-else-if="status === 'athome'" src="/assets/img/home-black.svg" alt="">
                <img v-else-if="status === 'help'" src="/assets/img/exclamation-mark-circled-black.svg" alt="">
                <img v-else src="/assets/img/speech-bubble-white.svg" alt="">
            </div>
            <div class="notification-summary">
                <div class="notification-title">{{ createdTime }}</div>
                <div>{{ statusDescription || description }}</div>
            </div>
        </div>
        <div v-if="details" class="notification-details">
            <button class="icon tiny transparent" @click="toggleShowDetails">
                <img v-if="!isShowDetails" src="/assets/img/arrow-down-white.svg" alt="">
                <img v-else src="/assets/img/arrow-up-white.svg" alt="">
            </button>
            <transition
                name="expand"
            >
                <div v-if="isShowDetails" style="white-space: pre-line;">
                    {{ details }}
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
// External
import moment from "moment-timezone";

export default {
    props: {
        timezone: {
            type: String,
            required: true
        },
        createdOn: {
            type: String,
            required: true
        },
        description: {
            type: String,
            required: true
        },
        status: {
            type: String,
            required: false
        },
        details: {
            type: String,
            required: false
        }
    },
    data() {
        return {
            isShowDetails: false
        };
    },
    computed: {
        createdTime: function() {
            return moment.tz(this.createdOn, this.timezone).format("HH:mm");
        },
        statusDescription: function() {
            let statusDescription;
            switch(this.status) {
                case "onsite":
                    statusDescription = "On site";
                    break;
                case "travellinghome":
                    statusDescription = "Travelling home";
                    break;
                case "athome":
                    statusDescription = "At home";
                    break;
                case "help":
                    statusDescription = "Requires assistance";
                    break;
            }
            return statusDescription;
        }
    },
    methods: {
        toggleShowDetails() {
            this.isShowDetails = !this.isShowDetails;
        }
    }
};
</script>

<style scoped>
.expand-enter-active,
.expand-leave-active {
  transition: all 0.75s;
  overflow: hidden;
  max-height: 1000px;
}

.expand-enter,
.expand-leave-to {
  max-height: 0;
}
</style>