<template>
  <v-dialog
    v-model="show"
    fullscreen
    hide-overlay
    persistent
    transition="dialog-bottom-transition"
    content-class="white pa-8 overflow-hidden"
    style="max-height: 100vh"
  >
    <div class="d-flex flex-column">
      <v-toolbar
        flat
        class="flex-grow-0 pb-5"
      >
        <v-toolbar-title>
          <div v-if="subheading" class="text-subtitle-2 text-secondary font-weight-medium">
            {{ subheading }}
          </div>
          <div v-if="heading" class="text-h5 font-weight-bold">
            {{ heading }}
          </div>
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn
          icon
          @click="close"
        >
          <v-icon>mdi-window-close</v-icon>
        </v-btn>
      </v-toolbar>
      <div class="flex-grow-1 pa-2 pt-5">
        <slot></slot>
      </div>
    </div>

  </v-dialog>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true
    },
    heading: {
      type: String,
      default: ''
    },
    subheading: {
      type: String,
      default: ''
    }
  },
  methods: {
    close() {
      this.$emit('close');
    }
  }
}
</script>