// External packages
import { uniqWith, sortBy } from "lodash";

import assetStatus from "./assets/asset-status";
import taskStatus from "./task-status";

export const assetsMixin = {
    data() {
        return {
            filters: {
                assets: {
                    status: undefined,
                    text: "",
                    site: undefined,
                    taskStatus: undefined
                }
            }
        };
    },
    methods: {
        getLastTask(asset) {
            return asset.tasks !== undefined && asset.tasks.length > 0 ? asset.tasks[0] : undefined;
        },
        getLastExecutedTask(asset) {
            return asset.tasks !== undefined && asset.tasks.length > 0 ? asset.tasks.find(t => t.statusId !== taskStatus.new) : undefined;
        },
        getAssetsWithStatus(status, assets) {
            let assetsWithStatus = [];
            for (const asset of assets) {
                const lastTask = this.getLastExecutedTask(asset) || { equipmentStatus: asset.equipmentStatus || assetStatus.none };
                if (lastTask.equipmentStatus === status) {
                    assetsWithStatus.push(asset);
                }
            }
            return assetsWithStatus;
        },
        getAssetsAwaitingApproval(assets) {
            return assets.filter(a => a.equipmentStatus === assetStatus.awaitingApproval);
        },
        getAssetsOnline(assets) {
            return this.getAssetsWithStatus(assetStatus.online, assets);
        },
        getAssetsOffline(assets) {
            return this.getAssetsWithStatus(assetStatus.offline, assets);
        },
        getAssetsOnlineEmergency(assets) {
            return this.getAssetsWithStatus(assetStatus.onlineEmergency, assets);
        },
        getAssetsUnknown(assets) {
            return this.getAssetsWithStatus(assetStatus.none, assets);
        },
        getAssetsWithTaskStatus(status, assets) {
            let assetsWithTaskStatus = [];
            for (const asset of assets) {
                const lastTask = this.getLastExecutedTask(asset);
                if (lastTask && lastTask.statusId === status) {
                    assetsWithTaskStatus.push(lastTask);
                }
            }
            return assetsWithTaskStatus;
        },
        getAssetsVisitScheduled(assets) {
            return this.getAssetsWithTaskStatus(taskStatus.new, assets);
        },
        getAssetsRiskAssessmentFailed(assets) {
            return this.getAssetsWithTaskStatus(taskStatus.riskAssessmentFailed, assets);
        },
        getAssetsQuoteRequired(assets) {
            return this.getAssetsWithTaskStatus(taskStatus.quoteRequired, assets);
        },
        getSites(assets) {
            let sites = assets !== undefined ? assets.map(a => ({ ...a.site, name: a.site.name || a.site.address.line1 })) : [];
            sites = uniqWith(sortBy(sites, [ "name" ]), (s1, s2) => s1.name === s2.name);
            return sites;
        }
    }
};