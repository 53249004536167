import Vue from "vue";
import Vuex from "vuex";

import contract from "./modules/contract";
import jobs from "./modules/jobs";
import modal from "./modules/modal";
import navbar from "./modules/navbar";
import prefs from "./modules/preferences";
import notifications from "./modules/notifications";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: { 
    contract,
    jobs,
    modal, 
    navbar,
    notifications,
    prefs
  }
});