<template>
  <v-dialog
    v-model="show"
    fullscreen
    hide-overlay
    persistent
    transition="dialog-bottom-transition"
  >
    <slot v-bind="{ close }"></slot>
  </v-dialog>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    close() {
      this.$emit('close');
    }
  }
}
</script>