<template>
    <div class="modal small">
      <div class="modal-heading">
        <a 
            href="#" 
            class="round-button w-inline-block transparent"
            @click="closeModal"
        >
            <img src="/assets/img/close.svg" alt="">
        </a>
        <div>Confirm action</div>
        <!-- Empty div to ensure the title is still in the center of the heading -->
        <div></div>
      </div>
      <div class="modal-body confirm-action">
        <h4>Delete this scheduled notification?</h4>
        <div>You cannot undo this action later.</div>
      </div>
      <div class="modal-footer justify-right">
        <a class="button form-button w-button grey lighten-2" @click="closeModal">Cancel</a>
        <tick-button
          class="form-button red"
          defaultLabel="Delete"
          executingLabel="Deleting"
          executedLabel="Deleted"
          :func="deleteNotification"
          @animationComplete="closeModal"
        />
      </div>
    </div>
</template>

<script>
// External packages
import { mapActions, mapGetters } from "vuex";

// Components
import TickButton from "../buttons/TickButton";

export default {
    components: {
      TickButton
    },
    computed: {
      ...mapGetters({
        "selectedNotification": "notifications/selectedNotification"
      })
    },
    methods: {
        ...mapActions({
            "hideModal": "modal/hideModal",
            "deleteSelectedNotification": "notifications/deleteSelectedNotification"
        }),
        async deleteNotification() {
          await this.deleteSelectedNotification();
        },
        closeModal() {
          this.hideModal();
        }
    }
};
</script>