<template>
    <shrinkable-filter
        :headingStat="assetsToAction.length"
        :heading="$tc('filter_taskstatus_heading', assetsToAction.length)"
    >
        <template v-slot:body>
            <a 
                href="#"
                class="field chip custom"
                :class="[ isRiskAssessmentFailed ? [ 'bg-grey-3' ] : [ 'bg-transparent' ] ]"
                @click.prevent="toggleRiskAssessmentFailed"
            >
                <div class="horizontal-flex-container">
                    <img src="/assets/img/forbidden-black.svg" alt="" class="field-icon">
                    <div class="field-value text-highlight">
                        <strong>{{ assetsRiskAssessmentFailed.length }}</strong> {{ $t("filter_taskstatus_riskassessmentfailed") }}
                    </div>
                </div>
                <img v-if="isRiskAssessmentFailed" src="/assets/img/x-black.svg" class="chip-icon" alt="">
            </a>
            <a 
                href="#"
                class="field chip custom"
                :class="[ isQuoteRequiredSelected ? [ 'bg-grey-3' ] : [ 'bg-transparent' ] ]"
                @click.prevent="toggleQuoteRequired"
            >
                <div class="horizontal-flex-container">
                    <img src="/assets/img/file-black.svg" alt="" class="field-icon">
                    <div class="field-value text-highlight">
                        <strong>{{ assetsQuoteRequired.length }}</strong> {{ $t("filter_taskstatus_quoterequired") }}
                    </div>
                </div>
                <img v-if="isQuoteRequiredSelected" src="/assets/img/x-black.svg" class="chip-icon" alt="">
            </a>
        </template>
    </shrinkable-filter>
</template>

<script>
// External packages
import taskStatus from "../task-status";

// Mixins
import { assetsMixin } from "../assets";

// Components
import ShrinkableFilter from "./ShrinkableFilter";

export default {
    mixins: [ assetsMixin ],
    props: {
        assets: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            selected: undefined
        };
    },
    components: {
        ShrinkableFilter
    },
    computed: {
        assetsVisitScheduled: function() {
            return this.getAssetsVisitScheduled(this.assets);
        },
        assetsRiskAssessmentFailed: function() {
            return this.getAssetsRiskAssessmentFailed(this.assets);
        },
        assetsQuoteRequired: function() {
            return this.getAssetsQuoteRequired(this.assets);
        },
        assetsToAction: function() {
            return [ 
                ...this.assetsVisitScheduled, 
                ...this.assetsRiskAssessmentFailed, 
                ...this.assetsQuoteRequired
            ];
        },
        isVisitScheduledSelected: function() {
            return this.selected === taskStatus.new;
        },
        isRiskAssessmentFailed: function() {
            return this.selected === taskStatus.riskAssessmentFailed;
        },
        isQuoteRequiredSelected: function() {
            return this.selected === taskStatus.quoteRequired;
        }
    },
    methods: {
        toggleTaskStatus(status) {
            if (this.selected !== status) {
                this.selected = status;
            } else {
                this.selected = undefined;
            }
            this.$emit("changed", this.selected);
        },
        toggleVisitScheduled() {
            this.toggleTaskStatus(taskStatus.new);
        },
        toggleRiskAssessmentFailed() {
            this.toggleTaskStatus(taskStatus.riskAssessmentFailed);
        },
        toggleQuoteRequired() {
            this.toggleTaskStatus(taskStatus.quoteRequired);
        }
    }
};
</script>