<template>
    <div class="content-container navbar-offset">
        <div class="content">
            <div class="content-section">
                <div class="content-section-container">
                    <div class="w-form">
                        <form id="engineer-form" name="engineer-form">
                            <div class="form-section">
                                <div class="field-groups">
                                    <div class="field-group">
                                        <h4 class="field-group-heading">Basic Details</h4>
                                        <div class="form-field">
                                            <div class="form-field-label">Alerts</div>
                                            <input
                                                type="checkbox"
                                                class="switch w-input"
                                                id="alerts"
                                                v-model="isReceiveAlerts"
                                            >
                                            <label class="switch" for="alerts"></label>
                                        </div>
                                        <div class="form-field">
                                          <div class="form-field-label">Rating</div>
                                          <v-rating
                                            hover
                                            length="5"
                                            size="36"
                                            v-model="writingRating"
                                          ></v-rating>
                                        </div>
                                        <div class="form-field">
                                            <div class="form-field-label">Region</div>
                                            <select
                                                id="region"
                                                name="region"
                                                class="select-field w-select"
                                                v-model="regionId"
                                            >
                                                <option disabled value="0">Select one...</option>
                                                <option value="1">Region 1</option>
                                                <option value="2">Region 2</option>
                                                <option value="3">Region 3</option>
                                                <option value="4">Region 4</option>
                                                <option value="6">Water Utilities</option>
                                                <option value="7">Sub-contractors</option>
                                            </select>
                                        </div>
                                        <div class="form-field">
                                            <div class="form-field-label">Fullname</div>
                                            <input 
                                                type="text" 
                                                class="text-field w-input" 
                                                name="fullname" 
                                                placeholder="The engineer's first name and surname" 
                                                id="fullname" 
                                                required=""
                                                v-model="fullname"
                                            >
                                        </div>
                                        <div class="form-field">
                                            <div class="form-field-label">E-mail</div>
                                            <input 
                                                type="email" 
                                                class="text-field w-input" 
                                                name="email" 
                                                placeholder="The engineer's e-mail address..." 
                                                id="email" 
                                                required=""
                                                v-model="email"
                                            >
                                        </div>
                                        <div class="form-field">
                                            <div class="form-field-label">Mobile</div>
                                            <input 
                                                type="text" 
                                                class="text-field w-input" 
                                                name="mobile" 
                                                placeholder="The engineer's mobile number..." 
                                                id="mobile" 
                                                required=""
                                                v-model="mobile"
                                            >
                                        </div>
                                    </div>
                                    <!-- <div class="field-group">
                                        <h4 class="field-group-heading">Address</h4>
                                        <div class="form-field">
                                            <div class="form-field-label">Line 1</div>
                                            <input 
                                                type="text" 
                                                class="text-field w-input" 
                                                name="line1" 
                                                placeholder="Line 1 of the address" 
                                                id="line1" 
                                                required=""
                                                v-model="address.line1">
                                        </div>
                                        <div class="form-field">
                                            <div class="form-field-label">Line 2</div>
                                            <input 
                                                type="text" 
                                                class="text-field w-input" 
                                                name="line2" 
                                                placeholder="Line 2 of the address" 
                                                id="line2" 
                                                required=""
                                                v-model="address.line2">
                                        </div>
                                        <div class="form-field">
                                            <div class="form-field-label">City</div>
                                            <input 
                                                type="text" 
                                                class="text-field w-input" 
                                                name="city" 
                                                placeholder="City" 
                                                id="city" 
                                                required=""
                                                v-model="address.city">
                                        </div>
                                        <div class="form-field">
                                            <div class="form-field-label">County</div>
                                            <input 
                                                type="text" 
                                                class="text-field w-input" 
                                                name="county" 
                                                placeholder="County" 
                                                id="county" 
                                                required=""
                                                v-model="address.county">
                                        </div>
                                        <div class="form-field">
                                            <div class="form-field-label">Postcode</div>
                                            <input 
                                                type="text" 
                                                class="text-field w-input" 
                                                name="postcode" 
                                                placeholder="Postcode" 
                                                id="postcode" 
                                                required=""
                                                v-model="address.postcode">
                                        </div>
                                    </div> -->
                                </div>
                            </div>

                            <div class="form-buttons">
                                <a 
                                    :href="`/engineer/${engineerId}`"
                                    class="button form-button w-button grey lighten-2"
                                >
                                    Cancel
                                </a>
                                <tick-button class="form-button" :func="saveEdit" />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// External packages
import { mapActions, mapGetters } from "vuex";

// Components
import TickButton from "../../components/buttons/TickButton";
import { RouterlinkMenuItem } from "../../navbar";

export default {
    data() {
        return {
            engineerId: this.$route.params.id,
            engineer: undefined,
            isReceiveAlerts: false,
            writingRating: 0,
            address: {
                line1: "",
                line2: "",
                city: "",
                county: "",
                country: "",
                postcode: ""
            },
            firstname: "",
            surname: "",
            email: "",
            mobile: "",
            regionId: 0
        };
    },
    components: {
        TickButton
    },
    watch: {
        engineers: function() {
            this.setEngineer();
        },
    },
    computed: {
        ...mapGetters({
            "engineers": "prefs/engineers/engineers"
        }),
        fullname: {
            get() {
                return `${this.firstname} ${this.surname}`.trim();
            },
            set(value) {
                const names = value.split(" ");
                this.firstname = names[0];
                this.surname = names[1];
            }
        }
    },
    methods: {
        ...mapActions({
            "setMenuItems": "navbar/setMenuItems",
            "loadEngineer": "prefs/engineers/loadEngineer",
            "patchEngineer": "prefs/engineers/patchEngineer"
        }),
        async setEngineer() {
            this.engineer = this.engineers[this.engineerId];
            if (this.engineer) {
                this.firstname = this.engineer.firstname;
                this.surname = this.engineer.surname;
                this.email = this.engineer.email;
                this.mobile = this.engineer.mobile;
                this.regionId = this.engineer.regionId;
                this.isReceiveAlerts = this.engineer.isReceiveAlerts;
                this.writingRating = this.engineer.writingRating;
                this.address = { ...this.engineer.address };
            } else {
                await this.loadEngineer(this.engineerId);
            }
        },
        loadMenuItems() {
            const menuItems = [
                new RouterlinkMenuItem("/assets/img/noun_Left_3145913-1.svg", `/engineer/${this.engineerId}`)
            ];
            this.setMenuItems(menuItems);
        },
        async saveEdit() {
            const data = {
              ...this.$data
            };
            delete data.engineerId;
            delete data.engineer;

            const {
              isReceiveAlerts,
              writingRating,
              address: {
                  line1,
                  line2,
                  city,
                  county,
                  postcode
              },
              firstname,
              surname,
              email,
              mobile,
              regionId
            } = data;

            const operations = [
              { "op": "replace", "path": "/firstname", "value": firstname },
              { "op": "replace", "path": "/surname", "value": surname },
              { "op": "replace", "path": "/email", "value": email },
              { "op": "replace", "path": "/mobile", "value": mobile },
              { "op": "replace", "path": "/regionId", "value": regionId },
              { "op": "replace", "path": "/isReceiveAlerts", "value": isReceiveAlerts },
              { "op": "replace", "path": "/writingRating", "value": writingRating },
              { "op": "replace", "path": "/address/line1", "value": line1 },
              { "op": "replace", "path": "/address/line2", "value": line2 },
              { "op": "replace", "path": "/address/city", "value": city },
              { "op": "replace", "path": "/address/county", "value": county },
              { "op": "replace", "path": "/address/postcode", "value": postcode }
            ];
            await this.patchEngineer({ id: this.engineerId, data, operations });
        }
    },
    mounted() {
        this.loadMenuItems();
        this.setEngineer();
    }
};
</script>