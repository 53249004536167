<template>
  <v-stepper-content
    :step="step"
    style="height: 100%"
  >
    <div
      class="d-flex flex-column"
      style="height: 100%"
    >
      <v-toolbar
        flat
        class="mb-5"
        style="flex-grow: 0 !important"
      >
        <v-btn
          v-if="step > 1"
          icon
          class="hidden-xs-only"
          @click="previous"
        >
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>

        <v-toolbar-title>
          <div v-if="showProgress" class="text-subtitle-2 text-secondary font-weight-medium">
            Question {{ step }}/{{ steps }}
          </div>
          <div class="text-h5 font-weight-bold">
            {{ heading }}
          </div>
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn
          v-if="close"
          icon
          class="hidden-xs-only"
          @click="close"
        >
          <v-icon>mdi-window-close</v-icon>
        </v-btn>
      </v-toolbar>

      <div
        class="step-body flex-grow-1 ml-4 mr-4"
      >
        <slot></slot>
      </div>

      <div class="d-flex justify-center align-center step-footer">
        <div class="buttons step-buttons">
          <v-btn
            large
            elevation="0"
            color="primary"
            class="step-button"
            v-if="step !== steps"
            :disabled="!isValid"
            @click="next"
          >
            Continue
          </v-btn>
          <v-btn
            large
            elevation="0"
            color="primary"
            class="step-button"
            :disabled="!isValid"
            v-else-if="finish !== undefined"
            @click="finish"
          >
            Finish
          </v-btn>
        </div>
      </div>
    </div>
  </v-stepper-content>
</template>

<script>
export default {
  props: {
    close: {
      type: Function,
      default: undefined
    },
    step: {
      type: Number,
      required: true
    },
    steps: {
      type: Number,
      required: true
    },
    heading: {
      type: String,
      required: true
    },
    next: {
      type: Function,
      default: undefined
    },
    previous: {
      type: Function,
      default: undefined
    },
    finish: {
      type: Function,
      default: undefined
    },
    isValid: {
      type: Boolean,
      default: true
    },
    showProgress: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style scoped>
.step-body {
  overflow: auto;
  height: 0px;
}

.step-footer {
  height: 100px;
}

.step-buttons {
  width: 200px;
}

.step-button {
  width: 200px;
}
</style>