<template>
    <div>
        <transition
            enter-active-class="animated fadeIn faster"
            leave-active-class="animated fadeOut faster"
            mode="out-in"
        >
            <div
                v-if="!isLoaded"
                key="skeleton"
                class="content-section-heading">
                <v-skeleton-loader
                    type="text"
                    style="min-width: 200px;"
                ></v-skeleton-loader>
            </div>
            <div
                v-else
                key="content"
                class="content-section-heading"
            >
                <div class="strong">{{ text }}</div>
                <div class="content-section-options">
                    <slot></slot>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    props: {
        isLoaded: {
            type: Boolean,
            required: true
        },
        text: {
            type: String,
            required: true
        }
    }
};
</script>