import assetStatus from "./asset-status";

export const taskMixin = {
    data() {
        return {
            equipmentStatus: undefined,
            equipmentStatusLabel: undefined
        };
    },
    methods: {
        setEquipmentStatus(task, defaultStatus) {
            let status = defaultStatus === assetStatus.awaitingApproval ? defaultStatus : assetStatus.none;
            if (task !== undefined) {
                status = task.equipmentStatus;
            }
            this.equipmentStatus = status;
        },
        setEquipmentStatusLabel() {
            let label = "Unknown";
            switch(this.equipmentStatus) {
                case assetStatus.awaitingApproval:
                    label = "Awaiting approval";
                    break;
                case assetStatus.none:
                    label = "Unknown";
                    break;
                case assetStatus.online:
                    label = "Online";
                    break;
                case assetStatus.offline:
                    label = "Offline";
                    break;
                case assetStatus.onlineEmergency:
                    label = "Online (Emergency)";
                    break;
            }
            this.equipmentStatusLabel = label;
        },
        async openSheetAttachment(attachment) {
            const api = await this.$api();
            const { data } = await api.get(`/attachments/${attachment.id}`);
            window.open(data.sasUrl, "_blank");
        }
    }
};