<template>
    <div class="vertical-flex-container custom">
        <div class="card-body bg-black asset grow custom">
            <transition
                enter-active-class="animated fadeIn faster"
                leave-active-class="animated fadeOut faster"
                mode="out-in"
            >
                <div v-if="!isLoaded" key="assetTasksSkeletons" class="rows-container shrink">
                    <asset-task-skeleton />
                    <asset-task-skeleton style="opacity: 0.6;" />
                    <asset-task-skeleton style="opacity: 0.2;" />
                </div>
                <div v-else key="assetTasks" class="rows-container shrink asset-tasks">
                    <asset-task
                        v-for="task in tasks"
                        :key="task.id"
                        :task="task"
                    />
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
// Components
import AssetTask from "./AssetTask";
import AssetTaskSkeleton from "./AssetTaskSkeleton";

export default {
    props: {
        asset: {
            type: Object,
            required: true
        },
        isLoaded: {
            type: Boolean,
            default: false
        }
    },
    components: {
        AssetTask,
        AssetTaskSkeleton
    },
    computed: {
      tasks() {
        const tasks = this.asset ? this.asset.tasks.filter((t) => t.visit !== undefined) : [];
        console.log(tasks);
        return tasks.sort(({ visit: { probableEndDateTime: a } }, { visit: { probableEndDateTime: b } }) => a > b ? -1 : 1);
      },
    },
};
</script>

<style scoped>
.vertical-flex-container.custom {
    height: 100%;
}

.card-body.custom {
    overflow: auto;
}

.asset-tasks {
    margin-bottom: 100px;
}
</style>