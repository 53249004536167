<template>
    <div class="row my-1">
        <v-skeleton-loader class="row-icon" width="40px" type="button"></v-skeleton-loader>
        <v-skeleton-loader class="row-avatar-container" type="avatar" style="border: none;"></v-skeleton-loader>
        <div class="row-heading mobile-width-80"><v-skeleton-loader width="60px" type="text"></v-skeleton-loader></div>
        <div class="row-fields">
            <div class="row-field shrink">
                <div class="row-field-label"><v-skeleton-loader type="text"></v-skeleton-loader></div>
                <div class="icon grey"></div>
            </div>
            <div class="row-field shrink">
                <div class="row-field-label"><v-skeleton-loader type="text"></v-skeleton-loader></div>
                <div class="icon grey"></div>
            </div>
            <div class="row-field">
                <div class="row-field-label"><v-skeleton-loader width="100px" type="text"></v-skeleton-loader></div>
                <div class="row-field-value"><v-skeleton-loader width="350px" type="text"></v-skeleton-loader></div>
            </div>
            <div class="row-more-icon">
                <div class="icon transparent w-inline-block" style="min-width: 50px;"><v-skeleton-loader type="button"></v-skeleton-loader></div>
            </div>
        </div>
    </div>
</template>

<script>
// Components
import { VSkeletonLoader } from "vuetify/lib/components/VSkeletonLoader";

export default {
    components: {
        VSkeletonLoader
    }
}
</script>