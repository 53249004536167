<template>
    <div>
        <div v-if="isIdleInTheFuture">
            Available {{ start.calendar(now).toLowerCase() }} for {{ humanizedDuration }}.
        </div>
        <div v-else-if="isIdleShortly">
            Available <span class="strong">{{ start.fromNow() }}</span> for <span class="strong">{{ humanizedDuration }}</span>.
        </div>
        <div v-else-if="isIdleNow">
            Available <span class="strong">now</span> for <span class="strong">{{ humanizedDuration }}</span>.
        </div>
    </div>
</template>

<script>

import humanizeDuration from "humanize-duration";
import moment from "moment-timezone";

export default {
    props: {
        timezone: {
            type: String,
            required: true
        },
        start: {
            type: Object,
            required: true
        },
        end: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            now: moment.tz(this.timezone)
        };
    },
    computed: {
        actualStart() {
            let start = this.start;
            if (this.now.isSameOrAfter(start))
                start = this.now;
            return start;
        },
        duration() {
            return this.end.diff(this.actualStart, "milliseconds");
        },
        humanizedDuration() {
            return humanizeDuration(this.duration, { units: [ "h", "m" ], round: true });
        },
        isIdleNow() {
            return this.now.isSameOrAfter(this.start) && this.now.isBefore(this.end);
        },
        isIdleShortly() {
            return this.now.isBefore(this.start);
        },
        isIdleInTheFuture() {
            return this.start.isSameOrAfter(this.tomorrow);
        },
        tomorrow() {
            return this.now.clone().add(1, "days").startOf("day");
        }
    },
    mounted() {
        setInterval(() => this.now = moment.tz(this.timezone), 60000);
    }
};
</script>