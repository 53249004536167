<template>
    <div class="modal">
      <div class="modal-heading">
        <a 
            href="#" 
            class="round-button w-inline-block transparent"
            @click="closeModal"
        >
            <img src="/assets/img/close.svg" alt="">
        </a>
        <div>Create scheduled notification</div>
        <!-- Empty div to ensure the title is still in the center of the heading -->
        <div></div>
      </div>
      <div class="modal-body">
        <div class="w-form">
          <form>
            <div class="field-group"></div>
            <div class="field-group">
              <div class="form-field">
                <div class="form-field-label">Time of notification</div>
                  <v-time-picker 
                      class="card"
                      v-model="time"
                      format="24hr"
                      :full-width="true"
                  ></v-time-picker>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="modal-footer justify-right">
        <a class="button form-button w-button grey lighten-2" @click="closeModal">Cancel</a>
        <tick-button 
          class="form-button"
          defaultLabel="Create"
          executingLabel="Creating"
          executedLabel="Created"
          :func="saveChanges"
          @animationComplete="closeModal"
        />
      </div>
    </div>
</template>

<script>
// External packages
import { mapActions, mapGetters } from "vuex";

// Components
import TickButton from "../buttons/TickButton";
import { VTimePicker } from "vuetify/lib/components/VTimePicker";

export default {
    data() {
        return {
            time: undefined
        };
    },
    components: {
      TickButton,
      VTimePicker
    },
    computed: {
      ...mapGetters({
        "newNotification": "notifications/newNotification"
      })
    },
    methods: {
        ...mapActions({
            "hideModal": "modal/hideModal",
            "postNewNotification": "notifications/postNewNotification"
        }),
        async saveChanges() {
          const [ hour, minute ] = this.time.split(":");
          this.newNotification.hour = Number.parseInt(hour);
          this.newNotification.minute = Number.parseInt(minute);
          await this.postNewNotification();
        },
        closeModal() {
          this.hideModal();
        }
    },
    mounted() {
      this.time = `${this.newNotification.hour}:${this.newNotification.minute}`;
    }
};
</script>