<template>
    <div class="row my-1">
        <img src="/assets/img/clock.svg" alt="" class="row-icon small">
        <div class="row-heading mobile-width-80">{{ hourFormatted }}:{{ minuteFormatted }}</div>
        <div class="row-fields">
            <div class="row-field shrink">
                <div class="row-field-label">E-mail</div>
                <div class="icon" :class="{ green: isEmail, grey: !isEmail }">
                    <img v-if="isEmail" src="/assets/img/tick-white.svg" alt="">
                    <img v-else src="/assets/img/tick-white.svg" alt="">
                </div>
            </div>
            <div class="row-field shrink">
                <div class="row-field-label">SMS</div>
                <div class="icon" :class="{ green: isSms, grey: !isSms }">
                    <img v-if="isSms" src="/assets/img/tick-white.svg" alt="">
                    <img v-else src="/assets/img/tick-white.svg" alt="">
                </div>
            </div>
            <div class="row-more-icon">
                <button 
                    class="icon transparent w-inline-block"
                    @click="deleteNotification"
                >
                    <img src="/assets/img/bin-black.svg" alt="">
                </button>
                <button 
                    class="icon transparent w-inline-block"
                    @click="editNotification"
                >
                    <img src="/assets/img/edit-black.svg" alt="">
                </button>
            </div>
        </div>
    </div>
</template>

<script>
// External packages
import { mapActions } from "vuex";

export default {
    props: {
        id: {
            type: String,
            required: true
        },
        hour: {
            type: Number,
            required: true
        },
        minute: {
            type: Number,
            required: true
        },
        isEmail: {
            type: Boolean,
            default: false
        },
        isSms: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        hourFormatted() {
            return ("0" + this.hour).slice(-2);
        },
        minuteFormatted() {
            return ("0" + this.minute).slice(-2);
        }
    },
    methods: {
        ...mapActions({
            "showModal": "modal/showModal",
            "selectNotificationById": "notifications/selectNotificationById"
        }),
        editNotification() {
            this.selectNotificationById(this.id);
            this.showModal("ScheduledNotificationEdit");
        },
        deleteNotification() {
            this.selectNotificationById(this.id);
            this.showModal("ScheduledNotificationDelete");
        }
    }
};
</script>