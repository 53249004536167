<template>
    <div class="modal">
      <div class="modal-heading">
        <a 
            href="#" 
            class="round-button w-inline-block transparent"
            @click="closeModal"
        >
            <img src="/assets/img/close.svg" alt="">
        </a>
        <div>Edit scheduled notification</div>
        <!-- Empty div to ensure the title is still in the center of the heading -->
        <div></div>
      </div>
      <div class="modal-body">
        <div class="w-form">
          <form>
            <div class="field-group"></div>
            <div class="field-group">
              <div class="form-field">
                <div class="form-field-label">Time of notification</div>
                  <v-time-picker 
                      class="card"
                      v-model="time"
                      format="24hr"
                      :full-width="true"
                  ></v-time-picker>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="modal-footer justify-right">
        <a class="button form-button w-button grey lighten-2" @click="closeModal">Cancel</a>
        <tick-button class="form-button" :func="saveChanges" />
      </div>
    </div>
</template>

<script>
// External packages
import { mapActions, mapGetters } from "vuex";

// Components
import TickButton from "../buttons/TickButton";
import { VTimePicker } from "vuetify/lib/components/VTimePicker";

export default {
    data() {
        return {
            time: undefined
        };
    },
    components: {
      TickButton,
      VTimePicker
    },
    computed: {
      ...mapGetters({
        "selectedNotification": "notifications/selectedNotification"
      })
    },
    methods: {
        ...mapActions({
            "hideModal": "modal/hideModal",
            "deselectNotification": "notifications/deselectNotification",
            "patchSelectedNotification": "notifications/patchSelectedNotification"
        }),
        async saveChanges() {
          const [ hour, minute ] = this.time.split(":");
          await this.patchSelectedNotification({ hour, minute });
        },
        closeModal() {
          this.deselectNotification();
          this.hideModal();
        }
    },
    mounted() {
      this.time = `${this.selectedNotification.hour}:${this.selectedNotification.minute}`;
    }
};
</script>