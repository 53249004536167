const state = {
    isDefault: false,
    defaultMenuItems: [],
    menuItems: []
};

const mutations = {
    defaultMenuItems(state, payload) {
        state.defaultMenuItems = payload;
    },
    isDefault(state, payload) {
        state.isDefault = payload;
    },
    menuItems(state, payload) {
        state.menuItems = payload;
    }
};

const actions = {
    resetMenuItems({ commit, getters }) {
        commit("menuItems", getters.defaultMenuItems);
        commit("isDefault", true);
    },
    setDefaultMenuItems({ commit }, items) {
        commit("defaultMenuItems", items);
        commit("menuItems", items);
        commit("isDefault", true);
    },
    setMenuItems({ commit }, items) {
        commit("menuItems", items);
        commit("isDefault", false);
    }
};

const getters = {
    defaultMenuItems(state) {
        return state.defaultMenuItems;
    },
    isDefault(state) {
        return state.isDefault;
    },
    menuItems(state) {
        return state.menuItems;
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};