import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#1F233D',
        secondary: '#2b2b2b',
        accent: '#1F233D', // #CCE7FF
        info: '#266FAF',
        error: '#E54340',
        success: '#9AB212',
        warning: '#E87E23',
        grundfos: '#152753',
        'primary-text': '#2B2B2B'
      }
    }
  }
});
