<template>
    <div class="row thin my-1">
        <div class="row-fields thin">
            <div class="row-field shrink">
                <div class="row-field-label">Date</div>
                <div class="text-smaller custom-date">{{ date }}</div>
            </div>
            <div class="row-field">
                <div class="row-field-label">Status</div>
                <div
                    class="text-smaller"
                    :class="[ equipmentStatus === 0 ? [ 'text-primary-3', 'text-bolder' ] : [],
                              equipmentStatus === 2 ? [ 'text-primary-5', 'text-bolder' ] : [],
                              equipmentStatus === 3 ? [ 'text-primary-6', 'text-bolder' ] : [] ]"
                >
                    {{ equipmentStatusLabel }}
                </div>
            </div>
        </div>
        <div class="row-fields thin">
            <div class="row-field">
                <div class="row-field-label">Engineer comments</div>
                <div class="text-smaller">{{ task.engineerNotes ? task.engineerNotes : "--" }}</div>
            </div>
        </div>
        
        <div class="row-more-icon horizontal-flex-container custom">
            <a 
                v-if="reportAttachment !== undefined" 
                :href="reportAttachment.url" 
                class="icon-button smaller bg-transparent w-inline-block" 
                target="_blank"
            >
                <img src="/assets/img/download-black.svg" alt="">
            </a>
            <div v-else class="icon-button smaller bg-transparent w-inline-block">
                <img src="/assets/img/download-grey.svg" alt="" />
            </div>

            <a 
                v-if="sheetAttachment !== undefined"
                href="#"
                class="icon-button smaller bg-transparent w-inline-block"
                target="_blank"
                @click.prevent="openSheetAttachment(sheetAttachment)"
            >
                <img src="/assets/img/setting-black.svg" alt="">
            </a>
            <div v-else class="icon-button smaller bg-transparent w-inline-block">
                <img src="/assets/img/setting-grey.svg" alt="" />
            </div>
        </div>
    </div>
</template>

<script>
// External packages
import moment from "moment-timezone";

// Mixins
import { taskMixin } from "./task";

export default {
    mixins: [taskMixin],
    props: {
        task: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            date: moment(this.task.visit.probableEndDateTime).format("Do MMM YYYY"),
            reportAttachment: this.task.visit.attachments.find(a => a.filename.toLowerCase().includes("report")),
            sheetAttachment: this.task.attachments[0]
        };
    },
    mounted() {
        this.setEquipmentStatus(this.task);
        this.setEquipmentStatusLabel();
    }
};
</script>

<style scoped>
.text-smaller.custom-date {
    min-width: 90px;
}
.row-more-icon.custom {
    top: 10px;
}
</style>