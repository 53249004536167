<template>
    <div class="content-section framed bg-gradient-2 grow vertical-flex-container">
        <div class="numeric-statistic grow mb30">
            <div class="text-giant text-highlight">{{ assets.length }}</div>
            <div class="text-large">Assets</div>
        </div>
        <a 
            href="#"
            class="field chip custom"
            @click.prevent="toggleAssetsAwaitingApproval"
            :class="[ isShowAssetsAwaitingApproval ? [ 'bg-primary-2', 'light' ] : [ 'bg-transparent' ] ]"
        >
            <div class="horizontal-flex-container">
                <img v-if="!isShowAssetsAwaitingApproval" src="/assets/img/avatar-blue-light.svg" alt="" class="field-icon">
                <img v-else src="/assets/img/avatar-blue.svg" alt="" class="field-icon">
                <div class="field-value text-highlight text-primary-2" :class="{ 'light': !isShowAssetsAwaitingApproval }">
                    <strong>{{ assetsAwaitingApproval }}</strong> assets awaiting approval
                </div>
            </div>
            <img v-if="isShowAssetsAwaitingApproval" src="/assets/img/x-blue.svg" class="chip-icon" alt="">
        </a>
        <a 
            href="#"
            class="field chip custom"
            @click.prevent="toggleAssetsOnline"
            :class="[ isShowAssetsOnline ? [ 'bg-primary-4', 'light' ] : [ 'bg-transparent' ] ]"
        >
            <div class="horizontal-flex-container">
                <img src="/assets/img/check-green.svg" alt="" class="field-icon">
                <div class="field-value text-highlight text-primary-4 bright">
                    <strong>{{ assetsOnline }}</strong> assets online
                </div>
            </div>
            <img v-if="isShowAssetsOnline" src="/assets/img/x-green-bright.svg" class="chip-icon" alt="">
        </a>
        <a 
            href="#"
            class="field chip custom" 
            @click.prevent="toggleAssetsOnlineEmergency"
            :class="[ isShowAssetsOnlineEmergency ? [ 'bg-primary-6', 'light' ] : [ 'bg-transparent' ] ]"
        >
            <div class="horizontal-flex-container">
                <img src="/assets/img/exclamation-mark-orange.svg" alt="" class="field-icon">
                <div class="field-value text-highlight text-primary-6 bright">
                    <strong>{{ assetsOnlineEmergency }}</strong> online for emergency use
                </div>
            </div>
            <img v-if="isShowAssetsOnlineEmergency" src="/assets/img/x-yellow-bright.svg" class="chip-icon" alt="">
        </a>
        <a
            href="#"
            class="field chip custom"
            @click.prevent="toggleAssetsUnknownStatus"
            :class="[ isShowAssetsUnknownStatus ? [ 'bg-primary-3', 'light' ] : [ 'bg-transparent' ] ]"
        >
            <div class="horizontal-flex-container">
                <img src="/assets/img/question-mark-orange.svg" alt="" class="field-icon">
                <div class="field-value text-highlight text-primary-3 bright">
                    <strong>{{ assetsUnknown }}</strong> unknown status
                </div>
            </div>
            <img v-if="isShowAssetsUnknownStatus" src="/assets/img/x-orange-bright.svg" class="chip-icon" alt="">
        </a>
        <a
            href="#"
            class="field chip custom"
            @click.prevent="toggleAssetsOffline"
            :class="[ isShowAssetsOffline ? [ 'bg-primary-5', 'light' ] : [ 'bg-transparent' ] ]"
        >
            <div class="horizontal-flex-container">
                <img src="/assets/img/cancel-red.svg" alt="" class="field-icon">
                <div class="field-value text-highlight text-primary-5 bright">
                    <strong>{{ assetsOffline }}</strong> offline
                </div>
            </div>
            <img v-if="isShowAssetsOffline" src="/assets/img/x-red-bright.svg" class="chip-icon" alt="">
        </a>
    </div>
</template>

<script>
import assetStatus from "../assets/asset-status";

// Mixins
import { assetsMixin } from "../assets";

export default {
    mixins: [ assetsMixin ],
    props: {
        assets: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            assetsAwaitingApproval: 0,
            assetsOnline: 0,
            assetsOnlineEmergency: 0,
            assetsUnknown: 0,
            assetsOffline: 0
        };
    },
    computed: {
        isShowAssetsAwaitingApproval: function() {
            return this.filters.assets.status === assetStatus.awaitingApproval;
        },
        isShowAssetsOnline: function() {
            return this.filters.assets.status === assetStatus.online;
        },
        isShowAssetsOnlineEmergency: function() {
            return this.filters.assets.status === assetStatus.onlineEmergency;
        },
        isShowAssetsUnknownStatus: function() {
            return this.filters.assets.status === assetStatus.none;
        },
        isShowAssetsOffline: function() {
            return this.filters.assets.status === assetStatus.offline;
        }
    },
    watch: {
        assets: function() {
            this.assetsAwaitingApproval = this.getAssetsAwaitingApproval(this.assets).length;
            this.assetsOnline = this.getAssetsOnline(this.assets).length;
            this.assetsOnlineEmergency = this.getAssetsOnlineEmergency(this.assets).length;
            this.assetsUnknown = this.getAssetsUnknown(this.assets).length;
            this.assetsOffline = this.getAssetsOffline(this.assets).length;
        }
    },
    methods: {
        toggleAssetsAwaitingApproval() {
            this.toggleAssetStatusFilter(assetStatus.awaitingApproval);
        },
        toggleAssetsOnline() {
            this.toggleAssetStatusFilter(assetStatus.online);
        },
        toggleAssetsOnlineEmergency() {
            this.toggleAssetStatusFilter(assetStatus.onlineEmergency);
        },
        toggleAssetsOffline() {
            this.toggleAssetStatusFilter(assetStatus.offline);
        },
        toggleAssetsUnknownStatus() {
            this.toggleAssetStatusFilter(assetStatus.none);
        },
        toggleAssetStatusFilter(targetStatus) {
            const { status } = this.filters.assets;
            const isSet = status === targetStatus;
            this.filters.assets.status = !isSet ? targetStatus : undefined;
            this.$emit("changed", this.filters.assets.status);
        }
    }
};
</script>