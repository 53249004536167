import moment from 'moment';

export const momentMixin = {
  methods: {
    formatDate(date, format) {
      return moment(date).format(format);
    },
    isEpoch(date) {
      return date ? moment(date).toDate().getTime() === 0 : false;
    },
    isDateSet(date) {
      return date !== undefined
        && date !== null
        && !this.isEpoch(date);
    }
  }
}