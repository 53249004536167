var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('stepper',{attrs:{"steps":1,"close":_vm.close,"finish":_vm.saveSupportVisits,"loading":_vm.loading,"loading-message":_vm.loadingMessage},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var steps = ref.steps;
var finish = ref.finish;
var close = ref.close;
return [_c('step',{attrs:{"step":1,"steps":steps,"close":close,"finish":finish,"isValid":_vm.selectedEngineers.length > 0,"show-progress":false,"heading":"Which support engineer would you like to add to this visit?"}},[_c('v-item-group',{attrs:{"multiple":""},model:{value:(_vm.selectedEngineers),callback:function ($$v) {_vm.selectedEngineers=$$v},expression:"selectedEngineers"}},[_c('v-row',{staticClass:"no-border"},_vm._l((_vm.calloutEngineers),function(engineer){return _c('v-col',{key:engineer.id,attrs:{"cols":"4","xl":"3","md":"4","sm":"12"}},[_c('v-item',{attrs:{"value":engineer.id},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('call-out-engineer',{attrs:{"engineer":engineer,"active":active,"toggle":toggle,"isPrimary":false,"fieldServiceManager":engineer.fieldServiceManager,"serviceManager":engineer.serviceManager}})]}}],null,true)})],1)}),1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }