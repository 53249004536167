<template>
  <div 
    style="width: 100%"
  >
    <h3 class="heading-4 ml-16 mb-10">Callout jobs</h3>

    <stepper-dialog
      :show="creatingJob"
      @close="creatingJob = false"
    >
      <template v-slot:default="{ close }">
        <job-create
          @close="close"
        />
      </template>
    </stepper-dialog>
    <v-navigation-drawer
      fixed
      permanent
      expand-on-hover
      dark
      color="grundfos"
    >
      <v-list
        shaped
      >
        <v-list-item class="px-0">
          <v-list-item-avatar
            tile
            size="30"
            class="ma-auto"
          >
            <v-img
              src="/assets/img/grundfos-logo-white.png"
              contain
            ></v-img>
          </v-list-item-avatar>
        </v-list-item>
        <v-list-item
          link
          @click="creatingJob = true"
        >
          <v-list-item-icon>
            <v-icon>mdi-plus</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Create callout</v-list-item-title>
        </v-list-item>
      </v-list>

      <template v-slot:append>
        <div class="py-2">
          <v-list
            shaped
          >
            <v-list-item
              link
              target="_blank"
              href="https://docs.grundfos-service.com/callouts/"
            >
              <v-list-item-icon>
                <v-icon>mdi-help-circle-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Help</v-list-item-title>
            </v-list-item>
            <v-list-item
              link
              @click="logout"
            >
              <v-list-item-icon>
                <v-icon>mdi-logout</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item>
          </v-list>
        </div>
      </template>
    </v-navigation-drawer>
    <jobs-table
      :jobs="jobs"
      :isLoading="loading"
      class="ml-16 mt-4 mr-2"
    />
  </div>
</template>

<script>
// External libraries
import { mapActions, mapGetters } from "vuex";

// Components
import JobCreate from './actions/Create';
import JobsTable from './JobsTable';
import StepperDialog from '@/components/stepper/StepperDialog';

export default {
  data() {
    return {
      loading: false,
      creatingJob: false
    };
  },
  components: {
    JobCreate,
    JobsTable,
    StepperDialog
  },
  computed: {
    ...mapGetters({
      'jobs': 'jobs/jobs'
    }),
  },
  methods: {
    ...mapActions({
      "loadJobs": "jobs/loadJobs"
    }),
    logout() {
      this.$auth.logout();
    },
  },
  async mounted() {
    this.loading = true;
    try {
      await this.loadJobs({ chargeScheme: 7 });
    } finally {
      this.loading = false;
    }
  }
}
</script>