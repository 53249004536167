<template>
    <div class="hero-block">
        <div class="hero-details">
            <div class="chip bg-primary-5 lighter">
                not home
            </div>
            <h3 class="hero-title">Home late</h3>
            <div class="hero-description">This engineer has not yet arrived home. Please click one of the options above to update his status.</div>
            <div class="hero-actions">
                <tick-button 
                    class="hero-action-button"
                    defaultLabel="On site"
                    executingLabel="Updating"
                    executedLabel="Updated"
                    :func="setOnSiteFunc"
                />
                <tick-button 
                    class="hero-action-button"
                    defaultLabel="Travelling"
                    executingLabel="Updating"
                    executedLabel="Updated"
                    :func="setTravellingHomeFunc"
                />
                <tick-button 
                    class="hero-action-button"
                    defaultLabel="At home"
                    executingLabel="Updating"
                    executedLabel="Updated"
                    :func="setAtHomeFunc"
                />
                <tick-button 
                    class="hero-action-button"
                    defaultLabel="Help"
                    executingLabel="Updating"
                    executedLabel="Updated"
                    :func="setRequiresAssistanceFunc"
                />
            </div>
        </div>
        <div class="hero-illustration">
            <img src="/assets/illustrations/undraw_warning_cyit.svg" alt="">
        </div>
    </div>
</template>

<script>
// External
import { mapActions } from "vuex";

// Components
import TickButton from "../../../../components/buttons/TickButton";

export default {
    props: {
        engineer: {
            type: Object,
            required: true
        }
    },
    components: {
        TickButton
    },
    methods: {
        ...mapActions({
            setOnSite: "prefs/engineers/setOnSite",
            setTravellingHome: "prefs/engineers/setTravellingHome",
            setAtHome: "prefs/engineers/setAtHome",
            setRequiresAssistance: "prefs/engineers/setRequiresAssistance"
        }),
        async setOnSiteFunc() {
            await this.setOnSite(this.engineer.id);
        },
        async setTravellingHomeFunc() {
            await this.setTravellingHome(this.engineer.id);
        },
        async setAtHomeFunc() {
            await this.setAtHome(this.engineer.id);
        },
        async setRequiresAssistanceFunc() {
            await this.setRequiresAssistance(this.engineer.id);
        }
    }
};
</script>