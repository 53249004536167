<template>
    <div class="row my-1">
        <img src="/assets/img/noun_Calendar_3145959.svg" alt="" class="row-icon small">
        <div class="row-heading"><strong>{{ weekday }}</strong> {{ day }}</div>
        <div class="row-fields">
            <div class="row-field">
            <div class="row-field-label">First departure</div>
            <div class="row-field-value">{{ firstDepartureTime }}</div>
            </div>
            <div class="row-field align-bottom">
            <div class="row-field-label">Last report</div>
            <div class="row-field-value">--</div>
            </div>
            <div class="row-field align-bottom">
            <div class="row-field-label">Return end</div>
            <div class="row-field-value">{{ returnTravelTime }}</div>
            </div>
            <div class="row-field">
            <div class="row-field-label">Home geofence</div>
            <div class="row-field-value">--</div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment-timezone";

export default {
    props: {
        date: {
            type: String,
            required: true
        },
        firstDeparture: {
            type: String,
            required: false,
            default: undefined
        },
        returnTravel: {
            type: String,
            required: false,
            default: undefined
        }
    },
    computed: {
        dateMoment: function() {
            return moment(this.date);
        },
        day: function() {
            return this.dateMoment.format("DD");
        },
        weekday: function() {
            return this.dateMoment.format("ddd");
        },
        firstDepartureTime: function() {
            return this.firstDeparture ? moment(this.firstDeparture).format("HH:mm") : "--";
        },
        returnTravelTime: function() {
            return this.returnTravel ? moment(this.returnTravel).format("HH:mm") : "--";
        }
    }
};
</script>