<template>
  <div>
    <button
      class="labeled-button medium bg-primary-1"
      @click="isShowAssetForm = true"
    >
      Add asset
    </button>
    <v-dialog
      v-model="isShowAssetForm"
      fullscreen
      hide-overlay
      persistent
      transition="dialog-bottom-transition"
    >

      <progress-overlay 
        :isExecuting="saving" 
        @animationComplete="close"
      />

      <v-row
        no-gutters
        align="center"
        justify="center"
        style="height: 100vh"
      >
        <v-col
          align-self="center"
          md="6"
        >
          <v-form
            ref="form"
            v-model="valid"
            class="pa-2"
            lazy-validation
          >
            <h3 class="heading-3">Create new asset</h3>

            <v-autocomplete
              v-model="product"
              :loading="loading"
              :items="products"
              :search-input.sync="search"
              :rules="[v => !!v || 'You have to select a product']"
              required
              label="What is the product?"
              hint="Search for products using their name or code"
              class="mt-6"
              item-text="ProductCode"
              return-object
              auto-select-first
              flat
              outlined
              persistent-hint
              clearable
              no-filter
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-title v-if="!lastQuery">
                    Search for Grundfos <strong>products</strong>
                  </v-list-item-title>
                  <v-list-item-title v-else>
                    No products matching <strong>{{ lastQuery }}</strong> found
                  </v-list-item-title>
                </v-list-item>
              </template>
              <template v-slot:item="{ item }">
                <v-list-item-content>
                  <v-list-item-title v-text="item.ProductCode"></v-list-item-title>
                  <v-list-item-subtitle v-text="item.ProductDescription"></v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-autocomplete>
            <v-text-field
              v-model="assetDescription"
              :rules="[v => !!v || 'You have to enter an asset description']"
              required
              class="mt-4"
              label="Asset description"
              hint="This is typically the name of the product. You must set this for products that are not in Grundfos' product database."
              :disabled="!product || !isUnknownProduct()"
              flat
              clearable
              outlined
              persistent-hint
            ></v-text-field>
            <v-select
              v-model="site"
              :items="sites"
              :rules="[v => !!v || 'You have to select a site']"
              required
              class="mt-4"
              item-text="name"
              label="In which site is the asset located?"
              hint="Select an existing site"
              return-object
              flat
              clearable
              outlined
              persistent-hint
            ></v-select>
            <v-combobox
              v-model="location"
              :items="locations"
              :rules="[v => !!v || 'You have to select an existing location or enter a new one']"
              required
              class="mt-4"
              label="Where is the asset located on site?"
              hint="Select existing locations or enter a new one"
              flat
              clearable
              outlined
              persistent-hint
            ></v-combobox>
            <v-text-field
              v-model="assetTag"
              :rules="[v => !!v || 'You have to enter an asset tag']"
              required
              class="mt-4"
              label="Asset tag"
              flat
              clearable
              outlined
            ></v-text-field>
          </v-form>
          <div class="d-flex justify-end">
            <v-btn
              elevation="0"
              class="mr-4"
              rounded
              @click="close"
            >
              Cancel
            </v-btn>
            <v-btn
              elevation="0"
              class="primary mr-4"
              rounded
              @click="saveAsset"
            >
              Create asset
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-dialog>

    <v-snackbar
      v-model="snack"
      :timeout="snackTimeout"
      :color="snackColour"
    >
      {{ snackText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          text
          @click="snack = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { v4 as uuid } from "uuid";
import { mapActions, mapGetters } from "vuex";
import { assetsMixin } from "../assets";

// Components
import TickButton from '@/components/buttons/TickButton';
import ProgressOverlay from '@/components/overlays/ProgressOverlay';

export default {
  mixins: [ assetsMixin ],
  data () {
    return {
      isShowAssetForm: false,
      assetDescription: undefined,
      assetTag: undefined,
      contractId: this.$route.params.id,
      loading: false,
      products: [],
      product: undefined,
      search: undefined,
      scheduledSearch: undefined,
      sites: [],
      site: undefined,
      locations: [],
      location: undefined,
      valid: true,
      saving: false,
      searched: false,
      lastQuery: undefined,
      snack: false,
      snackTimeout: 5000,
      snackColour: 'primary',
      snackText: undefined,
    }
  },
  components: {
    ProgressOverlay,
    TickButton,
  },
  computed: {
    ...mapGetters({
        "assets": "contract/assets",
        "contract": "contract/contract"
    })
  },
  watch: {
    search (val) {
      if (val && (!this.product || val !== this.product.ProductCode)) {
        this.scheduleSearch(val);
      }
    },
    site (val) {
      this.locations = [];
      if (val) {
        this.getLocations();
      }
    },
    product (val) {
      if (this.product && !this.isUnknownProduct()) {
        this.assetDescription = this.product.ProductDescription;
      }
    },
  },
  methods: {
    ...mapActions({
      addAsset: 'contract/addAsset',
    }),
    isUnknownProduct() {
      return this.product.ProductCode.toLowerCase().includes('unkpro');
    },
    async scheduleSearch(query) {
      if (this.scheduledSearch) {
        clearTimeout(this.scheduledSearch);
      }

      this.scheduledSearch = setTimeout(() => this.searchProducts(query), 350);
    },
    async searchProducts(query) {
      try {
        const tokens = query.trim().split(/\s+/gi);
        const search = `${tokens.join('* AND ')}*`;
        this.loading = true;
        const api = await this.$api();
        const { data: products } = await api.get('/products', { params: { search } });
        this.products = products;
        this.lastQuery = query;
      } finally {
        this.loading = false;
      }
    },
    getLocations() {
      const siteAssets = this.assets.filter(a => a.site.id === this.site.id);
      this.locations = siteAssets.map(a => a.position).filter(l => l !== undefined && l !== null);
      this.locations = [ ...new Map(this.locations.map(l => [ l.toLowerCase().trim(), l.trim() ])).values() ];
    },
    close() {
      this.isShowAssetForm = false;
      const vm = this;
      setTimeout(() => {
        vm.$refs.form.reset();
        vm.products = [];
      }, 1000);
    },
    async saveAsset() {
      const isValid = this.$refs.form.validate();
      if (isValid) {
        this.saving = true;
        const assetId = uuid();
        const user = await this.$auth.user;
        const { sub: createdBy } = user;
        let asset = {
          assetId,
          createdBy,
          contractId: this.contractId,
          productId: Number(this.product.id),
          description: this.assetDescription,
          siteId: Number(this.site.id),
          location: this.location,
          assetTag: this.assetTag
        };
        const api = await this.$api();
        await api.put(`/contracts/${this.contractId}/assets/${assetId}`, asset);

        // Add asset to the contract
        const { ProductCode: code, ProductDescription: description } = this.product;
        asset = {
          ...asset,
          id: assetId,
          position: asset.location,
          site: this.site,
          product: {
            code,
            description
          },
          tasks: [],
          isHydrated: true,
          equipmentStatus: 'awaitingapproval',
        };
        this.addAsset(asset);
        this.saving = false;

        const vm = this;
        setTimeout(() => vm.showSnack(`Your request to add asset ${description} to the contract is under review by our team.`), 4500);
      }
    },
    showSnack(text, colour = 'primary') {
      this.snack = true;
      this.snackColour = colour;
      this.snackText = text;
    }
  },
  mounted() {    
    this.sites = this.getSites(this.assets);
  }
}
</script>