<template>
    <div class="calendar">
        <div class="calendar-header">
            <div class="text-bolder">{{ header }}</div>
            <div class="calendar-actions">
                <a href="#" class="icon-button tiny bg-grey-3 w-inline-block" @click.prevent="subtractMonth">
                    <img src="/assets/img/left-black.svg" alt="">
                </a>
                <div class="spacer-15"></div>
                <a href="#" class="icon-button tiny bg-grey-3 w-inline-block" @click.prevent="addMonth">
                    <img src="/assets/img/right-black.svg" alt="">
                </a>
            </div>
        </div>
        <month 
            :date="date"
            :appointments="monthAppointments"
            @selected="onDateSelected"
        />
    </div>
</template>

<script>
// External libraries
import moment from "moment";
import { calendarMixin } from "./calendar-mixin";

// Components
import Month from "./Month";

export default {
    mixins: [ calendarMixin ],
    props: {
        startingDate: {
            type: String,
            default: moment().format()
        }
    },
    data() {
        return {
            date: moment(this.startingDate)
        };
    },
    components: {
        Month
    },
    computed: {
        header: function() {
            return this.getCalendarMomentStart().format("MMMM, YYYY");
        },
        monthAppointments: function() {
            const calMomentStart = this.getCalendarMomentStart();
            const calMomentEnd = this.getCalendarMomentEnd();
            return this.getOverlappingAppointments(calMomentStart, calMomentEnd);
        }
    },
    methods: {
        addMonth() {
            this.date = this.date.clone().add(1, "month");
        },
        subtractMonth() {
            this.date = this.date.clone().subtract(1, "month");
        },
        getCalendarMomentStart() {
            return this.date.clone().startOf("month");
        },
        getCalendarMomentEnd() {
            return this.date.clone().endOf("month");
        },
        onDateSelected(payload) {
            this.$emit("selected", payload);
        }
    }
};
</script>