<template>
    <div class="content-container navbar-offset side-panel-offset">
        <div class="content">
            <div class="content-section engineer-status-section">
                <div class="content-section-container">
                    <engineer-hero-card :engineer="engineer" />
                </div>
            </div>
            <div class="content-section profile-section">
                <div class="content-section-container">
                    <mobile-profile v-if="engineer" :engineer="engineer" />
                </div>
            </div>
            <div class="content-section">
                <content-section-heading
                    text="Work Timeline"
                    :isLoaded="engineer !== undefined"
                >
                    <button class="icon transparent w-inline-block" @click="getDayBefore">
                        <img src="/assets/img/noun_Left_3145913.svg" alt="">
                    </button>
                    <div v-if="visitsDay">{{ formattedVisitsDay }}</div>
                    <button class="icon transparent w-inline-block" @click="getDayAfter">
                        <img src="/assets/img/noun_right_3145906.svg" alt="">
                    </button>
                </content-section-heading>
                
                <transition
                    enter-active-class="animated fadeIn faster"
                    leave-active-class="animated fadeOut faster"
                    mode="out-in"
                >
                    <div
                        v-if="!isVisitsLoaded"
                        key="ganttSkeleton"
                    >
                        <v-skeleton-loader width="100%" type="heading" />
                    </div>
                    <div
                        v-else
                        key="gantt"
                    >
                        <div v-if="visits.length === 0">No visits on this day.</div>
                    </div>
                </transition>

                <div id="gantt" ref="gantt"></div>
            </div>
            <div class="content-section">
                <content-section-heading
                    text="Weekly Timesheet"
                    :isLoaded="engineer !== undefined"
                >
                    <button class="icon transparent w-inline-block" @click="getWeekBefore">
                        <img src="/assets/img/noun_Left_3145913.svg" alt="">
                    </button>
                    <div v-if="timesheetWeek">{{ timesheetWeek.format("DD/MM") }} - {{ timesheetWeek.clone().isoWeekday(7).format("DD/MM") }}</div>
                    <button class="icon transparent w-inline-block" @click="getWeekAfter">
                        <img src="/assets/img/noun_right_3145906.svg" alt="">
                    </button>
                </content-section-heading>
                <div class="content-section-container">
                    <transition
                        enter-active-class="animated fadeIn faster"
                        leave-active-class="animated fadeOut faster"
                        mode="out-in"
                    >
                        <div
                            v-if="!timesheet"
                            key="timesheetSkeleton"
                            class="rows-container"
                        >
                            <timesheet-row-skeleton
                                v-for="n in 7"
                                :key="n"
                            />
                        </div>
                        <div
                            v-else
                            key="timesheet"
                            class="rows-container"
                        >
                            <timesheet-row 
                                v-for="(value, key) in timesheet" 
                                :key="key" 
                                :date="key" 
                                :firstDeparture="value.firstDepartureDateTimeLocal" 
                                :returnTravel="value.returnTravelDateTimeLocal" 
                            />
                        </div>
                    </transition>
                </div>
            </div>
        </div>

        <transition
            enter-active-class="animated fadeIn faster"
            leave-active-class="animated fadeOut faster"
            mode="out-in"
        >
            <side-panel-skeleton
                v-if="!engineer"
                key="sidePanelSkeleton"
            />
            <side-panel
                v-else
                key="sidePanel"
                :engineer="engineer"
            />
        </transition>
        
    </div>
</template>

<script>
// External packages
import { init } from "mermaid";
import moment from "moment-timezone";
import { mapActions, mapGetters } from "vuex";
import { engineerMixin } from "./engineer";
import { mermaidMixin } from "./mermaid";
import { HyperlinkMenuItem, RouterlinkMenuItem } from "../../navbar";

// Components
import EngineerHeroCard from "./status/card/EngineerHeroCard";
import MobileProfile from "./profile/MobileProfile";
import SidePanel from "./profile/SidePanel";
import SidePanelSkeleton from "./profile/SidePanelSkeleton";
import TimesheetRow from "./TimesheetRow";
import TimesheetRowSkeleton from "./TimesheetRowSkeleton";
import ContentSectionHeading from "../../components/ContentSectionHeading";

export default {
    mixins: [engineerMixin, mermaidMixin],
    components: {
        EngineerHeroCard,
        MobileProfile,
        SidePanel,
        SidePanelSkeleton,
        TimesheetRow,
        TimesheetRowSkeleton,
        ContentSectionHeading
    },
    data() {
        return {
            visitsDay: undefined,
            timesheetWeek: undefined,
            engineer: undefined,
            engineerId: this.$route.params.id,
            visits: [],
            isVisitsLoaded: false,
            timesheet: undefined
        };
    },
    watch: {
        engineer: async function() {
            if (this.engineer !== undefined) {
                this.engineer.notifications = await this.getNotifications(this.engineer);
            }
        },
        engineers: async function() {
            await this.setEngineer();
        },
        visits: function() {
            this.loadGantt();
        }
    },
    computed: {
        ...mapGetters({
            "engineers": "prefs/engineers/engineers"
        }),
        formattedVisitsDay: function() {
            return this.visitsDay.calendar(undefined, {
                sameDay: "[Today]",
                nextDay: "[Tomorrow]",
                nextWeek: "dddd",
                lastDay: "[Yesterday]",
                lastWeek: "[Last] dddd",
                sameElse: "DD/MM/YYYY"
            });
        }
    },
    methods: {
        ...mapActions({
            "loadEngineer": "prefs/engineers/loadEngineer",
            "setMenuItems": "navbar/setMenuItems"
        }),
        async setEngineer(isRefresh = false) {
            this.engineer = this.engineers[this.engineerId];
            if (this.engineer) {
                this.loadMenuItems();

                // Only load this once to preserve the user's visit day choice (i.e. on auto-refresh)
                if (!this.visitsDay) {
                    this.visitsDay = moment.tz(this.engineer.timezone);
                    this.loadDayVisits(this.engineer.visits.today.all);
                }

                // Only load this once to preserve the user's timesheet week choice (i.e. on auto-refresh)
                if (!this.timesheetWeek) {
                    this.timesheetWeek = moment.tz(this.engineer.timezone).startOf("isoWeek");
                    this.loadWeekTimesheet();
                }
            }

            if (isRefresh) {
                // Trigger a refresh of the engineer in the background
                await this.loadEngineer(this.engineerId);
            }
        },
        async loadWeekTimesheet() {
            const accessToken = await this.$auth.getTokenSilently();
            const config = {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            };
            
            const api = await this.$api();
            const { data } = await api.get(`/engineers/${this.engineerId}/timesheets/week/${this.timesheetWeek.year()}/${this.timesheetWeek.isoWeek()}`, config);
            this.timesheet = data;
        },
        async loadDayVisits(data) {
            this.isVisitsLoaded = false;
            if (!data) {
                const accessToken = await this.$auth.getTokenSilently();
                const config = {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                };
                
                const api = await this.$api();
                ({ data } = await api.get(`/engineers/${this.engineerId}/visits/${this.visitsDay.year()}/${this.visitsDay.month()+1}/${this.visitsDay.date()}`, config));
            }
            
            this.visits = data;
            this.isVisitsLoaded = true;
        },
        getWeekBefore() {
            this.timesheetWeek.subtract(1, "isoWeek");
            this.loadWeekTimesheet();
        },
        getWeekAfter() {
            this.timesheetWeek.add(1, "isoWeek");
            this.loadWeekTimesheet();
        },
        getDayBefore() {
            this.visitsDay.subtract(1, "day");
            this.loadDayVisits();
        },
        getDayAfter() {
            this.visitsDay.add(1, "day");
            this.loadDayVisits();
        },
        loadGantt() {
            if (this.$refs.gantt.attributes["data-processed"])
                this.$refs.gantt.attributes.removeNamedItem("data-processed");

            this.$refs.gantt.innerHTML = "";
            if (this.visits.length > 0) {
                this.$refs.gantt.innerHTML = this.visitsToGantt(this.visits);
                init("#gantt");
            }
        },
        loadMenuItems() {
            const menuItems = [
                new RouterlinkMenuItem("/assets/img/noun_users_2303154-1.svg", "/engineers")
            ];

            if (this.engineer) {
                menuItems.push(new RouterlinkMenuItem("/assets/img/noun_edit_3145955-1.svg", `/engineer/edit/${this.engineerId}`));
                menuItems.push(new HyperlinkMenuItem("/assets/img/noun_envelope_3145966.svg", `mailto:${this.engineer.email}`));
                if (this.engineer.mobile) {
                    menuItems.push(new HyperlinkMenuItem("/assets/img/noun_Outgoing-Call_792164.svg", `tel:${this.engineer.mobile.replace(/\s/gm, "")}`));
                }
            }
            this.setMenuItems(menuItems);
        }
    },
    mounted() {
        this.setEngineer(true);
        this.loadMenuItems();
    }
};
</script>