<template>
    <div class="profile-details">
        <div class="profile-avatar-container">
            <img v-if="engineer.avatar" :src="engineer.avatar" alt="" class="profile-avatar">
            <div v-else class="profile-avatar-text">{{ engineer.initials }}</div>
        </div>

        <div class="profile-name">
            <span class="strong">{{ engineer.firstname }}</span> {{ engineer.surname }}
        </div>
    </div>
</template>

<script>
export default {
    props: {
        engineer: {
            type: Object,
            required: true
        }
    }
};
</script>