<template>
    <shrinkable-filter
        :headingStat="sites.length"
        :heading="$tc('filter_sites_heading', sites.length)"
    >
        <template v-slot:body>
            <a 
                href="#"
                class="field chip custom"
                v-for="site in sites"
                :key="site.name"
                :class="[ selected === site ? [ 'bg-grey-3' ] : [ 'bg-transparent' ] ]"
                @click.prevent="selectSite(site)"
            >
                <div class="horizontal-flex-container">
                    <div class="field-value text-highlight">
                        <strong>{{ site.count }}</strong> {{ site.name }}
                    </div>
                </div>
                <img v-if="selected === site" src="/assets/img/x-black.svg" class="chip-icon" alt="">
            </a>
        </template>
    </shrinkable-filter>
</template>

<script>
// Components
import ShrinkableFilter from "./ShrinkableFilter";

// Mixins
import { assetsMixin } from "../assets";

export default {
    mixins: [assetsMixin],
    props: {
        assets: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            selected: undefined
        };
    },
    components: {
        ShrinkableFilter
    },
    computed: {
        sites: function() {
            const sites = this.getSites(this.assets);
            for (const site of sites) {
                site.count = this.assets.filter(a => site.name === a.site.name).length;
            }
            this.tryReselectSite(sites);
            return sites;
        }
    },
    methods: {
        selectSite(site) {
            if (this.selected !== site) {
                this.selected = site;
            } else {
                this.selected = undefined;
            }
            this.$emit("changed", this.selected);
        },
        tryReselectSite(sites) {
            if (this.selected) {
                this.selected = sites.find(s => s.name === this.selected.name);
            }
        }
    }
};
</script>