var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-calendar',{ref:"calendar",attrs:{"now":_vm.day,"value":_vm.day,"events":_vm.events,"weekdays":[1, 2, 3, 4, 5, 6, 0],"color":"primary","type":"week"},on:{"click:event":_vm.showEvent},scopedSlots:_vm._u([{key:"event",fn:function(ref){
var ref_event = ref.event;
var start = ref_event.start;
var end = ref_event.end;
var type = ref_event.type;
var job = ref_event.job;
var appointment = ref_event.appointment;
var notes = ref_event.notes;
var description = ref_event.description;
return [_c('div',{staticClass:"d-flex flex-column"},[(type !== 'appointment')?_c('div',{staticClass:"pa-2",class:{ 'primary-text--text': type === 'travel' }},[_c('div',[_vm._v(_vm._s(job.ref))]),_c('div',[_vm._v(_vm._s(type !== 'travel' ? job.type : ''))]),_c('div',[_vm._v(_vm._s(_vm.formatDate(start, 'HH:mm'))+" - "+_vm._s(_vm.formatDate(end, 'HH:mm')))])]):(appointment.type !== 10)?_c('div',{staticClass:"pa-2"},[_vm._v(" "+_vm._s(description)+" "),_c('div',{staticClass:"text-caption"},[_vm._v(_vm._s(notes))]),_c('div',[_vm._v(_vm._s(_vm.formatDate(start, 'HH:mm'))+" - "+_vm._s(_vm.formatDate(end, 'HH:mm')))])]):_c('div',{staticClass:"px-2 primary-text--text"},[_vm._v(_vm._s(description)+" - "+_vm._s(notes))])])]}}])}),(_vm.selectedEvent.type === 'onsite')?_c('v-menu',{attrs:{"close-on-content-click":false,"activator":_vm.selectedElement,"offset-x":""},model:{value:(_vm.selectedOpen),callback:function ($$v) {_vm.selectedOpen=$$v},expression:"selectedOpen"}},[_c('v-card',{attrs:{"color":"grey lighten-4","min-width":"350px","flat":""}},[_c('v-card-text',[_c('div',{staticClass:"text-subtitle-1 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.selectedEvent.job.ref)+" ")]),_c('div',{staticClass:"text-subtitle-2 mt-2"},[_vm._v(" Site ")]),_c('div',{staticClass:"text-body-2"},_vm._l((_vm.selectedEvent.siteFields),function(field,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(field)+" ")])}),0),_c('div',{staticClass:"text-subtitle-2 mt-4"},[_vm._v(" Customer ")]),_c('div',{staticClass:"text-body-2"},[_c('div',[_vm._v(" "+_vm._s(_vm.selectedEvent.customer.name)+" ")])])])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }