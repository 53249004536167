<template>
  <div class="vertical-flex-container grow">
    <transition
      appear
      enter-active-class="animated fadeIn faster"
      leave-active-class="animated fadeOut faster"
      mode="out-in"
    >
      <div 
        v-if="!isLoaded"
        key="loading"
        ref="loading"
        data-name="loading"
        class="lottie loading"
        data-animation-path="/assets/lottie/loading.json"
        data-anim-loop="true"
        data-anim-type="svg"
      ></div>
      <div v-else key="contract">
        <div class="side-illustration">
          <img src="/assets/illustrations/undraw_quite_town_mg2q.svg" alt="">
        </div>
        <div class="content-section">
          <div class="content-section-heading custom">
            <h3 class="heading-3">Service Contract</h3>
          </div>
        </div>
        <div class="horizontal-flex-container wrap service-contract">
          <div class="content-section contract-filters">
            <div class="content">
              <contract-details />
              <div class="spacer-30"></div>
              <asset-status-filter :assets="filteredAssets" @changed="onAssetStatusFilterChanged" />
              <div class="spacer-30"></div>
              <task-status-filter :assets="filteredAssets" @changed="onTaskStatusFilterChanged" />
              <div class="spacer-30"></div>
              <sites-filter :assets="filteredAssets" @changed="onSitesFilterChanged" />
            </div>
            <div class="spacer-30"></div>
            <div class="content">
              <div class="content-section framed bg-grey-5">
                <div class="content-section-container calendar">
                  <calendar
                    :startingDate="calendarDate.format()"
                    :appointments="visits.map(v => ({ id: v.id, start: v.probableStartDateTime, end: v.probableEndDateTime }) )"
                    @selected="onDateSelected"
                  />
                  <contract-visits
                    class="calendar-appointments"
                    :date="calendarDate.format()"
                    :visits="calendarVisits"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="content-section assets-list">
            <div class="content-section-heading">
              <div class="search-box grow">
                <img src="/assets/img/search-black.svg" alt="" class="icon small transparent middle-align">
                <div class="form-block w-form">
                    <input
                      type="text"
                      class="search-field w-input"
                      maxlength="256"
                      name="name"
                      data-name="Name"
                      placeholder="Asset tag, description, product, ESM number, etc."
                      id="name"
                      v-model="filters.assets.text"
                    >
                </div>
              </div>
              <div class="spacer-15"></div>
              <asset-form />
            </div>
            <div class="content-section-container">
              <assets
                v-if="isLoaded"
                :assets="filteredAssets"
              />
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
// Mixins
import { assetsMixin } from "./assets";

// External packages
import { registerAnimation, play, stop } from "lottie-web";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";

// Components
import Assets from "./assets/Assets";
import AssetForm from "./assets/AssetForm";
import AssetStatusFilter from "./filters/AssetStatus";
import Calendar from "../../components/calendar/Calendar";
import ContractDetails from "./ContractDetails";
import ContractVisits from "./ContractVisits";
import SitesFilter from "./filters/Sites";
import TaskStatusFilter from "./filters/TaskStatus";

export default {
    mixins: [ assetsMixin ],
    data() {
        return {
            contractId: this.$route.params.id,
            calendarDate: moment().startOf("day"),
            isLoaded: false,
            isShowAssetForm: false
        };
    },
    components: {
      Assets,
      AssetForm,
      AssetStatusFilter,
      Calendar,
      ContractDetails,
      ContractVisits,
      SitesFilter,
      TaskStatusFilter
    },
    computed: {
        ...mapGetters({
            "assets": "contract/assets",
            "contract": "contract/contract",
            "visits": "contract/visits"
        }),
        filteredAssets: function() {
          const vm = this;
          let filtered = this.getFilteredAssets();
          const status = this.filters.assets.status;
          filtered = status === undefined ? filtered : this.getAssetsWithStatus(status, filtered);
          const site = this.filters.assets.site;
          filtered = site === undefined ? filtered : filtered.filter(a => a.site.name === site.name);
          const taskStatus = this.filters.assets.taskStatus;
          filtered = taskStatus === undefined ? filtered : filtered.filter(a => {
            const lastTask = vm.getLastExecutedTask(a);
            return lastTask && lastTask.statusId === taskStatus;
          });
          return filtered;
        },
        calendarVisits: function() {
          return this.visits.filter(v => moment(v.probableStartDateTime).startOf("day").isSame(this.calendarDate));
        }
    },
    watch: {
      contract: function() {
        const vm = this;
        setTimeout(() => {
          vm.isLoaded = true;
          setTimeout(() => stop("loading"), 1000);
        }, 2000);
      }
    },
    methods: {
      ...mapActions({
          "getContract": "contract/getContract",
          "getContractVisits": "contract/getContractVisits"
      }),
      getFilteredAssets() {
        const filter = this.filters.assets.text;
        const regex = new RegExp(`${filter}`, "i");

        const matchesReferences = (a) => a.tasks.some(t => (t.equipmentReference && t.equipmentReference.match(regex)) || (t.customerReference && t.customerReference.match(regex)));
        const matchesEquipmentDescription = (a) => a.equipmentDescription && a.equipmentDescription.match(regex);
        const matchesAssetTag = (a) => a.assetTag && a.assetTag.match(regex);
        const matchesSite = (a) => (a.site.name && a.site.name.match(regex)) || (a.site.line1 && a.site.line1.match(regex));
        const matchesPosition = (a) => a.position && a.position.match(regex);
        const matchesProductCode = (a) => a.product && a.product.code.match(regex);
        const matchesProductDescription = (a) => a.product && a.product.description.match(regex);
        const matchesProductGroup = (a) => a.product && a.product.group && a.product.group.name.match(regex);
        const matchesProductCategory = (a) => a.product && a.product.category && a.product.category.name.match(regex);
        const matchesFilter = (a) => {
          return matchesEquipmentDescription(a)
            || matchesReferences(a)
            || matchesAssetTag(a)
            || matchesSite(a)
            || matchesPosition(a)
            || matchesProductCode(a)
            || matchesProductDescription(a)
            || matchesProductGroup(a)
            || matchesProductCategory(a);
        };

        return filter === "" ? this.assets : this.assets.filter(a => matchesFilter(a));
      },
      onDateSelected(payload) {
        const { date } = payload;
        this.calendarDate = date;
      },
      onAssetStatusFilterChanged(payload) {
        this.filters.assets.status = payload;
      },
      onSitesFilterChanged(payload) {
        this.filters.assets.site = payload;
      },
      onTaskStatusFilterChanged(payload) {
        this.filters.assets.taskStatus = payload;
      }
    },
    mounted() {
      registerAnimation(this.$refs.loading);
      play("loading");
      this.getContract(this.contractId);
      this.getContractVisits(this.contractId);
    }
};
</script>

<style scoped>
.lottie.loading {
  width: 50vw;
  height: 50vh;
  margin: auto;
}

.content-section-heading.custom {
  margin: 0 30px;
}
</style>