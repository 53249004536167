<template>
    <div class="hero-block">
        <div class="hero-details">
            <div class="chip bg-primary-3 light">
                available for work
            </div>
            <h3 class="hero-title">Available</h3>
            <idle
                class="hero-description"
                :timezone="engineer.timezone"
                :start="nextIdleSlot.start"
                :end="nextIdleSlot.end"
            />
        </div>
        <div class="hero-illustration">
            <img src="/assets/illustrations/undraw_reading_time_gvg0.svg" alt="">
        </div>
    </div>
</template>

<script>
import Idle from "../descriptions/Idle";
import { engineerMixin } from "../../engineer";

export default {
    mixins: [engineerMixin],
    props: {
        engineer: {
            type: Object,
            required: true
        }
    },
    components: {
        Idle
    }
};
</script>