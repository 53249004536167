<template>
    <a 
        href="#" 
        @click.prevent="onClicked" 
        class="calendar-month-day text-normal text-primary-1"
        :class="{ selected: isSelected }"
    >
        <div 
            class="text-small" 
            :class="{ 'text-faded': isFaded }"
        >
            {{ day }}
        </div>
        <div class="day-appointments">
            <div
                v-for="a in appointments.length"
                :key="a"
                class="dot-tiny"
                :class="[ isSelected ? 'bg-white' : 'bg-primary-1' ]"
            ></div>
        </div>
    </a>
</template>

<script>
export default {
    props: {
        id: {
            required: true
        },
        day: {
            type: Number,
            required: true
        },
        appointments: {
            type: Array,
            default: () => []
        },
        isFaded: {
            type: Boolean,
            default: false
        },
        isSelected: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        onClicked() {
            this.$emit("clicked", { id: this.id, appointments: this.appointments });
        }
    }
};
</script>