<template>
    <div class="calendar-body">
        <div class="w-layout-grid grid">
            <div class="calendar-weekday-header">Mo</div>
            <div class="calendar-weekday-header">Tu</div>
            <div class="calendar-weekday-header">We</div>
            <div class="calendar-weekday-header">Th</div>
            <div class="calendar-weekday-header">Fr</div>
            <div class="calendar-weekday-header">Sa</div>
            <div class="calendar-weekday-header">Su</div>
            <day
                v-for="(d, index) in dates"
                :key="index"
                :id="index"
                :day="d.get('date')"
                :appointments="getAppointments(d)"
                :isFaded="d.get('month') !== date.get('month')"
                :isSelected="isSelected(d)"
                @clicked="onDayClicked"
            />       
        </div>
    </div>
</template>

<script>
// External libraries
import moment from "moment";
import { calendarMixin } from "./calendar-mixin";

// Components
import Day from "./Day";

const firstWeekDay = "monday";
const lastWeekDay = "sunday";

export default {
    mixins: [ calendarMixin ],
    props: {
        appointments: {
            type: Array,
            default: () => []
        },
        date: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            dates: [],
            selected: moment(this.date).startOf("day")
        };
    },
    components: {
        Day
    },
    methods: {
        getCalendarDates(month, year) {
            const monthMoment = moment().year(year).month(month);
            let day = monthMoment.daysInMonth();
            const dates = [];
            while (day) {
                const date = monthMoment.clone().date(day).startOf("day");
                dates.unshift(date);
                day--;
            }

            // Add previous month's dates to the calendar to complete the first week displayed in the calendar
            while (dates[0].format("dddd").toLowerCase() !== firstWeekDay) {
                dates.unshift(dates[0].clone().subtract(1, "day"));
            }

            // Add next month's dates to the calendar to complete the last week displayed in the calendar
            while (dates[dates.length - 1].format("dddd").toLowerCase() !== lastWeekDay) {
                dates.push(dates[dates.length - 1].clone().add(1, "day"));
            }

            // Add an extra row to the end of the calendar to smooth out transitions to months with
            // a different number of weeks
            if (dates.length === 35) {
                for (let i = 0; i < 7; i += 1) {
                    dates.push(dates[dates.length - 1].clone().add(1, "day"));
                }
            }

            return dates;
        },
        isSelected(date) {
            return date.isSame(this.selected);
        },
        getAppointments(date) {
            return this.getOverlappingAppointments(date.clone().startOf("day"), date.clone().endOf("day"));
        },
        onDayClicked(payload) {
            this.selected = this.dates[payload.id];
            this.$emit("selected", { date: this.selected, appointments: payload.appointments });
        }
    },
    watch: {
        date: function() {
            this.dates = this.getCalendarDates(this.date.month(), this.date.year());
        }
    },
    mounted() {
        this.dates = this.getCalendarDates(this.date.month(), this.date.year());
    }
};

</script>