<template>
  <stepper
    :steps="1"
    :close="close"
    :finish="saveSupportVisits"
    :loading="loading"
    :loading-message="loadingMessage"
  >
    <template v-slot:default="{ steps, finish, close }">
      <step
        :step="1"
        :steps="steps"
        :close="close"
        :finish="finish"
        :isValid="selectedEngineers.length > 0"
        :show-progress="false"
        heading="Which support engineer would you like to add to this visit?"
      >
        <v-item-group
          v-model="selectedEngineers"
          multiple
        >
          <v-row
            class="no-border"
          >
            <v-col
              v-for="engineer in calloutEngineers"
              :key="engineer.id"
              cols="4"
              xl="3"
              md="4"
              sm="12"
            >
              <v-item :value="engineer.id" v-slot="{ active, toggle }">
                <call-out-engineer
                  :engineer="engineer"
                  :active="active"
                  :toggle="toggle"
                  :isPrimary="false"
                  :fieldServiceManager="engineer.fieldServiceManager"
                  :serviceManager="engineer.serviceManager"
                />
              </v-item>
            </v-col>
          </v-row>
        </v-item-group>
      </step>
    </template>
  </stepper>
</template>

<script>
// External libraries
import { orderBy } from 'lodash';
import { mapActions, mapGetters } from "vuex";

// Components
import CallOutEngineer from './actions/CallOutEngineer';
import Step from '@/components/stepper/Step';
import Stepper from '@/components/stepper/Stepper';

export default {
  props: {
    visit: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      loadingMessage: 'Loading engineers',
      selectedEngineers: [],
      site: undefined,
      routesToSite: {}
    }
  },
  components: {
    CallOutEngineer,
    Step,
    Stepper
  },
  computed: {
    ...mapGetters({
      "engineers": "prefs/engineers/engineers",
      'jobs': 'jobs/jobs'
    }),
    calloutEngineers: function() {
      const regions = [1, 2, 3, 4];
      let engineers = Object.values(this.engineers).filter(e => regions.indexOf(e.regionId) > -1 && e.id !== this.visit.engineerId)
      for (const engineer of engineers.filter(e => e.address !== undefined)) {
        const routeToSite = this.routesToSite[engineer.address.id];
        let travelTimeInSeconds = Number.MAX_VALUE;
        if (routeToSite) {
          ({ travelTimeInSeconds } = routeToSite);
        }
        engineer.travelTime = travelTimeInSeconds;
      }
      engineers = orderBy(engineers, [ 'isOnCall', 'travelTime', 'regionId' ], [ 'desc', 'asc', 'asc' ]);
      return engineers;
    }
  },
  methods: {
    ...mapActions({
      "loadEngineers": "prefs/engineers/loadEngineers",
      "loadManagers": "prefs/engineers/loadManagers",
      "postVisits": "jobs/postVisits",
    }),
    async loadRoutesToSite() {
      if (this.site && this.engineers) {
        const from = this.site.address.id;
        const to = Object.values(this.calloutEngineers).filter(e => e.address !== undefined).map(e => e.address.id).join(',');
        const api = await this.$api();
        ({ data: this.routesToSite } = await api.get('/routes', { params: { from, to } }));
      }
    },
    async loadSite() {
      const api = await this.$api();
      ({ data: this.site } = await api.get(`/jobs/${this.visit.jobId}/site`));
    },
    async saveSupportVisits() {
      const { id: createdById } = this.$auth.profisUser;
      const { id: visitId, jobId, confirmedById, scheduledStartDateTime, estimatedDuration } = this.visit;
      const job = this.jobs.find(j => j.id === jobId);
      const visits = [];
      for (const selected of this.selectedEngineers) {
        const engineer = this.engineers[selected];
        const { id: engineerId } = engineer;
        const visit = {
          jobId,
          engineerId,
          confirmedById,
          scheduledStartDateTime,
          estimatedDuration,
          createdById,
          isPrimaryEngineer: false,
          visitStatusId: 7,
          primaryVisitId: visitId
        };
        visits.push(visit);
      }

      await this.postVisits({ job, visits, engineers: this.selectedEngineers.map(i => this.engineers[i]) });
    },
    reset() {
      this.selectedEngineers = [];
    },
    close() {
      this.reset();
      this.$emit('close');
    }
  },
  async mounted() {
    this.loading = true;
    try {
      await this.loadEngineers();
      await this.loadSite();
      await this.loadRoutesToSite();
      await this.loadManagers();
    } finally {
      this.loading = false;
    }
  }
}
</script>