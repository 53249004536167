import moment from "moment-timezone";
import { sortBy } from "lodash";

export const engineerMixin = {
    computed: {
        nextAppointment() {
            return this.getNextAppointment(this.engineer);
        },
        nextVisit() {
            return this.getNextVisit(this.engineer);
        },
        nextIdleSlot() {
            const now = moment.tz(this.engineer.timezone);
            const nextIdleSlot = this.getNextIdleSlot(this.engineer);

            const isActuallyIdle = 
                (
                    nextIdleSlot 
                    && nextIdleSlot.start.isAfter(now)
                ) || 
                (
                    nextIdleSlot
                    && !this.isTravellingToSite 
                    && !this.isOnSite
                );
            return isActuallyIdle ? nextIdleSlot : undefined;
        },
        isAssistanceRequired() {
            return this.engineer.statusNotifications[0].status === "help";
        },
        isAtHome() {
            return this.getIsAtHome(this.engineer);
        },
        isIdle() {
            return this.getIsIdle(this.nextIdleSlot, this.nextVisit);
        },
        isLateArrivingHome() {
            return this.getIsLateArrivingHome(this.engineer);
        },
        isLateDeparture() {
            return this.getIsLateDeparture(this.engineer);
        },
        isOnSite() {
            return this.getIsOnSite(this.engineer);
        },
        isTravellingHome() {
            return this.getIsTravellingHome(this.engineer);
        },
        isTravellingToSite() {
            return this.getIsTravellingToSite(this.engineer);
        },
        isUnavailable() {
            const nextIdleSlot = this.nextIdleSlot;
            const nextVisit = this.nextVisit;
            const nextAppointment = this.nextAppointment;

            return this.engineer.isUnavailable
                || 
                (
                    nextAppointment
                    && (!nextIdleSlot || nextAppointment.start.isBefore(nextIdleSlot.start))
                    && (!nextVisit || nextAppointment.start.isBefore(moment.tz(nextVisit.scheduledStartDateTime, nextVisit.timezone)))
                );
        }
    },
    methods: {
        async getNotifications(engineer) {
            const engineerId = engineer.id;
            const day = moment.tz(engineer.timezone).format();
            let notifications = [];
            const api = await this.$api();

            // Get notifications sent to the engineer
            let { data } = await api.get("/notifications", {
                params: {
                    recipientId: engineerId,
                    day
                }
            });
            notifications = data.map(n => { return { ...n, createdOn: n.sentOn }; });

            // Get notifications the engineer sent back (i.e. status updates)
            ({ data } = await api.get("/status-notifications", {
                params: {
                    subjectId: engineerId,
                    day
                }
            }));
            notifications = [ ...notifications, ...data ];

            return sortBy(notifications, (n) => n.createdOn);
        },
        getIsIdle(slot, nextVisit) {
            return !this.isOnSite 
                && !this.isTravellingToSite
                && !this.isTravellingHome
                && slot
                && slot.end.isAfter(moment.tz(this.engineer.timezone))
                && (!nextVisit || slot.start.isBefore(moment.tz(nextVisit.scheduledStartDateTime, nextVisit.timezone)));
        },
        getIsLateDeparture(engineer) {
            return engineer.visits.today.all.some(v => v.isLateDeparture === true);
        },
        getIsTravellingToSite(engineer) {
            return engineer.visits.today.all.some(v => v.isTravellingToSite === true);
        },
        getIsOnSite(engineer) {
            return engineer.visits.today.all.some(v => v.isOnSite === true);
        },
        getIsTravellingHome(engineer) {
            return engineer.visits.today.all.some(v => v.isTravellingHome === true);
        },
        getIsLateArrivingHome(engineer) {
            return engineer.isLateArrivingHome;
        },
        getIsAtHome(engineer) {
            return engineer.isHome && !engineer.isUnavailable;
        },
        getNextVisit(engineer) {
            const today = engineer.visits.today.all;
            // Visit on-site now
            let visit = today.find(v => v.isLateDeparture === true
                        || v.isTravellingToSite === true
                        || v.isOnSite === true
                        || v.isTravellingHome === true);
            if (!visit) {
                // Upcoming visit later today
                const now = moment.tz(engineer.timezone);
                visit = today.find(v => moment.tz(v.scheduledStartDateTime, engineer.timezone).isSameOrAfter(now));
            }

            // Nullify current visit if the engineer has already returned home
            if (visit && visit.isCompletedVisit && engineer.isHome) {
                visit = undefined;
            }
        
            if (!visit) {
                // Visit on the next working day
                visit = engineer.visits.nextWorkingDay.all.find(v => !v.isCompletedVisit);
            }
        
            return visit;
        },
        getNextIdleSlot(engineer) {
            const now = moment.tz(engineer.timezone);
            const slots = [ ...engineer.idleSlots.today, ...engineer.idleSlots.nextWorkingDay ];
            let slot, start, end, duration;
            for (let i = 0; i < slots.length && !slot; i += 1) {
                const s = slots[i];
                start = moment.tz(s.start, engineer.timezone);
                if (start.isBefore(now))
                    start = now;
                end = moment.tz(s.end, engineer.timezone);
                duration = end.diff(start, "minutes");
        
                if (duration >= 90) {
                    slot = s;
                }
            }
        
            return slot && start && end && duration ? { start, end, duration } : undefined;
        },
        getNextAppointment(engineer) {
            let appointment, start, end, description, notes, type;
            for (let i = 0; i < engineer.appointments.length && !appointment; i += 1) {
                const a = engineer.appointments[i];
                const s = moment.tz(a.fromDateTime, engineer.timezone);

                if (!appointment || s.isBefore(start)) {
                    appointment = a;
                    start = s;
                    end = moment.tz(a.toDateTime, engineer.timezone);
                    description = a.description;
                    notes = a.notes;
                    type = a.type;
                }
            }
            return appointment && start && end && (description || notes) && type ? { start, end, description, notes, type } : undefined;
        }
    }
};