<template>
    <div class="side-panel">
        <transition
            enter-active-class="animated fadeInRight faster"
            leave-active-class="animated fadeOutRight faster"
            mode="out-in"
        >
          <component
            :is="activePanel"
            :engineer="engineer"
            @shownotifications="onShowNotifications"
            @hidenotifications="onHideNotifications"
          />
        </transition>
    </div>
</template>

<script>
import Notifications from "./Notifications";
import Profile from "./Profile";

export default {
    props: {
        engineer: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            activePanel: "profile"
        };
    },
    components: {
        Notifications,
        Profile
    },
    methods: {
        onShowNotifications() {
            this.activePanel = "Notifications";
        },
        onHideNotifications() {
            this.activePanel = "Profile";
        }
    }
};
</script>