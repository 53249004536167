<template>
  <v-list
    two-line
    elevation="0"
  >
    <v-toolbar
      flat
      dense
    >
      <v-spacer></v-spacer>

      <v-btn
        icon
        @click="openContactDialog"
      >
        <v-icon>mdi-account-plus</v-icon>
      </v-btn>
    </v-toolbar>

    <v-dialog
      v-model="isShowContactDialog"
      fullscreen
      hide-overlay
      persistent
      transition="dialog-bottom-transition"
    >

      <v-row
        no-gutters
        align="center"
        justify="center"
        style="height: 100vh"
      >
        <v-col
          align-self="center"
          md="6"
        >
          <v-form
            ref="form"
            v-model="isContactFormValid"
            class="pa-2"
            lazy-validation
          >
            <h3 class="heading-3">New site contact</h3>
            
            <v-text-field
              v-model="newContact.firstname"
              class="mt-4"
              outlined
              label="First name"
              :rules="[v => !!v || 'You have to enter a first name']"
              required
            ></v-text-field>
          
            <v-text-field
              v-model="newContact.surname"
              class="mt-4"
              outlined
              label="Last name"
              :rules="[v => !!v || 'You have to enter a last name']"
              required
            ></v-text-field>
          
            <v-text-field
              v-model="newContact.email"
              class="mt-4"
              outlined
              label="Email"
              :rules="[v => !!v || 'You have to enter an e-mail address']"
              required
            ></v-text-field>
          
            <v-text-field
              v-model="newContact.mobile"
              class="mt-4"
              outlined
              label="Mobile"
              required
              type="tel"
              :rules="[v => !!v || 'You have to enter a mobile number']"
            ></v-text-field>
          </v-form>

          <div class="d-flex justify-end">
            <v-btn
              elevation="0"
              class="mr-4"
              @click="closeContactDialog"
            >
              Cancel
            </v-btn>
            <v-btn
              elevation="0"
              class="primary mr-4"
              @click="addContact"
            >
              Add contact
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-dialog>

    <v-list-item-group
      v-model="selected"
      active-class="blue--text"
    >
      <template v-for="(item, index) in items">
        <v-list-item :key="item.id" :value="item.id">
          <template v-slot:default="{ active }">
            <v-list-item-avatar class="contact-avatar">
              <v-avatar
                :class="{ 'white--text': active, 'info--text': !active }"
                :color="active ? 'info' : 'transparent'"
              >
                {{ item.initials }}
              </v-avatar>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title v-text="item.title"></v-list-item-title>

              <v-list-item-subtitle
                class="text--primary"
                v-text="item.headline"
              ></v-list-item-subtitle>

              <v-list-item-subtitle v-text="item.subtitle"></v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-icon
                v-if="!active"
                color="grey lighten-1"
              >
                mdi-checkbox-blank-circle-outline
              </v-icon>

              <v-icon
                v-else
                color="info"
              >
                mdi-checkbox-marked-circle-outline
              </v-icon>
            </v-list-item-action>
          </template>
        </v-list-item>

        <v-divider
          v-if="index < items.length - 1"
          :key="index"
        ></v-divider>
      </template>
    </v-list-item-group>
  </v-list>
</template>

<script>
import { uniqWith } from 'lodash';
import { v4 as uuid } from 'uuid';

const contactTemplate = {
  firstname: '',
  surname: '',
  email: '',
  mobile: ''
};

export default {
  props: {
    site: {
      type: Object,
      required: true
    },
    filter: {
      type: Array,
      default: []
    }
  },
  data: () => ({
    newContact: {},
    selected: undefined,
    contacts: [],
    isContactFormValid: true,
    isShowContactDialog: false,
    isSavingContact: false,
  }),
  watch: {
    site: function() {
      if (this.site.id) {
        this.loadContacts();
      } else {
        this.contacts = [];
        this.selected = undefined;
      }
    },
    selected: function(val) {
      if (val) {
        const { headline, subtitle, title, initials, ...contact } = this.items.find(i => i.id === this.selected);
        this.$emit('select', { id: this.selected, contact });
      }
    }
  },
  computed: {
    items: function() {
      return this.contacts.filter(c => this.filter.indexOf(c.id) === -1).map(c => this.toItem(c));
    }
  },
  methods: {
    async loadContacts() {
      const api = await this.$api();
      let contacts;
      ({ data: contacts } = await api.get(`/sites/${this.site.id}/contacts`));

      // Remove duplicate contacts
      this.contacts = uniqWith(contacts, (a, b) => {
        const isNameSet = a.firstname && a.surname && b.firstname && b.surname;
        const isNameEquals = isNameSet
          && a.firstname.toLowerCase() === b.firstname.toLowerCase()
          && a.surname.toLowerCase() === b.surname.toLowerCase();
        const isEmailSet = a.email && b.email;
        const isEmailEquals = isEmailSet && a.email.toLowerCase() === b.email.toLowerCase();
        return isNameEquals || isEmailEquals;
      });
    },
    toItem(contact) {
      const { email, mobile, telephone, firstname, surname } = contact;
      return { 
        ...contact,
        headline: email, 
        subtitle: mobile || telephone, 
        title: `${firstname} ${surname}`, 
        initials: `${firstname[0]}${surname[0]}`
      };
    },
    openContactDialog() {
      this.newContact = { ...contactTemplate };
      this.isShowContactDialog = true;
    },
    closeContactDialog() {
      this.isShowContactDialog = false;
      this.$refs.form.reset();
      this.newContact = { ...contactTemplate };
    },
    async addContact() {
      const isValid = this.$refs.form.validate();
      if (isValid) {
        this.isSavingContact = true;
        try {
          // Add it to the top of the list of items
          const contact = { 
            ...this.newContact, 
            isNew: true,
            id: uuid()
          };
          this.contacts.unshift(contact);
          // Select it
          this.selected = contact.id;
        } finally {
          this.isSavingContact = false;
          this.closeContactDialog();
        }
      }
    }
  }
}
</script>

<style scoped>
.contact-avatar {
  border-width: 1px;
  border-style: solid;
  border-color: var(--v-info-base) !important;
}
</style>