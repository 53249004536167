<template>
  <div>
    <v-dialog
      v-model="confirmDelete"
      persistent
      max-width="400"
    >
      <v-card>
        <v-card-title class="headline">
          Delete job {{ job.reference }}?
        </v-card-title>
        <v-card-subtitle></v-card-subtitle>
        <v-card-text>
          <p>
            You cannot undo this action later.
          </p>
          <p>
            Any visits associated with this job will also be cancelled.
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="grey lighten-4"
            elevation="0"
            @click="closeDeleteDialog"
          >
            Keep it
          </v-btn>
          <v-btn
            color="red darken-1"
            elevation="0"
            dark
            :loading="isDeleting"
            @click="deleteJob"
          >
            Delete the job
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-card 
      tile
      elevation="0"
      :loading="isLoading"
    >
      <v-app-bar
        flat
        dense
      >
        <v-tabs
          v-if="!isLoading"
          v-model="tab"
        >
          <v-tabs-slider></v-tabs-slider>

          <v-tab href="#customer">
            Customer
          </v-tab>

          <v-tab href="#site">
            Site
          </v-tab>

          <v-tab v-if="job.contract" href="#contract">
            Contract
          </v-tab>

          <v-tab v-if="job.visits.length > 0" href="#visits">
            Visits
          </v-tab>
        </v-tabs>

        <v-spacer></v-spacer>

        <v-btn
          v-if="!isLoading"
          icon
          color="warning"
          @click="openDeleteDialog"
        >
          <v-icon>mdi-delete-outline</v-icon>
        </v-btn>
      </v-app-bar>

      <v-tabs-items
        v-if="!isLoading"
        v-model="tab"
      >
        <v-tab-item value="customer">
          <v-list three-line>
            <v-subheader inset>Details</v-subheader>

            <v-list-item>
              <v-list-item-icon>
                <v-icon>
                  mdi-office-building
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ job.workOrder.customer.name || job.workOrder.customer.address.line1 }}</v-list-item-title>
                <v-list-item-subtitle>{{ job.workOrder.customer.reference || '--' }}</v-list-item-subtitle>
                <v-list-item-subtitle>{{ job.workOrder.customer.groupEmail }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-divider inset></v-divider>

            <v-subheader inset>Contacts</v-subheader>

            <contact-list-item
              v-for="(contact, index) in contacts.customer"
              :key="index"
              :showIcon="index === 0"
              :contact="contact"
            />
          </v-list>
        </v-tab-item>
        <v-tab-item value="site">
          <v-list three-line>
            <v-subheader inset>Details</v-subheader>

            <v-list-item>
              <v-list-item-icon>
                <v-icon>
                  mdi-map-marker
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ job.site.name || job.site.address.line1 }}</v-list-item-title>
                <v-list-item-subtitle>{{ job.site.address.city || '--' }}</v-list-item-subtitle>
                <v-list-item-subtitle>{{ job.site.address.postcode }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-divider inset></v-divider>

            <v-subheader inset>Contacts</v-subheader>

            <contact-list-item
              v-for="(contact, index) in contacts.site"
              :key="index"
              :showIcon="index === 0"
              :contact="contact"
            />
          </v-list>
        </v-tab-item>
        <v-tab-item 
          v-if="job.contract"
          value="contract"
        >
          <v-list three-line>
            <v-subheader inset>Details</v-subheader>

            <v-list-item>
              <v-list-item-icon>
                <v-icon>
                  mdi-signature-freehand
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ job.contract.reference }}</v-list-item-title>
                <v-list-item-subtitle>{{ $t('contract_start_date', { date: getDate(job.contract.startDate) }) }}</v-list-item-subtitle>
                <v-list-item-subtitle>{{ $t('contract_end_date', { date: getDate(job.contract.endDate) }) }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-divider inset></v-divider>

            <v-subheader inset>Contact</v-subheader>

            <contact-list-item
              :showIcon="true"
              :contact="job.contract.contact"
            />
          </v-list>
        </v-tab-item>
        <v-tab-item 
          v-if="job.visits.length > 0"
          value="visits"
        >
          <v-list
            three-line
          >
            <v-subheader v-if="visits.scheduled.length > 0" inset>Scheduled</v-subheader>

            <visit-list-item
              v-for="visit in visits.scheduled"
              :key="visit.id"
              :visit="visit"
            />

            <v-divider v-if="visits.scheduled.length > 0 && visits.completed.length > 0" inset></v-divider>

            <v-subheader v-if="visits.completed.length > 0" inset>Completed</v-subheader>

            <visit-list-item
              v-for="visit in visits.completed"
              :key="visit.id"
              :visit="visit"
            />
          </v-list>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
// External libraries
import moment from 'moment';
import { mapActions, mapGetters } from "vuex";

// Components
import ContactListItem from './ContactListItem';
import VisitListItem from './VisitListItem';

export default {
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      isLoading: false,
      contacts: {
        customer: [],
        site: []
      },
      visits: {
        scheduled: [],
        completed: []
      },
      tab: undefined,
      confirmDelete: false,
      isDeleting: false,
    };
  },
  components: {
    ContactListItem,
    VisitListItem
  },
  watch: {
    job (val) {
      this.loadDetails();
    }
  },
  computed: {
    ...mapGetters({
      'jobs': 'jobs/jobs'
    }),
    job: function() {
      return this.jobs.find(j => j.id === this.id);
    }
  },
  methods: {
    ...mapActions({
      "loadJob": "jobs/loadJob",
      "delete": "jobs/delete"
    }),
    closeDeleteDialog() {
      this.confirmDelete = false;
    },
    openDeleteDialog() {
      this.confirmDelete = true;
    },
    async deleteJob() {
      this.isDeleting = true;
      const { id, job } = this;
      try {
        const vm = this;
        await this.delete({ id, cb: () => {
          vm.$emit('deleted', { job });
        } });
      } finally {
        this.isDeleting = false;
        this.closeDeleteDialog();
      }
    },
    getDate(iso) {
      return moment(iso).format('DD/MM/YYYY');
    },
    loadContacts() {
      this.contacts.customer = this.job.contacts.filter(c => c.isCallerContact || c.type === 3).map(jc => jc.contact);
      this.contacts.site = this.job.contacts.filter(c => c.type === 2).map(jc => jc.contact);
    },
    loadVisits() {
      this.visits.completed = this.job.visits.filter(v => v.isCompletedVisit);
      this.visits.scheduled = this.job.visits.filter(v => !v.isCompletedVisit);
    },
    loadDetails() {
      this.loadContacts();
      this.loadVisits();
    },
    async load() {
      this.isLoading = true;
      try {
        await this.loadJob(this.id);
        this.loadDetails();
      } finally {
        this.isLoading = false;
      }
    }
  },
  async mounted() {
    await this.load();
  }
}
</script>