import Vue from "vue";

function initAsset(asset) {
    if (!asset.tasks) {
        asset.tasks = [];
    }

    asset.investigationTasks = getInvestigationTasks(asset);
    asset.quotedWorksTasks = getQuotedWorksTasks(asset);
    asset.serviceTasks = getServicesTasks(asset);
    asset.lastServiceTask = getLastServiceTask(asset);
    return asset;
}

function getInvestigationTasks(asset) {
    return getJobTypeTasks(asset, 5);
}

function getQuotedWorksTasks(asset) {
    return getJobTypeTasks(asset, 7);
}

function getServicesTasks(asset) {
    return getJobTypeTasks(asset, 8);
}

function getJobTypeTasks(asset, jobTypeId) {
    return asset.tasks.filter(t => t.visit !== undefined && t.visit.jobTypeId === jobTypeId);
}

function getLastServiceTask(asset) {
    return asset.tasks.find(t => t.visit !== undefined && t.visit.jobTypeId === 8);
}

const state = {
    assets: [],
    contract: {},
    visits: []
};

const mutations = {
    asset(state, payload) {
        const index = state.assets.findIndex(a => a.id === payload.id);
        if (index > -1) {
            state.assets.splice(index, 1, payload);
        } else {
            state.assets.unshift(payload);
        }
    },
    assets(state, payload) {
        state.assets = payload;
    },
    contract(state, payload) {
        state.contract = payload;
    },
    visit(state, payload) {
        const index = state.visits.findIndex(v => v.id === payload.id);
        if (index > -1) {
            state.visits.splice(index, 1, payload);
        } else {
            state.visits.push(payload);
        }
    },
    visits(state, payload) {
        state.visits = payload;
    }
};

const actions = {
    async getContract({ commit }, contractId) {
        const api = await Vue.prototype.$api();
        const { data } = await api.get(`/contracts/${contractId}`);
        const { equipment, ...contract } = data;
        const assets = equipment.map(a => initAsset(a));

        commit("contract", contract);
        commit("assets", assets);
    },
    async getContractVisits({ commit }, contractId) {
        const api = await Vue.prototype.$api();
        const { data: visits } = await api.get(`/contracts/${contractId}/visits`);
        commit("visits", visits);
    },
    updateAsset({ commit }, asset) {
        commit("asset", initAsset(asset));
    },
    addAsset({ commit }, asset) {
        commit("asset", initAsset(asset));
    }
};

const getters = {
    assets(state) {
        return state.assets;
    },
    contract(state) {
        return state.contract;
    },
    visits(state) {
        return state.visits;
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};