<template>
    <div class="content-section framed bg-grey-5 grow vertical-flex-container">
        <div class="horizontal-flex-container grow space-between">
            <div class="text-large mb30">
                <span class="text-bold">{{ headingStat }}</span> {{ heading }}
            </div>
            <a href="#" class="icon-button smaller bg-grey-4" @click.prevent="toggleFilterVisibility">
                <img v-if="isVisible || isShrinking" src="/assets/img/arrow-up-black.svg" alt="">
                <img v-else-if="!isShrinking" src="/assets/img/arrow-down-black.svg" alt="">
            </a>
        </div>
        <transition
            name="shrink"
            v-on:before-enter="onExpanding"
            v-on:after-enter="onExpanded"
            v-on:before-leave="onShrinking"
            v-on:after-leave="onShrunk"
        >
            <div v-if="isVisible" class="filter-body">
                <slot name="body"></slot>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    props: {
        headingStat: {
            type: Number,
            required: true
        },
        heading: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            isExpanding: false,
            isVisible: false,
            isShrinking: false
        };
    },
    methods: {
        toggleFilterVisibility() {
            this.isVisible = !this.isVisible;
        },
        onExpanding() {
            this.isExpanding = true;
        },
        onExpanded() {
            this.isExpanding = false;
        },
        onShrinking() {
            this.isShrinking = true;
        },
        onShrunk() {
            this.isShrinking = false;
        }
    }
};
</script>

<style scoped>
.filter-body {
    overflow: hidden;
}

.shrink-enter-active,
.shrink-leave-active {
  transition: max-height 0.75s ease-out;
  max-height: 999px;
}
.shrink-enter,
.shrink-leave-to
{
  max-height: 0px;
}
</style>