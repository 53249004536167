<template>
    <div class="primary-menu" :class="{ horizontal: isBottom }">
        <!-- <div class="menu-item user-avatar-container">
            <a href="#" class="w-inline-block">
                <img src="/assets/img/chris-parker-2_bdb7d2f1-4c89-425f-91fe-4ba213c2f92f.jpg" alt="" class="user-avatar">
            </a>
        </div> -->
        <div
            v-for="(item, index) in menuItems"
            :key="index"
            :class="{ small: isBottom }"
            class="menu-item"
        >
            <router-link 
                v-if="item.type === types.routerlink"
                :to="item.to"
                class="w-inline-block"
            >
                <img :src="item.icon" alt="">
            </router-link>
            <a 
                v-else-if="item.type === types.hyperlink && !item.href"
                @click.prevent="item.onclick"
                class="w-inline-block"
            >
                <img :src="item.icon" alt="">
            </a>
            <a 
                v-else-if="item.type === types.hyperlink && item.href"
                :href="item.href"
                class="w-inline-block"
            >
                <img :src="item.icon" alt="">
            </a> 
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { MenuItemTypes } from "../navbar";

export default {
    props: {
        isBottom: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            types: MenuItemTypes
        };
    },
    computed: {
        ...mapGetters({
            "menuItems": "navbar/menuItems"
        })
    }
};
</script>