import moment from "moment-timezone";

export const mermaidMixin = {
    methods: {
        // toGantt(visits, appointments) {

        // },
        // appointmentsToGantt(appointments) {
            
        // },
        visitsToGantt(visits) {
            const sections = [];
            for (let i = 0; i < visits.length; i += 1)
                sections.push(this.visitToSection(visits[i]));
            return `gantt
            axisFormat	%H:%M
            ${sections.join("\n")}`;
        },
        visitToSection(visit) {           
            const scheduledDateTime = this.getTaskDateTime(visit.scheduledStartDateTime, visit.timezone);
            const estimatedDurationTask = `Planned duration     :done, ${this.getTaskRef(visit, "est")}, ${scheduledDateTime}, ${visit.estimatedDuration}h`;
            const tasks = [ estimatedDurationTask ];

            if (visit.departedDateTimeLocal) {
                const travelStatus = visit.isArrivedOnSite ? "done" : "active";
                const travelStart = this.getTaskDateTime(visit.departedDateTimeLocal, visit.timezone);
                let travelEnd = scheduledDateTime;
                if (visit.isArrivedOnSite)
                    travelEnd = this.getTaskDateTime(visit.arrivedDateTimeLocal, visit.timezone);

                tasks.push(`Travel  :${travelStatus}, ${this.getTaskRef(visit, "tra")}, ${travelStart}, ${travelEnd}`);
            }

            if (visit.arrivedDateTimeLocal) {
                const onSiteStatus = visit.actualEndDateTimeLocal ? "done" : "active";
                const onSiteStart = this.getTaskDateTime(visit.arrivedDateTimeLocal, visit.timezone);
                const plannedOnSiteEnd = moment.tz(visit.arrivedDateTimeLocal, visit.timezone).add(visit.estimatedDuration, "hours");
                const now = moment.tz(visit.timezone);
                let onSiteEnd = `${visit.estimatedDuration}h`;
                if (visit.actualEndDateTimeLocal)
                    onSiteEnd = this.getTaskDateTime(visit.actualEndDateTimeLocal, visit.timezone);
                else if (plannedOnSiteEnd.isSameOrBefore(now))
                    onSiteEnd = `${this.getTaskDateTime(now, visit.timezone)}`;

                tasks.push(`On site :${onSiteStatus}, ${this.getTaskRef(visit, "ons")}, ${onSiteStart}, ${onSiteEnd}`);
            }

            if (visit.returnTravelDuration && visit.returnTravelDuration > 0) {
                const returnTravelStart = this.getTaskDateTime(visit.actualEndDateTimeLocal, visit.timezone);
                const returnTravelEnd =`${Math.round(visit.returnTravelDuration)}m`;
                tasks.push(`Travel home :done, ${this.getTaskRef(visit, "ret")}, ${returnTravelStart}, ${returnTravelEnd}`);
            }

            return `Section ${visit.jobReference} 
            ${tasks.join("\n")}`;
        },
        getTaskRef(visit, suffix) {
            return `${visit.jobReference}.${suffix}`;
        },
        getTaskDateTime(dateTime, timezone) {
            return moment.tz(dateTime, timezone).format("YYYY-MM-DD HH:mm");
        }
    }
};