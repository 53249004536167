<template>
  <div>
    <stepper-dialog
      :show="addingSupportVisits"
      @close="addingSupportVisits = false"
    >
      <template v-slot:default="{ close }">
        <add-support-visits
          :visit="visit"
          @close="close"
        />
      </template>
    </stepper-dialog>

    <v-list-item>
      <v-list-item-avatar>
        <v-img v-if="visit.engineer.avatar" :src="visit.engineer.avatar"></v-img>
        <v-avatar class="primary white--text" v-else>{{ visit.engineer.firstname[0].toUpperCase() }}{{ visit.engineer.surname[0].toUpperCase() }}</v-avatar>
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title>{{ visit.engineer.firstname }} {{ visit.engineer.surname }}</v-list-item-title>
        <v-list-item-subtitle v-if="visit.isPrimaryEngineer">primary</v-list-item-subtitle>
        <v-list-item-subtitle v-else>support</v-list-item-subtitle>
        <v-list-item-subtitle>{{ getDateTime(visit.probableStartDateTime) }}</v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-action>
        <v-tooltip
          bottom
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="visit.isPrimaryEngineer && !visit.isCompletedVisit"
              icon
              v-bind="attrs"
              v-on="on"
              @click="addSupportVisits"
            >
              <v-icon color="primary">
                mdi-account-multiple-plus-outline
              </v-icon>
            </v-btn>
          </template>
          <span>Add support engineers</span>
        </v-tooltip>
      </v-list-item-action>
    </v-list-item>
  </div>
</template>

<script>
// External libraries
import moment from 'moment';

// Components
import AddSupportVisits from './AddSupportVisits';
import StepperDialog from '@/components/stepper/StepperDialog';

export default {
  props: {
    visit: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      addingSupportVisits: false,
    };
  },
  components: {
    AddSupportVisits,
    StepperDialog
  },
  methods: {
    getDateTime(iso) {
      return moment(iso).format('DD/MM/YYYY HH:mm');
    },
    addSupportVisits() {
      this.addingSupportVisits = true;
    },
  }
}
</script>