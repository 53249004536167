var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"d-flex",attrs:{"flat":"","color":"grey lighten-3","height":"225"}},[(_vm.history.datasets)?_c('LineBar',{staticClass:"flex-grow-1",attrs:{"chart-data":_vm.history,"options":{ 
      maintainAspectRatio: false, 
      responsive: true,
      title: {
        display: true,
        text: '12 month forecast'
      },
      scales: {
        yAxes: [{
          display: true,
          scaleLabel: {
            display: true,
            labelString: 'man hours'
          },
          ticks: {
            beginAtZero: true
          }
        }]
      }
    }}}):_c('v-progress-linear',{staticClass:"align-self-center mx-auto",staticStyle:{"width":"150px"},attrs:{"indeterminate":"","color":"primary"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }