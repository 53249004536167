<template>
    <a 
        class="button w-button"
        :class="{ light: isExecuting || isExecuted, warning: isWarning && !isExecuting && !isExecuted }"
        @click="startExecuting"
    >
        <div 
            v-show="isExecuting"
            ref="executing"
            data-name="executing"
            class="lottie button-animation"
            data-animation-path="/assets/lottie/executing.json"
            data-anim-loop="true"
            data-anim-type="svg"
        ></div>
        <div 
            v-show="isExecuted"
            ref="executed"
            data-name="executed"
            class="lottie button-animation"
            data-animation-path="/assets/lottie/executed.json"
            data-anim-loop="false"
            data-anim-type="svg"
        ></div>

        <transition
            enter-active-class="animated fadeInDown faster"
            leave-active-class="animated fadeOutDown faster"
            mode="out-in"
        >
            <div v-if="isExecuting" class="button-label" key="executing">{{ executingLabel }}</div>
            <div v-else-if="isExecuted" class="button-label" key="executed">{{ executedLabel }}</div>
            <div v-else class="button-label white--text" key="default">{{ defaultLabel }}</div>
        </transition>
    </a>
</template>

<script>
import { getRegisteredAnimations, goToAndStop, registerAnimation, play, stop } from "lottie-web";

export default {
    props: {
        func: {
            type: Function,
            required: true
        },
        defaultLabel: {
            type: String,
            default: "Save"
        },
        executingLabel: {
            type: String,
            default: "Saving"
        },
        executedLabel: {
            type: String,
            default: "Saved"
        },
        isWarning: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isExecuting: false,
            isExecuted: false
        };
    },
    methods: {
        async startExecuting() {
            this.isExecuting = true;
            this.resetAnimations();
            play("executing");
            await this.func();
            this.startExecuted();
        },
        startExecuted() {
            this.isExecuting = false;
            this.isExecuted = true;
            stop("executing");
            play("executed");
        },
        stopExecuted() {
            this.isExecuted = false;
            this.resetAnimations();
            this.$emit("animationComplete");
        },
        resetAnimations() {
            goToAndStop(0);
        }
    },
    mounted() {
        registerAnimation(this.$refs.executing);
        registerAnimation(this.$refs.executed);
        const vm = this;
        const savedAnim = getRegisteredAnimations().find(a => a.name === "executed");
        savedAnim.addEventListener("complete", () => vm.stopExecuted());
    }
};
</script>