<template>
    <div>
        <span v-if="visit.isLateDeparture">
            <span class="strong">Late</span> departing for<span v-if="isShowJobType">&nbsp;{{ jobType }}</span> job <span class="strong">{{ visit.jobReference }}</span><span v-if="isShowPostcode">&nbsp;({{visit.site.address.postcode}})</span>.
        </span>
        <span v-else-if="visit.isTravellingToSite && isLateArrivingHome">
            <span class="strong">Late arriving home</span>. Was on the way to<span v-if="isShowJobType">&nbsp;{{ jobType }}</span> job <span class="strong">{{ visit.jobReference }}</span><span v-if="isShowPostcode">&nbsp;({{visit.site.address.postcode}})</span> at <span class="strong">{{ departedTime }}</span>.
        </span>
        <span v-else-if="visit.isTravellingToSite">
            On the way to<span v-if="isShowJobType">&nbsp;{{ jobType }}</span> job <span class="strong">{{ visit.jobReference }}</span><span v-if="isShowPostcode">&nbsp;({{visit.site.address.postcode}})</span>.
        </span>
        <span v-else-if="visit.isOnSite && isLateArrivingHome">
            <span class="strong">Late arriving home</span>. On site attending<span v-if="isShowJobType">&nbsp;{{ jobType }}</span> job <span class="strong">{{ visit.jobReference }}</span><span v-if="isShowPostcode">&nbsp;({{visit.site.address.postcode}})</span> since <span class="strong">{{ actualStartTime }}</span>.
        </span>
        <span v-else-if="visit.isOnSite">
            On site attending<span v-if="isShowJobType">&nbsp;{{ jobType }}</span> job <span class="strong">{{ visit.jobReference }}</span><span v-if="isShowPostcode">&nbsp;({{visit.site.address.postcode}})</span>.
        </span>
        <span v-else-if="visit.isTravellingHome && isLateArrivingHome">
            <span class="strong">Late arriving home</span>. Left the site of<span v-if="isShowJobType">&nbsp;{{ jobType }}</span> job <span class="strong">{{ visit.jobReference }}</span><span v-if="isShowPostcode">&nbsp;({{visit.site.address.postcode}})</span> at {{ actualEndTime }}.
        </span>
        <span v-else-if="visit.isTravellingHome">
            Travelling home. Finished<span v-if="isShowJobType">&nbsp;{{ jobType }}</span> job <span class="strong">{{ visit.jobReference }}</span><span v-if="isShowPostcode">&nbsp;({{visit.site.address.postcode}})</span> at {{ actualEndTime }}.
        </span>
        <span v-else-if="!visit.isCompletedVisit">
            <span v-if="isShowJobType">{{ jobTypeCapitalised }} job</span><span v-else>Job</span> visit <span class="strong">{{ visit.jobReference }}</span><span v-if="isShowPostcode">&nbsp;({{visit.site.address.postcode}})</span> scheduled for {{ scheduledStartDate.calendar(now).toLowerCase() }}.
        </span>
    </div>
</template>

<script>
import moment from "moment-timezone";

export default {
    props: {
        isLateArrivingHome: {
            type: Boolean,
            required: false,
            default: false
        },
        visit: {
            type: Object,
            required: true
        },
        isShowPostcode: {
            type: Boolean,
            required: false,
            default: false
        },
        isShowJobType: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            now: moment.tz(this.visit.timezone)
        };
    },
    computed: {
        departedTime: function() {
            return moment.tz(this.visit.departedDateTimeLocal, this.visit.timezone).format("HH:mm");
        },
        scheduledStartDate: function() {
            return moment.tz(this.visit.scheduledStartDateTime, this.visit.timezone);
        },
        actualStartTime: function() {
            return moment.tz(this.visit.actualStartDateTimeLocal, this.visit.timezone).format("HH:mm");
        },
        actualEndTime: function() {
            return moment.tz(this.visit.actualEndDateTimeLocal, this.visit.timezone).format("HH:mm");
        },
        jobType: function() {
            return this.visit.jobType.toLocaleLowerCase();
        },
        jobTypeCapitalised: function() {
            return `${this.jobType[0].toUpperCase()}${this.jobType.substring(1)}`;
        }
    }
};
</script>