<template>
    <div class="profile-container">
        <div class="profile-card">
            <avatar :engineer="engineer" />
            <fields :engineer="engineer" @shownotifications="onShowNotifications" />
        </div>
    </div>
</template>

<script>
// Components
import Avatar from "./Avatar";
import Fields from "./Fields";

export default {
    props: {
        engineer: {
            type: Object,
            required: true
        }
    },
    components: {
        Avatar,
        Fields
    },
    methods: {
        onShowNotifications() {
            this.$emit("shownotifications");
        }
    }
};
</script>