<template>
    <div class="cards-container">
        <asset
            v-for="asset in assets"
            :key="asset.id"
            :assetId="asset.id"
        />
    </div>
</template>

<script>
// Components
import Asset from "./Asset";

export default {
    props: {
        assets: {
            type: Array,
            required: true
        }
    },
    components: {
        Asset
    }
};
</script>