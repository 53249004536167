<template>
    <div class="side-panel">
        <profile-skeleton />
    </div>
</template>

<script>
import ProfileSkeleton from "./ProfileSkeleton";

export default {
    components: {
        ProfileSkeleton
    }
};
</script>