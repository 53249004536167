import moment from "moment";

export const calendarMixin = {
    props: {
        appointments: {
            type: Array,
            default: []
        }
    },
    methods: {
        getOverlappingAppointments(start, end) {
            return this.appointments.filter(a => {
                const appointmentStart = moment(a.start);
                const appointmentEnd = moment(a.end);
                return !start.isSameOrAfter(appointmentEnd)
                    && !end.isSameOrBefore(appointmentStart);
            });
        }
    }
};