<template>
    <div class="side-navbar">
        <img 
            v-if="isDefaultMenuItems" 
            src="/assets/img/grundfos-logo-white.png" 
            srcset="/assets/img/grundfos-logo-white-p-500.png 500w, /assets/img/grundfos-logo-white-p-800.png 800w, /assets/img/grundfos-logo-white.png 983w"
            sizes="(max-width: 767px) 100vw, 30px" 
            alt="" 
            class="main-logo"
        >
        <primary-menu />
    </div>
</template>

<script>
// External libraries
import { mapGetters } from "vuex";

// Components
import PrimaryMenu from "./PrimaryMenu";

export default {
    components: {
        PrimaryMenu
    },
    computed: {
        ...mapGetters({
            "isDefaultMenuItems": "navbar/isDefault",
        })
    }
};
</script>