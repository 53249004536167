<template>
  <div
    class="d-flex flex-column"
    style="width: 100%"
  >
    <transition
      enter-active-class="animated fadeInUpBig"
      leave-active-class="animated fadeOutDownBig"
      mode="out-in"
    >
      <div
        v-if="!loaded"
        key="loading"
      >
        <v-row
          no-gutters
          align="center"
          justify="center"
          style="height: 100vh"
        >
          <v-col
            align-self="center"
            md="6"
            class="px-8"
          >
            <div 
              key="loading"
              ref="loading"
              data-name="loading"
              class="lottie loading"
              data-animation-path="/assets/lottie/loading.json"
              data-anim-loop="true"
              data-anim-type="svg"
            ></div>
          </v-col>
        </v-row>
      </div>
      <div
        v-else-if="!editable"
        key="not-editable"
      >
        <v-row
          no-gutters
          align="center"
          justify="center"
          style="height: 100vh"
        >
          <v-col
            align-self="center"
            md="6"
            class="px-8"
          >
            <div class="hero-block show-all">
              <div class="hero-details">
                  <h3 class="hero-title">Oops!</h3>
                  <div class="text-h6">This job cannot be sent for technical review.</div>
                  <div class="text-body">Please call the office if you need to advise them of any issues with this job.</div>
              </div>
              <div class="hero-illustration">
                  <img src="/assets/illustrations/undraw_warning_cyit.svg" alt="">
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
      <div
        v-else-if="saved"
        key="saved"
      >
        <v-row
          no-gutters
          align="center"
          justify="center"
          style="height: 100vh"
        >
          <v-col
            align-self="center"
            md="6"
            class="px-8"
          >
            <div class="hero-block show-all">
              <div class="hero-details">
                  <h3 class="hero-title">Done!</h3>
                  <div class="text-h6">The job has been sent for technical review.</div>
                  <div class="text-body">If you have any more comments regarding this job, please contact the office.</div>
              </div>
              <div class="hero-illustration">
                  <img src="/assets/illustrations/undraw_Messaging_fun_re_vic9.svg" alt="">
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
      <stepper
        v-else
        key="not-saved"
        :steps="1"
        :close="close"
        :finish="sendRequest"
      >
        <template v-slot:default="{ steps, finish }">
          <step
            :step="1"
            :steps="steps"
            :finish="finish"
            :isValid="Boolean(comment)"
            :showProgress="false"
            :heading="`Request technical review for job ${jobReference}`"
          >
            <v-textarea
              outlined
              class="mt-2"
              label="Comment for the office"
              hint="Indicate here the reason for requesting that the job be technically reviewed."
              persistent-hint
              v-model="comment"
            ></v-textarea>
          </step>
        </template>
      </stepper>
    </transition>
  </div>
</template>

<script>
import { registerAnimation, play, stop } from "lottie-web";

// Components
import Step from '@/components/stepper/Step';
import Stepper from '@/components/stepper/Stepper';

export default {
  data() {
    return {
      loaded: false,
      valid: true,
      saving: false,
      saved: false,
      jobReference: '',
      comment: '',
      statusId: 0,
      jobId: Number(this.$route.params.id),
      userId: Number(this.$route.query.userId),
    };
  },
  components: {
    Step,
    Stepper,
  },
  computed: {
    editable() {
      return this.statusId === 18;
    }
  },
  methods: {
    close() {
      this.saved = true;
    },
    async sendRequest() {
      const comment = { createdById: this.userId, comment: this.comment };
      const api = await this.$api();
      await api.put(`/jobs/${this.jobId}/status/awaiting-technical-review`, comment);
    },
  },
  async mounted() {
    registerAnimation(this.$refs.loading);
    play("loading");
    try {
      const api = await this.$api();
      const { data: { reference, statusId } } = await api.get(`/jobs/${this.jobId}/status`);
      this.statusId = Number(statusId);
      this.jobReference = reference;
    } finally {
      setTimeout(() => stop("loading"), 1500);
      this.loaded = true;
    }
  }
}
</script>