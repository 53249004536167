import Vue from "vue";
import App from "./App.vue";
import axios from "./plugins/axios";
import vuetify from "./plugins/vuetify";
import api from "./plugins/api";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import VueWorker from "vue-worker";
import { Auth0Plugin } from "./auth";
import { ObserveVisibility } from "vue-observe-visibility";

import { domain, clientId, audience } from "../auth_config.json";

Vue.config.productionTip = false;

Vue.use(Auth0Plugin, {
  domain,
  clientId,
  audience,
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  }
});

Vue.use(axios);
Vue.use(api);
Vue.use(VueWorker);
Vue.directive("observe-visibility", ObserveVisibility);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount("#app");
