<template>
    <div class="profile-fields">
        <div class="profile-field" :class="{ 'lime--text accent-4': engineer.isOnCall, 'red--text': !engineer.isOnCall }">
            <img v-if="engineer.isOnCall" src="/assets/img/noun_correct_3145948-2.svg" alt="" class="profile-field-icon">
            <img v-else src="/assets/img/noun_cancel_3145938-2.svg" alt="" class="profile-field-icon">

            <div class="profile-field-value"><span class="strong">{{ engineer.isOnCall ? "Is" : "Not" }}</span> on-call</div>
        </div>
        <div class="profile-field" :class="{ 'lime--text accent-4': engineer.isReceiveAlerts, 'red--text': !engineer.isReceiveAlerts }">
            <img v-if="engineer.isReceiveAlerts" src="/assets/img/noun_correct_3145948-2.svg" alt="" class="profile-field-icon">
            <img v-else src="/assets/img/noun_cancel_3145938-2.svg" alt="" class="profile-field-icon">
            <div class="profile-field-value">
                Alerts <span class="strong">{{ engineer.isReceiveAlerts ? "on" : "off" }}</span>
            </div>
        </div>
        <button
            class="profile-field"
            :class="{ 'orange--text': engineer.notifications.length > 0 }"
            @click="emitShowNotifications"
        >
            <img v-if="engineer.notifications.length > 0" src="/assets/img/bell-orange.svg" alt="" class="profile-field-icon">
            <img v-else src="/assets/img/bell-white.svg" alt="" class="profile-field-icon">
            <div class="profile-field-value">
                <span class="strong">{{ engineer.notifications.length }}</span> alerts today
            </div>
        </button>
        <div class="profile-field">
            <img src="/assets/img/noun_Outgoing-Call_792164.svg" alt="" class="profile-field-icon">
            <div class="profile-field-value">{{ engineer.mobile }}</div>
        </div>
        <!-- <div class="profile-field">
            <img src="/assets/img/noun_Location_3145927.svg" alt="" class="profile-field-icon">
            <div class="profile-field-value">
                {{ engineer.address.line1 }}<br>
                {{ engineer.address.line2 }}<br>
                {{ engineer.address.city }}<br>
                {{ engineer.address.county }}<br>
                {{ engineer.address.postcode }}
            </div>
        </div> -->
    </div>
</template>

<script>
export default {
    props: {
        engineer: {
            type: Object,
            required: true
        }
    },
    methods: {
        emitShowNotifications() {
            if (this.engineer.notifications.length > 0)
                this.$emit("shownotifications");
        }
    }
};
</script>