import Vue from "vue";
import Router from "vue-router";
// import Home from "../views/Home.vue";
import AssetsReview from '../views/contracts/assets/AssetsReview.vue';
import AssetForm from "../views/contracts/assets/AssetForm.vue";
import CallOuts from "../views/callout/CallOuts.vue";
import Contract from "../views/contracts/Contract.vue";
import Engineers from "../views/engineers/Engineers.vue";
import Engineer from "../views/engineers/Engineer.vue";
import EngineerEdit from "../views/engineers/EngineerEdit.vue";
import RequestTechnicalReview from "../views/job/RequestTechnicalReview.vue";
import Settings from "../views/settings/Settings.vue";
import Utilisation from "../views/utilisation/Index.vue";

import { authGuard } from "../auth";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "home",
      redirect: "/engineers"
    },
    {
      path: "/engineers",
      component: Engineers,
      beforeEnter: authGuard
    },
    {
      path: "/engineer/:id",
      component: Engineer,
      beforeEnter: authGuard
    },
    {
      path: "/engineer/edit/:id",
      component: EngineerEdit,
      beforeEnter: authGuard
    },
    {
      path: "/engineers/utilisation",
      component: Utilisation,
      beforeEnter: authGuard
    },
    {
      path: "/settings",
      component: Settings,
      beforeEnter: authGuard
    },
    {
      path: "/contract/:id",
      component: Contract,
      beforeEnter: authGuard
    },
    {
      name: 'contract',
      path: "/contracts/:id",
      component: Contract,
      beforeEnter: authGuard
    },
    {
      path: "/contracts/:id/assets/create",
      component: AssetForm,
      beforeEnter: authGuard
    },
    {
      path: "/contracts/:id/assets/review",
      component: AssetsReview,
      beforeEnter: authGuard
    },
    {
      path: "/callouts",
      component: CallOuts,
      beforeEnter: authGuard
    },
    {
      path: "/jobs/:id/request-technical-review",
      component: RequestTechnicalReview
    }
  ],

  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
});

export default router;
