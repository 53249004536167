import Vue from "vue";
import { chain, remove } from "lodash";

const types = {
    engineerNotification: "engineerWelfareNotification",
    fsmEngineerWelfareNotification: "fsmEngineerWelfareNotification",
    smEngineerWelfareNotification: "smEngineerWelfareNotification"
};

const state = {
    newNotification: undefined,
    isLoaded: false,
    scheduledNotifications: [],
    selectedNotification: undefined
};

function createNotification(type) {
    return {
        hour: 0,
        minute: 0,
        timezone: "Europe/London",
        type
    };
}

const mutations = {
    newNotification(state, payload) {
        state.newNotification = payload;
    },
    isLoaded(state, payload) {
        state.isLoaded = payload;
    },
    scheduledNotifications(state, payload) {
        state.scheduledNotifications = payload;
    },
    selectedNotification(state, payload) {
        state.selectedNotification = payload;
    },
    selectedNotificationHour(state, payload) {
        state.selectedNotification.hour = payload;
    },
    selectedNotificationMinute(state, payload) {
        state.selectedNotification.minute = payload;
    }
};

const actions = {
    createEngineerNotification({ commit }) {
        commit("newNotification", createNotification(types.engineerNotification));
    },
    createFsmEngineerWelfareNotification({ commit }) {
        commit("newNotification", createNotification(types.fsmEngineerWelfareNotification));
    },
    createSmEngineerWelfareNotification({ commit }) {
        commit("newNotification", createNotification(types.smEngineerWelfareNotification));
    },
    async loadNotifications({ commit, getters }) {
      const { email } = Vue.prototype.$auth.user;
        if (!getters.isLoaded && email !== 'external@grundfos.com') {
            const api = await Vue.prototype.$api();
            const { data } = await api.get("/scheduled-notifications");

            commit("scheduledNotifications", data);
            commit("isLoaded", true);
        }
    },
    async patchSelectedNotification({ commit, getters }, payload) {
        const notification = getters.selectedNotification;
        payload.hour = payload.hour ? Number.parseInt(payload.hour) : notification.hour;
        payload.minute = payload.minute ? Number.parseInt(payload.minute) : notification.minute;
        try {
            const api = await Vue.prototype.$api();
            const res = await api.patch(`/scheduled-notifications/${notification.notificationId}`, payload);
            if (res.status >= 200 && res.status < 300) {
                commit("selectedNotificationHour", payload.hour);
                commit("selectedNotificationMinute", payload.minute);
            }
        } catch(err) {
            console.error("Failed to patch notification", err);
        }
    },
    async deleteSelectedNotification({ commit, getters }) {
        const notification = getters.selectedNotification;
        try {
            const api = await Vue.prototype.$api();
            const res = await api.delete(`/scheduled-notifications/${notification.notificationId}`);
            if (res.status >= 200 && res.status < 300) {
                const scheduledNotifications = [ ...getters.scheduledNotifications ];
                remove(scheduledNotifications, (n) => n === notification);
                commit("scheduledNotifications", scheduledNotifications);
                commit("selectedNotification", undefined);
            }
        } catch(err) {
            console.error("Failed to delete notification", err);
        }
    },
    async postNewNotification({ commit, getters }) {
        const notification = getters.newNotification;      
        try {
            const api = await Vue.prototype.$api();
            const res = await api.post("/scheduled-notifications", notification);
            if (res.status >= 200 && res.status < 300) {
                const scheduledNotifications = [ ...getters.scheduledNotifications ];
                scheduledNotifications.push(notification);
                commit("scheduledNotifications", scheduledNotifications);
                commit("newNotification", undefined);
            }
        } catch(err) {
            console.error("Failed to post notification", err);
        }
    },
    selectNotificationById({ commit, getters }, id) {
        const notification = getters.scheduledNotifications.find(n => n.notificationId === id);
        commit("selectedNotification", notification);
    },
    selectNotification({ commit }, payload) {
        commit("selectedNotification", payload);
    },
    deselectNotification({ commit }) {
        commit("selectedNotification", undefined);
    }
};

const getters = {
    newNotification(state) {
        return state.newNotification;
    },
    isLoaded(state) {
        return state.isLoaded;
    },
    scheduledNotifications(state) {
        return state.scheduledNotifications;
    },
    selectedNotification(state) {
        return state.selectedNotification;
    },
    engineerNotifications(state) {
        return chain(state.scheduledNotifications.filter(n => n.type === types.engineerNotification))
                .sortBy(({ hour, minute }) => hour * 60 + minute)
                .value();
    },
    fieldServiceManagerNotifications(state) {
        return chain(state.scheduledNotifications.filter(n => n.type === types.fsmEngineerWelfareNotification))
                .sortBy(({ hour, minute }) => hour * 60 + minute)
                .value();
    },
    serviceManagerNotifications(state) {
        return chain(state.scheduledNotifications.filter(n => n.type === types.smEngineerWelfareNotification))
                .sortBy(({ hour, minute }) => hour * 60 + minute)
                .value();
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};