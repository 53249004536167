<template>
    <div class="modal">
      <div class="modal-heading">
        <a 
            href="#" 
            class="round-button w-inline-block transparent"
            @click="hideModal"
        >
            <img src="/assets/img/close.svg" alt="">
        </a>
        <div>Filters</div>
        <a href="#" class="link" @click="clearFilters" >Clear</a>
      </div>
      <div class="modal-body">
        <div class="w-form">
          <form>
            <div class="filter-groups">
              <div class="filter-group">
                <div class="filter-group-heading">Status</div>
                <div class="filters">
                    <label class="checkbox-field">
                        <input type="checkbox" class="hidden" :value="statusOptions.atHome" v-model="status">
                        <div class="checkbox-label">
                            <div class="box checked"></div>
                            <div class="box unchecked"></div>
                            At home ({{ countStatusMatches(statusOptions.atHome) }})
                        </div>
                    </label>
                    <label class="checkbox-field">
                        <input type="checkbox" class="hidden" :value="statusOptions.idle" v-model="status">
                        <div class="checkbox-label">
                            <div class="box checked"></div>
                            <div class="box unchecked"></div>
                            Available for job allocation ({{ countStatusMatches(statusOptions.idle) }})
                        </div>
                    </label>
                    <label class="checkbox-field">
                        <input type="checkbox" class="hidden" :value="statusOptions.travellingToSite" v-model="status">
                        <div class="checkbox-label">
                            <div class="box checked"></div>
                            <div class="box unchecked"></div>
                            Travelling to site ({{ countStatusMatches(statusOptions.travellingToSite) }})
                        </div>
                    </label>
                    <label class="checkbox-field">
                        <input type="checkbox" class="hidden" :value="statusOptions.lateArrivingHome" v-model="status">
                        <div class="checkbox-label">
                            <div class="box checked"></div>
                            <div class="box unchecked"></div>
                            Late arriving home ({{ countStatusMatches(statusOptions.lateArrivingHome) }})
                        </div>
                    </label>
                    <label class="checkbox-field">
                        <input type="checkbox" class="hidden" :value="statusOptions.lateDeparture" v-model="status">
                        <div class="checkbox-label">
                            <div class="box checked"></div>
                            <div class="box unchecked"></div>
                            Late departing ({{ countStatusMatches(statusOptions.lateDeparture) }})
                        </div>
                    </label>
                    <label class="checkbox-field">
                        <input type="checkbox" class="hidden" :value="statusOptions.onSite" v-model="status">
                        <div class="checkbox-label">
                            <div class="box checked"></div>
                            <div class="box unchecked"></div>
                            On site ({{ countStatusMatches(statusOptions.onSite) }})
                        </div>
                    </label>
                    <label class="checkbox-field">
                        <input type="checkbox" class="hidden" :value="statusOptions.travellingHome" v-model="status">
                        <div class="checkbox-label">
                            <div class="box checked"></div>
                            <div class="box unchecked"></div>
                            Travelling home ({{ countStatusMatches(statusOptions.travellingHome) }})
                        </div>
                    </label>
                    <label class="checkbox-field">
                        <input type="checkbox" class="hidden" :value="statusOptions.unavailable" v-model="status">
                        <div class="checkbox-label">
                            <div class="box checked"></div>
                            <div class="box unchecked"></div>
                            Unavailable ({{ countStatusMatches(statusOptions.unavailable) }})
                        </div>
                    </label>
                </div>
              </div>
              <div class="filter-group last">
                <div class="filter-group-heading">Regions</div>
                <div class="filters">
                    <label class="checkbox-field">
                        <input type="checkbox" class="hidden" value="1" v-model="regions">
                        <div class="checkbox-label">
                            <div class="box checked"></div>
                            <div class="box unchecked"></div>
                            Region 1 ({{ countRegionMatches(1) }})
                        </div>
                    </label>
                    <label class="checkbox-field">
                        <input type="checkbox" class="hidden" value="2" v-model="regions">
                        <div class="checkbox-label">
                            <div class="box checked"></div>
                            <div class="box unchecked"></div>
                            Region 2 ({{ countRegionMatches(2) }})
                        </div>
                    </label>
                    <label class="checkbox-field">
                        <input type="checkbox" class="hidden" value="3" v-model="regions">
                        <div class="checkbox-label">
                            <div class="box checked"></div>
                            <div class="box unchecked"></div>
                            Region 3 ({{ countRegionMatches(3) }})
                        </div>
                    </label>
                    <label class="checkbox-field">
                        <input type="checkbox" class="hidden" value="4" v-model="regions">
                        <div class="checkbox-label">
                            <div class="box checked"></div>
                            <div class="box unchecked"></div>
                            Region 4 ({{ countRegionMatches(4) }})
                        </div>
                    </label>
                    <label class="checkbox-field">
                        <input type="checkbox" class="hidden" value="6" v-model="regions">
                        <div class="checkbox-label">
                            <div class="box checked"></div>
                            <div class="box unchecked"></div>
                            Water Utilities ({{ countRegionMatches(6) }})
                        </div>
                    </label>
                    <label class="checkbox-field">
                        <input type="checkbox" class="hidden" value="7" v-model="regions">
                        <div class="checkbox-label">
                            <div class="box checked"></div>
                            <div class="box unchecked"></div>
                            Sub-contractors ({{ countRegionMatches(7) }})
                        </div>
                    </label>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="modal-footer">
        <a 
            href="#" 
            class="button grow w-button white--text" 
            @click="applyFilters"
        >
            Apply filters
        </a>
      </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
    data() {
        return {
            regions: [],
            status: []
        };
    },
    computed: {
        ...mapGetters({
            "prefsRegions": "prefs/engineers/regions",
            "prefsStatus": "prefs/engineers/status",
            "statusOptions": "prefs/engineers/statusOptions",
            "filteredEngineers": "prefs/engineers/filteredEngineers"
        })
    },
    methods: {
        ...mapActions({
            "hideModal": "modal/hideModal",
            "setRegions": "prefs/engineers/setRegions",
            "setStatus": "prefs/engineers/setStatus",
        }),
        applyFilters() {
            this.setRegions(this.regions);
            this.setStatus(this.status);
            this.hideModal();
        },
        clearFilters() {
            this.regions = [];
            this.status = [];
            this.setRegions(this.regions);
            this.setStatus(this.status);
        },
        countRegionMatches(regionId) {
            return this.filteredEngineers.filter(e => e.regionId === regionId).length;
        },
        countStatusMatches(status) {
            return this.filteredEngineers.filter(e => e.status === status).length;
        }
    },
    mounted() {
        this.regions = [ ...this.prefsRegions ];
        this.status = [ ...this.prefsStatus ];
    }
};
</script>