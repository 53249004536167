<template>
    <div>
        <div v-if="isSameDay && isToday">
            Unavailable today between <span class="strong">{{ start.format("HH:mm") }}</span> and <span class="strong">{{ end.format("HH:mm") }}.</span> <span v-if="description !== ''">&nbsp;({{ description }})</span>
        </div>
        <div v-else-if="isSameDay && isTomorrow">
            Unavailable tomorrow between <span class="strong">{{ start.format("HH:mm") }}</span> and <span class="strong">{{ end.format("HH:mm") }}.</span> <span v-if="description !== ''">&nbsp;({{ description }})</span>
        </div>
        <div v-else>
            Unavailable between the <span class="strong">{{ format(start) }}</span> and the <span class="strong">{{ format(end) }}.</span> <span v-if="description !== ''">&nbsp;({{ description }})</span>
        </div>
    </div>
</template>

<script>
import moment from "moment";

export default {
    props: {
        description: {
            type: String, 
            required: false,
            default: ""
        },
        notes: {
            type: String, 
            required: false,
            default: ""
        },
        start: {
            type: Object,
            required: true
        },
        end: {
            type: Object,
            required: true
        }
    },
    computed: {
        isSameDay: function() {
            return this.end.isSame(this.start, "day");
        },
        isToday: function() {
            const now = moment();
            return now.isSame(this.start, "day");
        },
        isTomorrow: function() {
            const tomorrow = moment().add(1, "day");
            return tomorrow.isSame(this.start, "day");
        }
    },
    methods: {
        format(date) {
            return `${date.format("Do")} of ${date.format("MMMM")}`;
        }
    }
};
</script>